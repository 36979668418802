import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Card, CardContent, CardHeader } from '@mui/material';
import prevIcon from "../../assets/icons/prevIcon.svg"
import nextIcon from "../../assets/icons/nextIcon.svg"


const ClaimNoteHistory = (props) => {
    const notesData = props?.viewData?.Notes
    const user = JSON.parse(sessionStorage.getItem('userDetail'));
    return (
        <>
            <Dialog className='claimModal'
                fullWidth
                open={props.openNote}
                onClose={props.closeNotePopup} style={{ width: '100%' }}>
                <div className='claimDialog'>
                    <div className='d-flex align-items-center claimNoteDiv justify-content-between px-3'>
                        <span className='claimNote'>Claim Note History</span>

                        <div>{`${(props.firstPage) + 1} of ${props.totalPage}`}
                            <button onClick={props.clickPrevious} className='navigateBtn' disabled={props.firstPage == 0 ? true : false}>
                                <img src={prevIcon} />
                            </button>
                            <button onClick={props.clickNext} className='navigateBtn' disabled={(props.firstPage) + 1 == props.totalPage ? true : false}>
                                <img src={nextIcon} />
                            </button>
                        </div>
                    </div>
                    {/* <div className='noteBot'></div> */}
                    <DialogContent>
                        <Card className='noteCard '>
                            {/* <div className='row mx-2 my-2'>
                                <div className='col-12 col-sm-12 col-lg-12 '>
                                    <span>Encounter Details</span>
                                </div>
                            </div> */}
                            <div className='w-100'>

                            <div className='modalHeading'>
                                <span >Encounter Details</span>
                            </div>
                            <CardContent>
                                <div className='row'>
                                    <div className='col-6'>
                                        <label><strong>Patient Name: </strong><span>{props?.viewData?.PatientName}</span></label><br />
                                        <label><strong>Provider: </strong><span>{props?.viewData?.SchedulingProviderName}</span></label><br />
                                        <label><strong>Location: </strong><span>{props?.viewData?.ServiceLocationName}</span></label>
                                    </div>
                                    <div className='col-3'>
                                        <label><strong>Encounter: </strong><span>{props?.viewData?.EncounterID}</span></label><br />
                                        <label><strong>Claim ID: </strong><span>{props?.viewData?.CLAIMID}</span></label><br />
                                        <label><strong>Procedure: </strong><span>{props?.viewData?.Code}</span></label>
                                    </div>
                                    <div className='col-3'>
                                        <label><strong>Last Billed Date: </strong><span>{new Date(props?.viewData?.Denial_Date).toLocaleDateString('en-US')}</span></label><br />
                                        <label><strong>No Resp Date: </strong><span>{new Date(props?.viewData?.Denial_Date).toLocaleDateString('en-US')}</span></label>
                                    </div>
                                </div>
                            </CardContent>
                            </div>
                            {/* <div className='noteBot'></div> */}
                            <div style={{ overflowY: 'scroll', width: '100%' }}>{notesData?.length > 0 ? notesData?.map((note, idx) => (
                                <div className='contentBox'>
                                    <div className='innerContent'>
                                        <span>{note.NOTES}</span><br />
                                        {/* <span style={{fontSize: 13}}>Posting Date: {new Date(props?.viewData?.Denial_Date).toLocaleDateString('en-US')}</span><br /> */}
                                        <span style={{fontSize: 13}}>Posting Date: {note.CREATEDDATE}</span><br />
                                        {/* <span style={{fontSize: 13}}>User: {props?.viewData?.AssignedTo === null || props?.viewData?.AssignedTo == '' ? user.Name : props?.viewData?.AssignedTo}</span> */}
                                        <span style={{fontSize: 13}}>User: {note.USERNAME}</span>
                                    </div>
                                </div>
                            )) :
                                <div className='innerContent'>
                                    <span>There are no notes for this claim...</span>
                                </div>
                            }</div>
                        </Card>
                        <div className='mt-3'>
                            <button className='close_button' onClick={props.closeNotePopup}>CLOSE</button>
                        </div>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
}

export default ClaimNoteHistory;