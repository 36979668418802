import React from 'react';
import { useEffect, useState } from "react";
import "./createRule.css";
// import PropTypes from 'prop-types';

export default function InputWithChip({ separateBy, forCondition, indexs, onEnter, editRule, ruleValues, categoryType, deleteChipEdit, maskdate }) {
  const [inputs, setInputs] = useState({ chips: [], currVal: "" });
  // console.log(indexs,"indexssssssssssssssssssss>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
  //  setInputs()
  function handleChange(e) {
    setInputs({ ...inputs, currVal: e.target.value });
  }

  useEffect(() => {
    // console.log(inputs, "useEffect..........>>>>>>>>>>>>>>///////////")
    setInputs({ ...inputs, chips: [], currVal: "" });
  }, [forCondition]);

  function deleteChip(valindex, index) {
    let updatedChips = [...inputs.chips];
    updatedChips.splice(valindex, 1);
    setInputs({
      ...inputs,
      chips: updatedChips
    });

  }

  // function onKeyUp(event) {
  //   if (event.charCode === 13) {
  //     let value = inputs.currVal.trim()
  //     if (value !== '') {
  //       setInputs({
  //         ...inputs,
  //         chips: [...inputs.chips, inputs.currVal],
  //         currVal: ""
  //       });
  //       if (editRule) {
  //         onEnter([inputs.currVal]);
  //       } else {
  //         onEnter([...inputs.chips, value]);
  //       }
  //     }
  //   }
  // }
  function onKeyUp(event) {
    if (event.charCode === 13) {
      let value = inputs.currVal.trim()
      if (value !== '') {
        setInputs({
          ...inputs,
          chips: [...inputs.chips, value],
          currVal: ""
        });
        if (editRule) {
          onEnter([inputs.currVal]);
        } else {
          if(categoryType === 'Date of Injury' || categoryType === 'Service Date'){
            onEnter([...inputs.chips, maskdate(inputs.currVal)])
          }else{
            onEnter([...inputs.chips, inputs.currVal]);
          }
        }
      }
    }
  }
  if((forCondition === 'is above' || forCondition === 'is below' || forCondition === 'is equal to' || 
  forCondition === 'is not equal to' || forCondition === 'is equal to or above' || forCondition === 'is not equal to or above') && (inputs.chips.length > 1)){
    inputs.chips.pop();
  }

  return (
    <div className="row mb-2 rule-builder">

<div className={categoryType === 'Diagnosis 1' || 
              categoryType === 'Diagnosis 2' || 
              categoryType === 'Diagnosis 3' || 
              categoryType === 'Diagnosis 4' || 
              categoryType === 'Diagnosis in any slot' || 
              categoryType === 'Modifier' || 
              categoryType === 'Total Charges' || 
              categoryType === 'Units' || 
              categoryType === 'Unit Charge' ? "col-lg-10 offset-md-1" : "col-lg-10 offset-md-1"}>

      <label htmlFor="chips-input" className="text-wrap font-face-futura inputbox">
        {!editRule ? inputs.chips.map((chip, index) => (
          <>
            {index !== 0 && (
              <span
                style={{ borderRadius: "9px", padding: "5px", margin: "5px", fontSize: 18 }}>
                {separateBy}
              </span>
            )}
            <span key={index} className="chips-element">
              {/* {chip}{" "}&nbsp;&nbsp; */}
              {(categoryType === 'Date of Injury' || categoryType === 'Service Date') ? maskdate(chip) : chip}{" "}&nbsp;&nbsp;
              <span className="chip-del-btn" onClick={() => deleteChip(index)}>
                &times;
              </span>
            </span>
          </>
        ))
          : (ruleValues?.ruleInfo && ruleValues.ruleInfo.length > 0 && ruleValues.ruleInfo.map((f, index) => (
            ((f.category === categoryType) && (indexs === index) && 
            (f.condition === 'contains' || f.condition === 'does not contain' || f.condition === 'is equal to or above' || 
            f.condition === 'is above' || f.condition === 'is below' || f.condition === 'is equal to' || f.condition === 'is not equal to' ||
            f.condition === 'is equal to or below' || f.condition === 'is between' || f.condition === 'is not between' || f.condition === 'is missing' || 
            f.condition === 'is equal to or after' || f.condition === 'is after' || f.condition === 'is before' || f.condition === 'is equal to or before' || 
            f.condition === 'starts with' || f.condition === 'does not start with' || f.condition === 'ends with' || f.condition === 'does not end with' || 
            f.condition === 'is not missing')) &&
            f?.values && f.values.length > 0 && f.values.map((val, i) => (
              <>
                {/* {console.log(val, "FFFFF", f.condition, f.values)} */}
                {i !== 0 && (
                  <span
                    style={{ borderRadius: "9px", padding: "5px", margin: "5px", fontSize: 18 }}>
                    {separateBy}
                  </span>
                )}

                <span key={i} className="chips-element">
                  {val}{" "}&nbsp;&nbsp;
                  <span className="chip-del-btn" onClick={() => deleteChipEdit(i, index)}>
                    &times;
                  </span>
                </span>
              </>
            ))
          )))
        }
        <input
          className='font-face-futura text-center'
          value={inputs.currVal}
          onChange={handleChange}
          onKeyPress={onKeyUp}
          type="text"
          id="chips-input"
        />
      </label>
      </div>
    </div>
  );
}

// InputWithChip.propTypes = {
//   separateBy: PropTypes.string.isRequired,
//   forCondition: PropTypes.string.isRequired,
//   onEnter: PropTypes.func.isRequired
// }
