export const columnHeader = [
  {
    name: 'Patient Name',
    key: 'PatientName'
  },
  {
    name: 'Encounter',
    key: 'EncounterID'
  },
  {
    name: 'Provider',
    key: 'SchedulingProviderName'
  },
  {
    // name: 'Date of Service',
    name: 'DOS',
    key: 'ServiceStartDate'
  },
  {
    // name: 'Procedure',
    name: 'Code',
    key: 'ProcedureCode'
  },
  {
    name: 'Primary Insurance',
    key: 'PrimaryInsurancePlanName'
  },
  {
    name: 'Secondary Insurance',
    key: 'SecondaryInsurancePlanName'
  },
  {
    name: 'Facility',
    key: 'ServiceLocationName'
  },
  {
    name: 'DXC1',
    key: 'EncounterDiagnosisID1'
  }
]

export const denialHeader = [
  // {
  //   name: 'Flag',
  //   key: 'Flag'
  // },
  {
    name: 'Claim ID',
    key: 'ClaimID'
  },
  {
    name: 'Enc ID',
    key: 'EncID'
  },
  {
    name: 'Patient Name',
    key: 'PatientName'
  },
  {
    name: 'DOS',
    key: 'ServiceStartDate'
  },
  {
    name: 'Scheduling Provider',
    key: 'SchedulingProviderName'
  },
  {
    name: 'Facility',
    key: 'ServiceLocationName'
  },
  {
    name: 'Billed To',
    key: 'BilledTo'
  },
  {
    name: 'Code',
    key: 'ProcedureCode'
  },
  {
    name: 'Mod',
    key: 'Mod'
  },
  {
    name: 'DXC1',
    key: 'EncounterDiagnosisID1'
  },
  {
    name: 'Total Charge',
    key: 'TotalCharge'
  },
  {
    name: 'Balance',
    key: 'Balance'
  },
  {
    name: 'Redenied',
    key: 'Redenied'
  },
  {
    name: 'Adj Code',
    key: 'AdjCode'
  },
  {
    name: 'Remark',
    key: 'Remark'
  },
  {
    name: 'Assigned To',
    key: 'AssignedTo'
  },
  {
    name: `What's Needed`,
    key: 'WhatsNeeded'
  },
  {
    name: 'Denial Date',
    key: 'DenialDate'
  },
  {
    name: 'Due Date',
    key: 'DueDate'
  },
]

// No response management dropdown values
export const whatsNeededNoResp = [
  {key: 'pullFromPortal', value: 'Pull From Portal'},
  {key: 'needToCall', value: 'Need to Call'},
  {key: 'eraReceived', value: 'ERA Received'},
  {key: 'portalIssue', value: 'Portal Issue'},
  {key: 'waitingCallBack', value: 'Waiting Call Back'},
  {key: 'eobRequested', value: 'EOB Requested'},
  {key: 'cannotContact', value: 'Cannot Contact'},
]

export const actionTakenNoResp = [
  {key: 'portal', value: 'Portal'},
  {key: 'phone', value: 'Phone'},
  {key: 'fax', value: 'Fax'},
  {key: 'rebilled', value: 'Rebilled'},
  {key: 'insTransfer', value: 'Ins Transfer'},
  {key: 'patientBilled', value: 'Patient Billed'},
  {key: 'settled', value: 'Settled'},
  {key: 'voided', value: 'Voided'},
  {key: 'correctedClaim', value: 'Corrected Claim'},
  {key: 'phoneReopening', value: 'Phone Reopening'},
  {key: 'remove', value: 'Remove'},
]

// denial management dropdown values
export const whatsNeededDenial = [
  {key: 'Appeal', value: 'Appeal'},
  {key: 'Bill_Patient', value: 'Bill Patient'},
  {key: 'Bulk_Reconsideration', value: 'Bulk Reconsideration'},
  {key: 'Call_Insurance', value: 'Call Insurance'},
  {key: 'Cannot_Contact', value: 'Cannot Contact'},
  {key: 'Clarification', value: 'Clarification'},
  {key: 'Credentialing', value: 'Credentialing'},
  {key: 'Documentation', value: 'Documentation'},
  {key: 'Fix_Claim', value: 'Fix Claim'},
  {key: 'Payment_Issue', value: 'Payment Issue'},
  {key: 'Pending_Adjudication', value: 'Pending Adjudication'},
  {key: 'Phone_Reopening', value: 'Phone Reopening'},
  {key: 'Portal_Issue', value: 'Portal Issue'},
  {key: 'Research', value: 'Research'},
  {key: 'Settle', value: 'Settle'},
  {key: 'Waiting_Call_Back', value: 'Waiting Call Back'},
]

export const actionTakenDenial = [
  {key: 'appealed', value: 'Appealed'},
  {key: 'correctedClaim', value: 'Corrected Claim'},
  {key: 'insTransfer', value: 'Ins Transfer'},
  {key: 'patientBilled', value: 'Patient Billed'},
  {key: 'phoneReopening', value: 'Phone Reopening'},
  {key: 'rebilled', value: 'Rebilled'},
  {key: 'remove', value: 'Remove'},
  {key: 'settled', value: 'Settled'},
  {key: 'voided', value: 'Voided'},
]

export const adjustCodeDenial = [
  {key: 'co', value: 'CO', desc: 'Contractual Obligation'},
  {key: 'oa', value: 'OA', desc: 'Other Adjustment'},
  {key: 'pr', value: 'PR', desc: 'Patient Responsibility'},
  {key: 'pi', value: 'PI', desc: 'Payor Initiated Reduction'},
]

export const pendingWorkedValidationHeader = [
  {
    name: 'Claim ID',
    key: 'ClaimID'
  },
  {
    name: 'Enc ID',
    key: 'EncID'
  },
  {
    name: 'Patient Name',
    key: 'PatientName'
  },
  {
    name: 'DOS',
    key: 'ServiceStartDate'
  },
  {
    name: 'Scheduling Provider',
    key: 'SchedulingProviderName'
  },
  {
    name: 'Facility',
    key: 'ServiceLocationName'
  },
  {
    name: 'Billed To',
    key: 'BilledTo'
  },
  {
    name: 'Code',
    key: 'ProcedureCode'
  },
  {
    name: 'Mod',
    key: 'Mod'
  },
  {
    name: 'DXC1',
    key: 'EncounterDiagnosisID1'
  },
  {
    name: 'Total Charge',
    key: 'TotalCharge'
  },
  {
    name: 'Balance',
    key: 'Balance'
  },
  {
    name: 'Redenied',
    key: 'Redenied'
  },
  {
    name: 'Adj Code',
    key: 'AdjCode'
  },
  {
    name: 'Remark',
    key: 'Remark'
  },
  {
    name: `What's Needed`,
    key: 'WhatsNeeded'
  },
  {
    name: 'Completed By',
    key: 'completedBy'
  },
  {
    name: 'Date Completed',
    key: 'dateCompleted'
  },
  {
    name: 'Action Taken',
    key: 'actionTaken'
  }
]

let today = new Date()
let tomorrow = today.setDate(today.getDate() - 1)

export const dueDateData = [
  {label: 'Missing', value: ''},
  {label: 'Late', value: ''},
  {label: 'Today', value: today},
  {label: 'Tomorrow', value: tomorrow},
  {label: 'This Week', value: ''},
  {label: 'Next Week', value: ''},
  {label: 'Future', value: ''},
]
