import React, { Component } from "react";
import API from "../../Api/index";
import Sidebar from "../Sidebar";
import "./autoRunRule.css";
import { TablePagination } from "@material-ui/core";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { autoRunData } from "./ReportData";

let eachPageContaiRow = 10;
class AutoRunRuleReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      autoRunRuleReports: [],
      page: 0,
      rowsPerPage: eachPageContaiRow,
      totalRows: 0,
    };
  }

  fetchData = async () => {
    let ruleReports = await API.get("autoRunRuleReports");
    if (ruleReports.data.success === true) {
      this.setState({
        autoRunRuleReports: ruleReports.data.reports,
        totalRows: ruleReports.data.rowCount,
      });
    } else {
      alert("Something went Wrong !");
    }
  };

  // function to handle page change
  handlePageChange = (event, currentPage) => {
    this.setState({
      page: currentPage,
    });
  };

  // function to handle rows per page
  handleRowsPerPage = (event) => {
    let rowspage = parseInt(event.target.value, 10);
    this.setState({
      rowsPerPage: rowspage,
      page: 0,
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const styles = {
      content: {
        width: "100%",
      },
    };
    console.log();
    return (
      <>
        <Sidebar
          path={
            this.props.location?.state?.editRule
              ? this.props.location?.state?.path
              : null
          }
          handleChangePractice={(practice) => {
            if (practice) {
              this.fetchData();
            }
            return practice;
          }}
        />
        <div style={styles.content}>

          <div className="container-fluid">
            <div className="row py-1 align-items-center justify-content-between">
              <div className="col-auto">
                <span className="trnsactionTab">Reports</span>
              </div>
            </div>
            <Card className="autoRunCard mrt-top autoRunatable">
              <CardHeader
                className="reportCardHead"
                title={<h5 className="text-white font-face-futura">Auto-Run</h5>}
              ></CardHeader>
              <CardContent>
                <div className="managerRule">
                  <table className="manageTableRule">
                    <thead>
                      <tr>
                        {autoRunData.map((col, index) => (
                          <th
                            key={index}
                            className="text-center font-face-futura"
                          >
                            {col.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.autoRunRuleReports.length > 0 ? (
                        this.state.autoRunRuleReports
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                          )
                          .map((item, index) => (
                            <tr key={index}
                              className="table-row">
                              <td style={{ textAlign: "center" }}>
                                <div className="innerdata">
                                  <span
                                    title={item.id}
                                    className="one-line-ellipsis"
                                  >
                                    {item.ranEncounters}
                                  </span>
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="innerdata">
                                  <span
                                    title={item.id}
                                    className="one-line-ellipsis"
                                  >
                                    {item.dateDownloadFromKareo}
                                  </span>
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="innerdata">
                                  <span
                                    title={item.id}
                                    className="one-line-ellipsis"
                                  >
                                    {item.startTimeAutoRun}
                                  </span>
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="innerdata">
                                  <span
                                    title={item.id}
                                    className="one-line-ellipsis"
                                  >
                                    {item.endTimeAutoRun}
                                  </span>
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="innerdata">
                                  <span
                                    title={item.id}
                                    className="one-line-ellipsis"
                                  >
                                    {item.successEncounters}
                                  </span>
                                </div>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <div className="innerdata">
                                  <span
                                    title={item.id}
                                    className="one-line-ellipsis"
                                  >
                                    {item.failedEncounters}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))) : (
                        <tr>
                          <td
                            colSpan={4}
                            className="mt-3 noRecord text-center"
                          >
                            No records found!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </CardContent>
              <TablePagination
                rowsPerPageOptions={[10, 100, 200]}
                className="foot-flex autorunpagination "
                component="div"
                count={this.state.totalRows}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                onPageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleRowsPerPage}
              />
            </Card>
          </div>
        </div>
      </>
    );
  }
}
export default AutoRunRuleReports;