import "./style.css";

export const RoCheckbox = (props) => {
  const { onChange, style = {}, ref=null, checked = false, ...rest } = props;
  return (
    <label className="checkbox-container" style={style} ref={ref}>
      <input type="checkbox" checked={checked} ref={ref} {...rest} onChange={onChange} />
      <span className="checkmark" ref={ref}/>
    </label>
  );
};
