import React, { useState, useEffect } from "react";
import SideBar from "../Sidebar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import "./style.css";
import Footer from "../Footer/Footer";
import {
  denialHeader,
  whatsNeededDenial,
  actionTakenDenial,
  adjustCodeDenial,
  dueDateData
} from "./data";
import { RoCheckbox } from "layouts/Form/Checkbox";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Autocomplete,
  ButtonGroup,
  Menu,
  MenuItem,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import API from "Api";
import ClaimNoteHistory from "./noteHistory";
import { NotificationManager } from "react-notifications";
import { denialSearchItems } from "../Footer/data";
import { DenialTable } from "./DataTable";
import { denailFilterDropdown } from "../Footer/data";
import { RoAlert } from "layouts/RoAlert";
import { socketInstance, getClientKey } from "../../socket/connection"

var userRole = sessionStorage.getItem("userDetail");
var socket = socketInstance();
var clientKey = getClientKey();

try {
  userRole = JSON.parse(userRole)?.role_Name;
} catch (error) {
  alert("User details not found in session");
}

const DenialWorksheet = (props) => {
  var actionTakenD = null;
  if (userRole !== "Sytem Administrator" && userRole !== "Rule Creator") {
    actionTakenD = actionTakenDenial.filter((item) => {
      return item.key !== "remove";
    });
  } else {
    actionTakenD = actionTakenDenial;
  }
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [denialList, setDenialList] = useState([]);
  const [denialListAllRows, setDenialListAllRows] = useState([]);
  const [denialListCopy, setDenialListCopy] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [remarkData, setRemarkData] = useState([]);
  const [checkboxForAllRows, setCheckboxForAllRows] = useState(false)
  const [updateDenailDetails, setUpdateDenialdetails] = useState({
    Adj_Code: "",
    Note: '',
    AssignedTo: "",
    WhatsNeeded: "",
    ActionTaken: "",
    Redenied: false,
    Remark: "",
    DueDate: "",
    id: [],
    denialRows: []
  });
  const [openNotePopup, setOpenNotePopup] = useState(false);
  const [noteRequired, setNoteRequired] = useState({
    visible: false,
    tagName: "",
  });
  const handleNoteRequired = () => setNoteRequired(!noteRequired.visible);
  const [optionSelector, setOptionSelector] = useState(false);
  const [selectedColumnData, setSelectedColumnData] = useState([]);
  const [selectedColumnDataCopy, setSelectedColumnDataCopy] = useState([]);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  // const [goBackCheckbox, setGoBackCheckbox] = useState('');
  const [filterKey, setFilterKey] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchData, setSearchData] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [showCheckedValues, setShowCheckedValues] = useState(false);
  const [filterData, setFilterData] = useState({
    EncounterID: [],
    ServiceLocationName: [],
    Code: [],
    ServiceStartDate: [],
    SchedulingProviderName: [],
    PrimaryInsurancePlanName: [],
    EncounterDiagnosisID1: [],
    PatientName: [],
  });
  const [practiceId, setPracticeId] = useState("");
  const assigntoHint = React.useRef("");
  const autoCAssignRef = React.useRef(null);
  const [assigntoValue, setAssigntoValue] = React.useState("");
  const remarkHint = React.useRef("");
  const autoCRemarkCRef = React.useRef(null);
  const [remarkValue, setRemarkValue] = React.useState("");
  const [claimSelector, setClaimSelector] = useState(false);
  const [showDenialListByFilter, setShowDenialListByFilter] = useState("workList");
  const [allDenailData, setAllDenialData] = useState([]);
  const [labelValue, setLabelValue] = useState("Work List");
  const [showRemovePopup, setShowRemovePopup] = useState(false);

  const [denailFromAndToDate, setDenailFromAndToDate] = useState(
    { toDate: null, fromDate: null }
  );
  const [goBackCheckbox, setGoBackCheckbox] = useState('');
  const saveFilter = localStorage.getItem("denialFilter")

  const [encounterCount, setEncounterCount] = useState('');
  const [filterEncounterCount, setFilterEncounterCount] = useState(0);
  const [viewHistoryData, setViewHistoryData] = useState({});
  const [viewHistoryPage, setViewHistoryPage] = useState(0);
  const handleRemovePopup = () => setShowRemovePopup(!showRemovePopup);
  let [practiceIdFromSession, setPracticeIdFromSession] = useState("" + JSON.parse(sessionStorage.getItem('practice'))?.id);
  const [isAllPracticeSelected, setIsAllPracticeSelected] = useState(false)
  let roleData = JSON.parse(sessionStorage?.getItem('userDetail'))

  useEffect(() => {
    // web socket implementation
    try {
      socket.on('updateDenialRows', (data) => {
        if (data.success === true) {
          if (clientKey === data.clientKey && data.practiceId === practiceIdFromSession) {
            if (data.data.length) {
              if (data.actionTaken) {
                setDenialListAllRows((item) => {
                  return item.filter(row => {
                    let updatedItem = data.data.find(updatedRow => updatedRow === row.id)
                    if (updatedItem)
                      return false
                    else
                      return true
                  })
                })
                setDenialListCopy((item) => {
                  return item.filter(row => {
                    let updatedItem = data.data.find(updatedRow => updatedRow === row.id)
                    if (updatedItem)
                      return false
                    else
                      return true
                  })
                })
              } else {
                setDenialListAllRows((item) => {
                  return item.map(row => {
                    let updatedItem = data.data.find(updatedRow => updatedRow.id === row.id)
                    if (updatedItem)
                      return updatedItem
                    else
                      return row
                  })
                })
                setDenialListCopy((item) => {
                  return item.map(row => {
                    let updatedItem = data.data.find(updatedRow => updatedRow.id === row.id)
                    if (updatedItem)
                      return updatedItem
                    else
                      return row
                  })
                })
              }
            }
          }
        }
      })
    } catch (error) {
      console.log('Socket connection error on denial page')
    }
  }, [denialListCopy])

  // logic to search encounters by dropdown selection
  const handleSelectChange = (e) => {
    setSelectedColumnData([])
    setSelectedColumnDataCopy([])
    let { value } = e.target;
    let searchDataKey = denialSearchItems?.find(item => item?.key == value)?.data
    setSearchKey(value);
    setSearchName(denialSearchItems.find(item => item.key == value)?.value)
    setSearchData(denialSearchItems.find(item => item.key == value)?.data)
    setShowSearch(true)
    setShowCheckedValues(false)
    setCheckboxValues(filterData[searchDataKey] ? filterData[searchDataKey] : [])
    handleDropdownData(value);
  }
  const handleDropdownData = (type) => {
    if (type === "dueDate") {
      setSelectedColumnData(dueDateData.map(item => item.label))
      setSelectedColumnDataCopy(dueDateData.map(item => item.label))
    } else {
      let body_data = {
        label: type,
        labelValue: labelValue
      }
      API.post('searchByColumnValuesInDM', body_data).then((res) => {
        let data = res.data.data;
        if (type == "assignedTo") data.unshift("Unassigned")
        setSelectedColumnData(data);
        setSelectedColumnDataCopy(data)
      }).catch(err => {
        console.error(err);
      })
    }
  }
  const searchFilterValues = (value) => {
    setFilterKey(value);
    let searchResult = selectedColumnDataCopy.filter((item) => {
      if (
        item.toUpperCase().indexOf(value.toUpperCase()) !== -1
      ) {
        return item;
      }
    });
    setSelectedColumnData(searchResult);
  };

  // function to handle checkbox values for filter
  const handleCheckboxValues = (e) => {
    const { value } = e.target;
    let selectedVal = value;
    const newSearchData = filterData
    if (!checkboxValues.includes(selectedVal)) {
      setCheckboxValues([...checkboxValues, selectedVal]);
      newSearchData[searchData] = [...checkboxValues, selectedVal]
      setFilterData(newSearchData);
    } else {
      const newFilterObj = checkboxValues.filter((index) => {
        return index !== selectedVal;
      })
      setCheckboxValues(newFilterObj)
      newSearchData[searchData] = newFilterObj ? newFilterObj : []
      setFilterData(newSearchData);
    }
  }

  // function to handle go back
  const handleGoBack = () => {
    setShowCheckedValues(true);
    setShowSearch(false);
    setSearchKey('')
  }

  // function to handle go back checkbox selection
  const handleGoBackCheckbox = (title, values) => {
    const newFilterData = filterData[title]?.filter(item => item != values)
    const newFilterObj = filterData
    newFilterObj[title] = newFilterData ? newFilterData : []
    setFilterData(newFilterObj);
    setGoBackCheckbox(`${title}: ${values}`)
  }

  const dateCompare = (key, dateVal) => {
    let date1
    let futureDate
    let compareElement
    filterData[key]?.forEach(element => {
      if (element === "Missing") {
        compareElement = compareElement ? (compareElement || dateVal === "" || dateVal == null || dateVal == undefined) : (dateVal === "" || dateVal == null || dateVal == undefined)
      }
      if (element === "Late") {
        date1 = new Date().getTime();
        compareElement = compareElement ? (compareElement || (new Date(dateVal).getTime() < date1)) : ((new Date(dateVal).getTime() < date1))
      }
      if (element === "Today") {
        date1 = new Date();
        date1.setHours(0, 0, 0, 0);
        // date1 = new Date().getTime()
        // console.log(date1, new Date(dateVal).getTime())
        // console.log(new Date(date1).toLocaleDateString(), new Date(new Date(dateVal).getTime()).toLocaleDateString())
        compareElement = compareElement ? (compareElement || (new Date(dateVal).setHours(0, 0, 0, 0) == date1.getTime())) : (new Date(dateVal).setHours(0, 0, 0, 0) == date1.getTime())
      }
      if (element === "Tomorrow") {
        date1 = new Date()
        date1.setDate(date1.getDate() + 1)
        date1.setHours(0, 0, 0, 0);
        // console.log(new Date(date1).getTime(), new Date(dateVal).getTime())
        // console.log(new Date(new Date(date1).getTime()).toLocaleDateString(), new Date(new Date(dateVal).getTime()).toLocaleDateString())
        compareElement = compareElement ? (compareElement || (new Date(dateVal).setHours(0, 0, 0, 0) == date1.getTime())) : (new Date(dateVal).setHours(0, 0, 0, 0) == date1.getTime())
      }
      if (element === "This Week") {
        date1 = new Date()
        date1.setDate(date1.getDate())
        futureDate = new Date()
        futureDate.setDate(date1.getDate() + 7)
        compareElement = compareElement ? (compareElement || ((new Date(dateVal).getTime()) <= new Date(futureDate).getTime()) && ((new Date(dateVal).getTime()) >= new Date(date1).getTime())) : ((new Date(dateVal).getTime()) <= new Date(futureDate).getTime()) && ((new Date(dateVal).getTime()) >= new Date(date1).getTime())
      }
      if (element === "Next Week") {
        date1 = new Date()
        date1.setDate(date1.getDate() + 7)
        futureDate = new Date()
        futureDate.setDate(futureDate.getDate() + 14)
        compareElement = compareElement ? (compareElement || ((new Date(dateVal).getTime()) <= new Date(futureDate).getTime()) && ((new Date(dateVal).getTime()) >= new Date(date1).getTime())) : ((new Date(dateVal).getTime()) <= new Date(futureDate).getTime()) && ((new Date(dateVal).getTime()) >= new Date(date1).getTime())
      }
      if (element === "Future") {
        date1 = new Date()
        date1.setDate(date1.getDate() + 14)
        compareElement = compareElement ? (compareElement || new Date(dateVal).getTime() > new Date(date1).getTime()) : (new Date(dateVal).getTime() > new Date(date1).getTime())
      }
      else {
        // compaireElement = true
      }
    });
    return compareElement
  }

  const filterTable = async () => {
    let keys = Object.keys(filterData)
    let finalResult = denialListCopy
    await keys?.map(key => {
      if (filterData[key]?.length != 0) {
        if (key === 'DueDate') {
          finalResult = finalResult?.filter(item => dateCompare(key, item[key]))
        }
        else {
          if (filterData[key] == "Unassigned") {
            finalResult = finalResult?.filter(item => item.AssignedTo == null || item.AssignedTo == "")
          } else {
            finalResult = finalResult?.filter(item => filterData[key]?.includes(item[key]))
          }
        }
      }
    })
    let filterCount = finalResult.map(item => item.EncounterID)
    setDenialList(finalResult)
    setFilterEncounterCount(new Set(filterCount).size)
    setOptionSelector(false)
    setShowSearch(false);
    setShowCheckedValues(true);
    localStorage.setItem('denialFilter', JSON.stringify(filterData))
  }

  // function to clear filter values
  const clearFilter = () => {
    setOptionSelector(false)
    setShowSearch(false);
    setShowCheckedValues(false)
    setSearchKey('')
    setFilterKey('')
    setCheckboxValues([])
    getDenialWorklist(practiceId);
    setFilterData({
      EncounterID: [],
      ServiceLocationName: [],
      Code: [],
      ServiceStartDate: [],
      SchedulingProviderName: [],
      PrimaryInsurancePlanName: [],
      EncounterDiagnosisID1: [],
      PatientName: [],
      DueDate: []
    })
    setFilterEncounterCount(0)
    localStorage.removeItem("denialFilter");
  }

  const selectHandler = (e) => {
    setOptionSelector(!optionSelector)
    setSearchKey('')
    setSelectedColumnData([])
    setShowSearch(false)
  }

  useEffect(() => {
    setDenailFromAndToDate(
      sessionStorage.getItem("denailFromAndToDate")
        ? JSON.parse(sessionStorage.getItem("denailFromAndToDate"))
        : { toDate: null, fromDate: null }
    );
    getDenialWorklist();
    getAssignToDetails();
    getRemarkData();
  }, []);

  useEffect(async () => {
    let storedFilterData = (JSON.parse(localStorage.getItem('denialFilter')))
    // let keys = Object.keys(filterData)
    let newFilterData = storedFilterData ? storedFilterData : filterData;
    let keys = Object.keys(newFilterData)
    let finalResult = denialListCopy
    await keys?.map(key => {
      // if (filterData[key]?.length != 0) {
      if (newFilterData[key]?.length != 0) {
        if (key === 'DueDate') {
          finalResult = finalResult?.filter(item => dateCompare(key, item[key]))
        }
        else {
          // finalResult = finalResult?.filter(item => filterData[key]?.includes(item[key]))
          finalResult = finalResult?.filter(item => newFilterData[key]?.includes(item[key]))
        }
      }
    })
    let filterCount = finalResult.map(item => item.EncounterID)
    setDenialList(finalResult)
    setFilterEncounterCount(localStorage.getItem('denialFilter') ? new Set(filterCount).size : 0)
    setShowCheckedValues(localStorage.getItem('denialFilter') ? true : false);
  }, [denialListCopy]);

  // function to get denial worksheet list
  const getDenialWorklist = () => {
    API.get("getDenialData").then((res) => {
      let data = res.data.data;
      setDenialListCopy(data);
      setDenialListAllRows(data)
      setPracticeId(data.find((item) => item.PracticeID));
      setEncounterCount(res.data.encounter_count)
    });
  };

  const getRemarkData = () => {
    API.get("getRemarkData")
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          setRemarkData(data);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something Went wrong!",
          "Failure!",
          3000
        );
      });
  };

  const getAssignToDetails = () => {
    API.get("/getAssignToUsers")
      .then((result) => {
        if (result.data.success === true && result.data.userList.length) {
          setAssignTo(result.data.userList);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          "Something Went wrong!",
          "Failure!",
          3000
        );
      });
  };

  const handleCheckboxClick = (event, row = null) => {

    let incomingVal = event.target.value;
    switch (event.target.name) {
      case "checkboxForAllRows":
        setCheckboxForAllRows(item => !item)
        if (incomingVal === 'false') {
          setUpdateDenialdetails((items) => {
            return { ...items, id: row.map(eml => eml.id), denialRows: [...row] }
          })
        } else {
          setUpdateDenialdetails((items) => {
            return { ...items, id: [], denialRows: [] }
          })
        }
        break;
      case "userCheckbox":
        incomingVal = +incomingVal;
        let duplicateEncounter = (denialListAllRows || []).filter(item => (item.EncounterID === row.EncounterID && item.id !== incomingVal));
        if (!updateDenailDetails.id.includes(incomingVal)) {
          let allRows = [...updateDenailDetails.denialRows, row, ...duplicateEncounter]
          let idsArr = duplicateEncounter.map((item => item.id))
          idsArr.push(incomingVal)
          let allIds = [...updateDenailDetails.id, ...idsArr]
          setUpdateDenialdetails((item) => {
            return { ...item, id: allIds, denialRows: allRows };
          });
        } else {
          if (duplicateEncounter.length) {
            let idsArr = duplicateEncounter.map((item => item.id))
            idsArr.push(incomingVal)
            setUpdateDenialdetails((item) => {
              return {
                ...item,
                id: item.id.filter((ids) => !idsArr.includes(ids)),
                denialRows: item.denialRows.filter((item => !idsArr.includes(item.id)))
              };
            });
          } else {
            setUpdateDenialdetails((item) => {
              return {
                ...item,
                id: item.id.filter((ids) => ids !== incomingVal),
                denialRows: item.denialRows.filter(item => item.id !== incomingVal)
              };
            });
          }
        }
        break;
      case "denialNote":
        setUpdateDenialdetails((item) => {
          return { ...item, Note: incomingVal };
        });
        break;
      case "WhatsNeeded":
        setUpdateDenialdetails((item) => {
          return { ...item, WhatsNeeded: incomingVal };
        });
        break;
      case "Action":
        setUpdateDenialdetails((item) => {
          return { ...item, ActionTaken: incomingVal };
        });
        break;
      case "redenied":
        setUpdateDenialdetails((item) => {
          return { ...item, Redenied: incomingVal };
        });
        break;
      case "adjCode":
        setUpdateDenialdetails((item) => {
          return { ...item, Adj_Code: incomingVal };
        });
        break;
      default:
        console.log("wrong key selection");
    }
  };

  const resetState = (stateName) => {
    const autoCRemarkCRefEle = autoCRemarkCRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (autoCRemarkCRefEle) {
      autoCRemarkCRefEle.click();
    }
    const autoCautoCAssignRefEle = autoCAssignRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (autoCautoCAssignRefEle) {
      autoCautoCAssignRefEle.click();
    }
    if (stateName === "resetButtonHandle") {
      assigntoHint.current = "";
      remarkHint.current = "";
      setAssigntoValue("");
      setRemarkValue("");
      setUpdateDenialdetails(item => {
        return {
          Adj_Code: "",
          Note: '',
          AssignedTo: "",
          WhatsNeeded: "",
          ActionTaken: "",
          Redenied: false,
          Remark: "",
          DueDate: "",
          id: item.id,
          denialRows: []
        }
      });
    }
    if (stateName === "updateDenailDetails") {
      setCheckboxForAllRows(false)
      assigntoHint.current = "";
      remarkHint.current = "";
      setAssigntoValue("");
      setRemarkValue("");
      setUpdateDenialdetails({
        Adj_Code: "",
        Note: '',
        AssignedTo: "",
        WhatsNeeded: "",
        ActionTaken: "",
        Redenied: false,
        Remark: "",
        DueDate: "",
        id: [],
        denialRows: []
      });
    }

  };

  const updateDenailRow = () => {
    if (updateDenailDetails.ActionTaken !== "") {
      if (updateDenailDetails.Note.length === 0) {
        setNoteRequired({
          tagName: 'note',
          visible: true,
        });
        return;
      }
      if (updateDenailDetails.ActionTaken === "Remove") {
        handleRemovePopup();
        return;
      }
      if (updateDenailDetails.WhatsNeeded === "" || updateDenailDetails.Remark === "" || updateDenailDetails.Adj_Code === "") {
        let remarkCodeCheck = false, adjCodeCheck = false, whatsNeededCheck = false
        if (updateDenailDetails.Adj_Code === "") {
          if (updateDenailDetails.denialRows.filter((code) => {
            return code.Adj_Code === '' || code.Adj_Code === null || code.Adj_Code === undefined
          }).length) {
            setNoteRequired({
              tagName: "Adjustment Code",
              visible: true,
            });
            return;
          } else {
            remarkCodeCheck = true
          }
        } else {
          remarkCodeCheck = true
        }
        if (updateDenailDetails.Remark === "") {
          let remarkCodeArray = remarkData.map(code => code.RemarkCode);
          let dropdownEmptyCheck = updateDenailDetails.denialRows.filter((code) => {
            return code.Remark === '' || code.Remark === null || code.Remark === undefined
          }).length;
          let checkRemarkInRows = updateDenailDetails.denialRows.filter((code) => {
            return remarkCodeArray.indexOf("" + code.Remark) === -1
          }).length;

          if (dropdownEmptyCheck) {
            setNoteRequired({
              tagName: "Remark",
              visible: true,
            });
            return;
          }
          if (checkRemarkInRows) {
            NotificationManager.error(
              "Wrong Remark Code",
              "Failure!",
              3000
            );
            return;
          } else {
            adjCodeCheck = true;
          }
        } else {
          adjCodeCheck = true;
        }
        if (updateDenailDetails.WhatsNeeded === "") {
          let whatsNeededEmptyCheck = updateDenailDetails.denialRows.filter((code) => {
            return code.WhatsNeeded === '' || code.WhatsNeeded === null || code.WhatsNeeded === undefined
          }).length;
          if (whatsNeededEmptyCheck) {
            setNoteRequired({
              tagName: "What's Needed",
              visible: true,
            });
            return;
          } else {
            whatsNeededCheck = true
          }
        } else {
          whatsNeededCheck = true
        }

        if (whatsNeededCheck && adjCodeCheck && remarkCodeCheck) {
          updateData();
        }
      } else if (updateDenailDetails.id.length === 0) {
        NotificationManager.error(
          "Please select atleast one row to continue!",
          "Failure!",
          3000
        );
      } else {
        updateData();
      }
    } else {
      if (updateDenailDetails.id.length === 0) {
        NotificationManager.error(
          "Please select atleast one row to continue!",
          "Failure!",
          3000
        );
      } else {
        updateData();
      }
    }
  };

  const updateData = () => {
    // need to remove denialRows from payload
    localStorage.setItem("undoData", JSON.stringify(updateDenailDetails))
    let { denialRows, ...restpauload } = updateDenailDetails;
    API.put("/updateDenialData", restpauload).then((result) => {
      if (result.data.success === true) {
        NotificationManager.success(
          "Rows updated successfully",
          "Successful!",
          2000
        );
        if (updateDenailDetails.ActionTaken) {
          setEncounterCount(encCount => encCount - (new Set(updateDenailDetails.denialRows.map(item => item.EncounterID))).size)
          socket.emit('updateDenialRows', {
            clientKey: getClientKey(),
            practiceId: practiceIdFromSession,
            ids: updateDenailDetails.id,
            actionTaken: updateDenailDetails.ActionTaken
          })
          resetState("updateDenailDetails");
        } else {
          socket.emit('updateDenialRows', {
            clientKey: getClientKey(),
            practiceId: practiceIdFromSession,
            ids: updateDenailDetails.id
          })
          resetState("updateDenailDetails");
        }
      }
    });
  };

  // function to undo last action
  const undoLastAction = () => {
    let undoData = JSON.parse(localStorage.getItem('undoData'))
    console.log(undoData)
    API.post('undoDenialData', undoData).then(res => {
      console.log(res)
    })
  }

  const statusDropdownHandler = (e) => {
    setClaimSelector(!claimSelector);
  };

  const handleClaimsDropdown = async (e) => {
    setShowDenialListByFilter(e.target.value);
    setLabelValue(
      denailFilterDropdown.filter((item) => item.key === e.target.value)[0]
        .value
    );
    setDenialList(null);
    setClaimSelector(!claimSelector);
    if (e.target.value === "workList") {
      getDenialWorklist();
    }
  };

  const handleDateChange = (event, date_type) => {
    if (date_type === "DENIAL_FROM") {
      setDenailFromAndToDate((item) => {
        return { ...item, fromDate: event };
      });
    }
    if (date_type === "DENIAL_TO") {
      setDenailFromAndToDate((item) => {
        return { ...item, toDate: event };
      });
    }
  };

  useEffect(() => {
    if (updateDenailDetails.id.length) {
      if (!checkboxForAllRows)
        setCheckboxForAllRows(true)
    } else
      setCheckboxForAllRows(false)
  }, [updateDenailDetails.id])

  useEffect(() => {
    applyToAndFromDateFilter();
  }, [denailFromAndToDate, denialListAllRows]);

  function applyToAndFromDateFilter() {
    if (denailFromAndToDate.toDate && denailFromAndToDate.fromDate) {
      sessionStorage.setItem(
        "denailFromAndToDate",
        JSON.stringify(denailFromAndToDate)
      );
      let data = denialListAllRows.filter((item) => {
        var denailDate = new Date(item.Denial_Date);
        var fromDate = new Date(denailFromAndToDate.fromDate);
        var toDate = new Date(denailFromAndToDate.toDate);
        return denailDate > fromDate && denailDate < toDate; // item.Denial_Date > denailFromAndToDate.fromDate && item.Denial_Date < denailFromAndToDate.toDate
      });
      setDenialList(data);
    } else if (
      denailFromAndToDate.toDate &&
      denailFromAndToDate.fromDate === null
    ) {
      sessionStorage.setItem(
        "denailFromAndToDate",
        JSON.stringify(denailFromAndToDate)
      );
      let data = denialListAllRows.filter((item) => {
        var denailDate = new Date(item.Denial_Date);
        var toDate = new Date(denailFromAndToDate.toDate);
        return denailDate < toDate;
      });
      setDenialList(data);
    } else if (
      denailFromAndToDate.fromDate &&
      denailFromAndToDate.toDate === null
    ) {
      sessionStorage.setItem(
        "denailFromAndToDate",
        JSON.stringify(denailFromAndToDate)
      );
      let data = denialListAllRows.filter((item) => {
        var denailDate = new Date(item.Denial_Date);
        var fromDate = new Date(denailFromAndToDate.fromDate);
        return denailDate > fromDate;
      });
      setDenialList(data);
    } else if (
      denailFromAndToDate.fromDate === null &&
      denailFromAndToDate.toDate === null
    ) {
      setDenialList(denialListAllRows)
      sessionStorage.setItem(
        "denailFromAndToDate",
        JSON.stringify(denailFromAndToDate)
      );
    }
  }

  const handleNotePopup = () => {
    if (updateDenailDetails.id.length === 0) {
      NotificationManager.error('Please select atleast one row to continue!', 'Failure!', 3000);
    } else {
      setOpenNotePopup(!openNotePopup);
      const viewData = denialList?.find(item => item.id === updateDenailDetails.id[0])
      // setViewHistoryPage(viewHistoryPage + 1)
      setViewHistoryData(viewData)
    }
  }
  const handleClickNextForView = () => {
    const currectPage = viewHistoryPage + 1
    const viewData = denialList?.find(item => item.id === updateDenailDetails.id[currectPage])
    setViewHistoryPage(currectPage)
    setViewHistoryData(viewData)
  }
  const handleClickPreviousForView = () => {
    const currectPage = viewHistoryPage - 1
    const viewData = denialList?.find(item => item.id === updateDenailDetails.id[currectPage])
    setViewHistoryPage(currectPage)
    setViewHistoryData(viewData)
  }
  const styles = {
    content: {
      width: "100%",
      marginTop: "0rem",
    },
  };
  return (
    <section>
      <SideBar
        handleChangePractice={(selectedPractice) => {
          resetState('updateDenailDetails')
          getDenialWorklist(selectedPractice.id);
          setPracticeIdFromSession("" + JSON.parse(sessionStorage.getItem('practice'))?.id)
          if (selectedPractice === 'all') {
            setIsAllPracticeSelected(true)
          } else setIsAllPracticeSelected(false)
        }}
        hasAllPracticeOption={roleData.roleId === 3 ? false : true}
      />
      {showDenialListByFilter === "workList" && (
        <>
          <div className="section-layout" >
            <div className="row font-face-futura text-start">
              <span className="font18">Denial Management</span>
            </div>
            <div className="botDivider"></div>

            <div className="ruleout-grid  mb-3">
              <div>
                <div className="font-face-futura text-start noteDiv">
                  <span className="px-1">Note</span>
                  <textarea
                    placeholder="Write what was done to fix the claim..."
                    className="p-2 denialNote w-100"
                    name="denialNote"
                    value={updateDenailDetails.Note}
                    onChange={handleCheckboxClick}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center gap-2">
                <div className="ruleout-grid-three">
                  <div>
                    <span className="denial-forminput">
                      <label className="text-nowrap">Assigned To</label>
                      <Autocomplete
                        className="justify-content-start autocomplete-select2"
                        ref={autoCAssignRef}
                        onKeyDown={(event) => {
                          if (event.key === "Tab") {
                            if (assigntoHint.current) {
                              setAssigntoValue(assigntoHint.current);
                              setUpdateDenialdetails((item) => {
                                return {
                                  ...item,
                                  AssignedTo: assigntoHint.current,
                                };
                              });
                              event.preventDefault();
                            }
                          }
                        }}
                        onClose={() => {
                          assigntoHint.current = "";
                        }}
                        onChange={(event, newValue) => {
                          setAssigntoValue(
                            newValue && newValue.id
                              ? newValue.first_name + " " + newValue.last_name
                              : ""
                          );
                          setUpdateDenialdetails((item) => {
                            return {
                              ...item,
                              AssignedTo:
                                newValue && newValue.id ? newValue.id : "",
                            };
                          });
                        }}
                        blurOnSelect
                        // disablePortal
                        inputValue={assigntoValue}
                        id="combo-box-hint-demo-"
                        options={assignTo}
                        getOptionLabel={(option) =>
                          option.first_name + " " + option.last_name
                        }
                        renderInput={(params) => {
                          return (
                            <Box className="autocompleteselect2-container" >
                              <TextField
                                className="autocompleteinput-container"
                                {...params}
                                onChange={(event) => {
                                  const newValue = event.target.value;
                                  setAssigntoValue(newValue);
                                  setUpdateDenialdetails((item) => {
                                    return { ...item, AssignedTo: newValue };
                                  });
                                  const matchingOption = assignTo.find(
                                    (option) =>
                                      option.first_name.startsWith(
                                        newValue
                                      ) ||
                                      option.last_name.startsWith(newValue)
                                  );
                                  if (newValue && matchingOption) {
                                    assigntoHint.current =
                                      matchingOption.first_name +
                                      " " +
                                      matchingOption.last_name;
                                  } else {
                                    assigntoHint.current = "";
                                  }
                                }}
                                placeholder="Select"
                              />
                            </Box>
                          );
                        }}
                      />
                    </span>
                  </div>
                  <div>
                    <span className="denial-forminput">
                      <label className="text-nowrap">What's Needed</label>
                      <select
                        name="WhatsNeeded"
                        value={updateDenailDetails.WhatsNeeded}
                        onChange={(event) => handleCheckboxClick(event)}
                        className="denialSelect2"
                      >
                        <option value="" disabled selected>Select</option>
                        {whatsNeededDenial.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                  <div>
                    <span className="denial-forminput">
                      <label className="text-nowrap">Action Taken</label>

                      <select
                        className="denialSelect2"
                        name="Action"
                        value={updateDenailDetails.ActionTaken}
                        onChange={(event) => handleCheckboxClick(event)}
                      >
                        <option value="" disabled selected>Select</option>
                        {actionTakenD.map((item) => (
                          <option key={item.key} value={item.value}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                </div>
                <div className="ruleout-grid-four">
                  {/* <div>
                    <span className="denial-forminput">
                        <label className="text-nowrap">Redenied</label>

                        <select
                          name="redenied"
                          value={updateDenailDetails.Redenied}
                          onChange={(event) => handleCheckboxClick(event)}
                          className="denialSelect2"
                        >
                          <option value="">Select</option>
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </select>
                      </span>
                  </div> */}
                  <div>
                    <span className="denial-forminput">
                      <label className="text-center">Adjustment Code</label>

                      <select
                        name="adjCode"
                        className="denialSelect2"
                        value={updateDenailDetails.Adj_Code}
                        onChange={(event) => handleCheckboxClick(event)}
                      >
                        <option value="" disabled selected>Select</option>
                        {adjustCodeDenial.map((adj) => (
                          <option key={adj.key} value={adj.value}>
                            {adj.value}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>
                  <div>
                    <span className="denial-forminput">
                      <label className="text-nowrap">Remark</label>
                      <Autocomplete
                        className=" justify-content-start autocomplete-select2"
                        ref={autoCRemarkCRef}
                        onKeyDown={(event) => {
                          if (event.key === 'Tab') {
                            if (remarkHint.current) {
                              setRemarkValue(remarkHint.current);
                              setUpdateDenialdetails(item => {
                                return { ...item, Remark: remarkHint.current }
                              });
                              event.preventDefault();
                            }
                          }
                        }}
                        onClose={() => {
                          remarkHint.current = '';
                        }}
                        onChange={(event, newValue) => {
                          setRemarkValue(newValue && newValue.RemarkCode ? newValue.RemarkCode : '');
                          setUpdateDenialdetails(item => {
                            return { ...item, Remark: newValue && newValue.RemarkCode ? newValue.RemarkCode : '' }
                          });
                        }}
                        blurOnSelect
                        disablePortal
                        inputValue={remarkValue}
                        id="combo-box-hint-demo"
                        options={remarkData}

                        getOptionLabel={(option) => option.RemarkCode}
                        renderInput={(params) => {
                          return (
                            <Box className="autocompleteselect2-container">
                              <TextField
                                className="autocompleteinput-container"
                                {...params}
                                onChange={(event) => {
                                  const newValue = event.target.value;
                                  setRemarkValue(newValue);
                                  setUpdateDenialdetails(item => {
                                    return { ...item, Remark: newValue }
                                  });
                                  const matchingOption = remarkData.find((option) =>
                                    (option.RemarkCode).startsWith(newValue)
                                  );

                                  if (newValue && matchingOption) {
                                    remarkHint.current = matchingOption.RemarkCode;
                                  } else {
                                    remarkHint.current = '';
                                  }
                                }}
                                placeholder="select"
                              />
                            </Box>
                          );
                        }}
                      />
                    </span>
                  </div>
                  <div>
                    <span className="denial-forminput">
                      <label className="text-nowrap"> Date</label>
                      <DatePicker
                        className="denialSelect2"
                        format={"MM-dd-yyyy"}
                        placeholderText="MM/DD/YYYY"
                        name="DueDate"
                        selected={updateDenailDetails.DueDate}
                        onChange={(date) =>
                          setUpdateDenialdetails((item) => {
                            return { ...item, DueDate: date };
                          })
                        }
                      // customInput={
                      // <MaskedInput
                      //     // pipe={"10-10-2025"}
                      //     mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      //     guide={true}
                      //     keepCharPositions={true} />}
                      />
                    </span>
                  </div>
                  <div>
                    <div className="denial-buttons">
                      <Button
                        className="text-white resetBtn"
                        variant="contained"
                        onClick={handleNotePopup}
                      >
                        <span className="text-capitalize font-face-futura">
                          View
                        </span>
                      </Button>
                      <Button
                        className="text-white resetBtn"
                        onClick={() => resetState("resetButtonHandle")}
                        variant="contained"
                      >
                        <span className="text-capitalize font-face-futura">
                          Reset
                        </span>
                      </Button>
                      {/* <Button
                          className="text-white saveButn"
                          variant="contained"
                          onClick={updateDenailRow}
                        >
                          <span className="text-capitalize font-face-futura">
                            Save
                          </span>
                        </Button> */}
                      <ButtonGroup>
                        <Button
                          className="text-white saveButn"
                          variant="contained"
                          onClick={updateDenailRow}
                        >
                          <span className="text-capitalize font-face-futura">
                            Save
                          </span>
                        </Button>
                        <Button
                          className="savebtn-dropdown"
                          size="small"
                          aria-controls={open ? 'split-button-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            setAnchorEl(e.target)
                            setOpen(!open)
                          }}>
                          <ArrowDropDownIcon />
                        </Button>
                        <Menu
                          id="split-button-menu"
                          className="savebtn-modal"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={() => setAnchorEl(null)}>
                          <MenuItem onClick={undoLastAction}>Undo Last Action</MenuItem></Menu>
                      </ButtonGroup>
                    </div>
                  </div>
                  {noteRequired.visible && (
                    <Dialog
                      fullWidth
                      open={noteRequired.visible}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <div align="center" className="avatar3">
                        <CancelIcon
                          className="dialogCancel"
                          fontSize="large"
                        />
                      </div>
                      <div
                        align="center"
                        className="dialog-box3 dialog-box-denail"
                      >
                        <DialogTitle id="alert-dialog-title">
                          <span className="font-face-futura dialogTitle dialogTitlefontSize">
                            {(noteRequired.tagName || "").toUpperCase()}{" "}
                            REQUIRED
                          </span>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description ">
                            <span className="font-face-futura dialogText">
                              A {noteRequired.tagName} is required before
                              changing this claim's status
                            </span>
                          </DialogContentText>
                          <Button
                            fullWidth
                            className="mt-4 dialogBtn"
                            variant="contained"
                            onClick={handleNoteRequired}
                          >
                            <span className="font-face-futura">GO BACK</span>
                          </Button>
                        </DialogContent>
                      </div>
                    </Dialog>
                  )}
                  {showRemovePopup && (
                    <RoAlert
                      show={showRemovePopup}
                      handleClose={() => handleRemovePopup()}
                      handleSubmit={() => {
                        updateData();
                        handleRemovePopup();
                      }}
                      title={"Remove Row?"}
                      type="info"
                      hideFooterLine={true}
                      content={
                        <div className="alert-footer-text text-center">
                          You are currently removing this claim as a denial We
                          will not count this as a denial in reporting and it
                          will remove it from the worklist
                          <br />
                          <br />
                          Once this is completed you cannot reverse this
                          decision. Would you like to remove the row?
                        </div>
                      }
                    />
                  )}
                  <ClaimNoteHistory
                    openNote={openNotePopup}
                    closeNotePopup={handleNotePopup}
                    firstPage={viewHistoryPage}
                    totalPage={updateDenailDetails.id.length}
                    clickNext={handleClickNextForView}
                    clickPrevious={handleClickPreviousForView}
                    viewData={viewHistoryData}
                  />
                </div>
              </div>
            </div>
            <div style={styles.content}>
              <div
                className={`1pt-4 table-responsive linkedprayer-table custom-size tableresponsivecustom denial-style denial-container w-100 ${props.showRule ? "opentoggle1" : ""
                  }`}
              >
                { }{" "}
                <table className="denial-table">
                  <thead>
                    <tr>
                      <th className="first-header">
                        <div
                          className="innerdata"
                          style={{ borderRadius: "9px 0 0 9px" }}
                        >
                          <RoCheckbox
                            name={"checkboxForAllRows"}
                            value={checkboxForAllRows}
                            checked={checkboxForAllRows}
                            onChange={(e) => {
                              handleCheckboxClick(e, denialList)
                            }}
                          />
                        </div>
                      </th>
                      {isAllPracticeSelected && <th className="text-center">
                        <span>{"Practice Acronym"}</span>
                      </th>}
                      {denialHeader.map((col, index) => (
                        <th className="text-center" key={index}>
                          <span>{col.name}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {denialList?.length > 0 &&
                      denialList?.map((data, idx) => (
                        <tr
                          style={{ cursor: "pointer" }}
                          key={idx}
                          className={updateDenailDetails.id.includes(
                            data.id
                          ) ? "table-row active-tablerow" : "table-row"}
                        >
                          <td>
                            <div
                              className="innerdata"
                              style={{ borderRadius: "9px 0 0 9px" }}
                            >
                              <RoCheckbox
                                name={"userCheckbox"}
                                value={data.id}
                                onChange={(e) => handleCheckboxClick(e, data)}
                                checked={updateDenailDetails.id.includes(
                                  data.id
                                )}
                              />
                            </div>
                          </td>
                          {/* <td style={{ textAlign: 'center' }}>
                                        <div className='innerdata'>{data.flag}</div>
                                    </td> */}
                          {isAllPracticeSelected && <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              {data.PracticeAcronym}
                            </div>
                          </td>}
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.CLAIMID}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              {data.EncounterID}
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                title={data.PatientName}
                                className="deal-content"
                              >
                                {data.PatientName}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              {new Date(
                                data.ServiceStartDate
                              ).toLocaleDateString("en-US")}
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                title={data.SchedulingProviderName}
                                className="deal-content"
                              >
                                {data.SchedulingProviderName}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                title={data.ServiceLocationName}
                                className="deal-content"
                              >
                                {data.ServiceLocationName}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                title={data.Billed_to}
                                className="deal-content"
                              >
                                {data.Billed_to}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.Code}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.Mod}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.DXC1}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.Total_Charge}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.Balance}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">{data.Redenied}</div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                className="one-line-ellipsis"
                                title={
                                  adjustCodeDenial.find(
                                    (item) => item.value === data.Adj_Code
                                  )?.desc
                                }
                              >
                                {data.Adj_Code}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata cstm-tooltip-container">
                              <div className="custom-toltip">
                                {data.Remark}
                                <div className="tooltip-text">
                                  <p>{data.Adj_Code + " " + data.Remark + ": " +
                                    remarkData.find(
                                      (item) => item.RemarkCode === data.Remark
                                    )?.Description}</p>
                                  <p>{data.advice && data.advice?.map(item => (<>{item.advice_code + ":" + item.description}</>))}</p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                title={data.AssignedTo}
                                className="one-line-ellipsis"
                              >
                                {data.AssignedTo}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              <span
                                title={data.whatsNeeded}
                                className="one-line-ellipsis"
                              >
                                {data.WhatsNeeded}
                              </span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div className="innerdata">
                              {new Date(data.Denial_Date).toLocaleDateString("en-US")}
                            </div>
                          </td>
                          <td>
                            <div className="innerdata">
                              {data.DueDate !== null ? (
                                <span className={moment(new Date().toLocaleDateString("en-US")).isAfter(new Date(data.DueDate).toLocaleDateString("en-US"))
                                  ? "duedate" : ""}
                                >
                                  {new Date(data.DueDate).toLocaleDateString("en-US")}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="rule-footer">
                  <div className="foot-flex">
                    {/* <div className='foot-right3'> */}
                    <div>
                      <div>
                        <span className="text-dark font-face-futura">
                          {showCheckedValues &&
                            <><span className='fontw'>Filtered Encounters:{' '}
                              <span className='fw-normal'>{filterEncounterCount ? filterEncounterCount : 0}</span>
                            </span><br /></>
                          }
                          <span className='fontw'>Total Encounters: <span className='fw-normal'>{encounterCount}</span></span>
                        </span>
                      </div>
                    </div>
                    <div className="ms-auto">
                      {/* <div style={{ marginLeft: props.ruleSuccess ? '0px' : '110px' }}> */}
                      <div>
                        {/* {props.ruleSuccess ? <></> : <div>
                                        {localStorage.getItem("refreshState") === true ?
                                            <p className="text-dark">Refreshing...</p> :
                                            <a
                                                style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                onClick={() => this.OpenChargesPopup()}>
                                                <img src={refresh} alt="logo" width={45} style={{ height: 50, float: 'left', marginRight: '20px', marginTop: 10 }} /></a>
                                        }
                                    </div>} */}
                        <span className="text-dark font-face-futura">
                          <p className="fontw mb-0 text-start">
                            Total Claims: {(denialList || []).length}{" "}
                          </p>
                          <span className="dateFont">
                            Last Updated: 09/28/2024
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={showRule ? 'rule-footer opentoggle2' : 'rule-footer'}>
                        <div className='foot-flex'>
                            <div className='foot-right'>
                                <div style={{ marginLeft: ruleSuccess ? '0px' : '110px' }}>
                                    {ruleSuccess ? <></> : <div>
                                        {localStorage.getItem("refreshState") === true ?
                                            <p className="text-dark">Refreshing...</p> :
                                            <a
                                                style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                onClick={() => this.OpenChargesPopup()}>
                                                <img src={refresh} alt="logo" width={45} style={{ height: 50, float: 'left', marginRight: '20px', marginTop: 10 }} /></a>
                                        }
                                    </div>}
                                    <span className="text-dark font-face-futura">
                                        <span className='fontw'>
                                            Total Claims: {this.state.rulesRan ? encounterData.length : this.state.searchValue === '' ? encounterCount : encounterLength} </span><br />
                                        <span className='dateFont'>{practiceData?.filterDateType === 'createdDate' ? 'Created' : 'Serviced'}{' '}
                                            <b>From:</b> {practiceData?.fromDate}{' '}
                                            <b>To:</b> {practiceData?.toDate}
                                        </span><br />
                                        <span className='dateFont'>Last Updated: {new Date(practiceData?.lastUpdatedDate).toLocaleString('en-US')}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
              </div>
            </div>
          </div>
        </>
      )}
      {(showDenialListByFilter === "pendingWorkedValidation" ||
        showDenialListByFilter === "completed") && (
          <div className="section-layout">
            <DenialTable filterType={showDenialListByFilter} />
          </div>
        )}
      <Footer
        selectValue={searchKey}
        onSelectChange={handleSelectChange}
        columnData={selectedColumnData}
        filterValue={filterKey}
        searchFilterValues={searchFilterValues}
        checkboxValues={checkboxValues}
        handleCheckbox={handleCheckboxValues}
        showSearch={showSearch}
        showCheckedValues={showCheckedValues}
        handleGoBack={handleGoBack}
        handleGobackCheckbox={handleGoBackCheckbox}
        filteredValues={filterData}
        filterTable={filterTable}
        clearFilter={clearFilter}
        optionSelector={optionSelector}
        statusDropdownHandler={statusDropdownHandler}
        claimSelector={claimSelector}
        setClaimSelector={setClaimSelector}
        handleClaimsDropdown={handleClaimsDropdown}
        labelValue={labelValue}
        handleDateChange={handleDateChange}
        denailFromAndToDate={denailFromAndToDate}
        selectHandler={selectHandler}
      />
    </section>
  );
};

export default DenialWorksheet;