import { useEffect, useState } from 'react';
import {pendingWorkedValidationHeader} from './data'
import TablePagination from '@mui/material/TablePagination';
import API from 'Api';

const DenialTable = ({filterType}) => {
    let rowCount = 50;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowCount);
    const [denialList, setDenialList] = useState([])
    const [totalRecord , setTotalRecord] = useState(null)
  
    const getData = async ({filterType = null, limit = rowsPerPage, pgPage = page}) => {
        if(filterType === 'completed') {
            let result = await API.get(`/getCompletedList?page=${pgPage}&limit=${limit}`)
            if (result.data.success === true) {
                setDenialList(result.data.data)
                setTotalRecord(result.data.pagination.totalRecords)
            }
        } else {
            let result = await API.get(`/getPendingWorkedValidation?page=${pgPage}&limit=${limit}`)
            if (result.data.success === true) {
                setDenialList(result.data.data)
                setTotalRecord(result.data.pagination.totalRecords)
            }
        }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      getData({pgPage: newPage})
    };


  
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        let limit = parseInt(event.target.value)
        setRowsPerPage(limit);
        getData({limit, pgPage: 0})
    };

    const resetStates = () => {
        setDenialList([])
        setTotalRecord(0)
        setPage(0)
    }

    useEffect(() => {
        if(filterType === 'pendingWorkedValidation') {
            getData({limit: rowsPerPage})
        } else if(filterType === 'completed') {
            resetStates();
            getData({filterType, limit: rowsPerPage})
        }
    }, [filterType])

    const styles = {
        content: {
            width: '100%',
            marginTop: '0rem',
            // padding: '0rem 0rem 1rem 1.5rem',
            // border: '0px !important',
            // position: 'absolute'
        }
    }

    const getColour = (actionName) => {
        
        let colour = ''
        switch (actionName) {
            case 'Appealed' : 
                colour= '#C88700'
                break
            case 'Rebilled' : 
                colour= '#C88700'
                break
            case 'Ins Transfer' : 
                colour= '#C88700'
                break
            case 'Patient Billed' : 
                colour= '#0097FA'
                break
            case 'Settled' : 
                colour= '#EB0000'
                break
            case 'Voided' : 
                colour= '#EB0000'
                break
            case 'Remove' : 
                colour= '#535353'
                break
            case 'Unknown' : 
                colour= '#535353'
                break
            default : 
                break
        }
        return colour
    }

    return <div style={styles.content}>
                    <div className={`1pt-4 table-responsive custom-size tableresponsivecustom pending-table denial-style w-100 }`}>
                        { } <table className="denial-table">
                            <thead>
                                <tr>
                                    {pendingWorkedValidationHeader.map((col, index) => (
                                        <th className='text-center' key={index} >
                                            <span>{col.name}</span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {denialList?.length > 0 && denialList?.map((data, idx) => (
                                    <tr style={{ cursor: 'pointer' }} key={idx} className='table-row'>
                                        <td style={{ textAlign: 'center'}}>
                                            <div className='innerdata'>{data.CLAIMID}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.EncounterID}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.PatientName} className='deal-content'>{data.PatientName}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{new Date(data.ServiceStartDate).toLocaleDateString('en-US')}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.SchedulingProviderName} className='deal-content'>{data.SchedulingProviderName}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.ServiceLocationName} className='deal-content'>{data.ServiceLocationName}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.Billed_to} className='deal-content'>{data.Billed_to}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.Code}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.Mod}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.DXC1}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.Total_Charge}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.Balance}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{data.Redenied}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <div className='innerdata'>{data.Adj_Code}</div>
                                            </div>
                                        </td>                                  
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.Remark} className='one-line-ellipsis'>{data.Remark}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.WhatsNeeded} className='one-line-ellipsis'>{data.WhatsNeeded}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>
                                                <span title={data.assignedTo} className='one-line-ellipsis'>{data.AssignedTo}</span>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata'>{new Date(data.Denial_Date).toLocaleDateString('en-US')}</div>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            <div className='innerdata' >
                                                <span style={{ backgroundColor: getColour(data.ActionTaken) , color: '#fff', lineHeight: 'normal', padding: "4px 0px", borderRadius: '25px', width: '100%'}}>
                                                    {data.ActionTaken}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
    
                        <div className='rule-footer'>
                            <div className='foot-flex'>
                                {/* <div className='foot-right3'> */}
                                <div >
                                    <div>
                                        <span className="text-dark font-face-futura">
                                            <span className='fontw'>Total Encounters: <span className='fw-normal'>{totalRecord}</span></span>
                                        </span>
                                    </div>
                                </div>
                                {/* <div className='ms-auto'> */}
                                    {/* <div style={{ marginLeft: props.ruleSuccess ? '0px' : '110px' }}> */}
                                    <div>
                                    <TablePagination
                                        component="p"
                                        count={totalRecord}
                                        page={page}
                                        rowsPerPageOptions={[rowCount,100,200]}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}   
                                        className='pendingtable-pagination'                                     
                                        />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
}

export {
    DenialTable
}