import { Box } from "layouts/Box";
import { Content } from "layouts/Content";
import { TextInput } from "layouts/Form/TextInput";
import { Main } from "layouts/Main";
import { Panel } from "layouts/Panel";
import Section from "layouts/Section";
import Sidebar from "../Sidebar";
import { MdHelp } from "react-icons/md";
import { ROButton } from "layouts/RoButton";
import { useEffect, useState } from "react";
import { deleteLinkedPayer, fetchAllLinkedPayers, linkPayerWithInsurance, updateLinkedPayer } from "./helper";
import { ROModal } from "layouts/Modal";
import { TrizettoPayerList } from "./TrizettoPayersList";
import { LinkedPayerTable } from "./LinkedPayerTable";
import { LinkedInsuracePayerFooter } from "./Footer";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { LoadingSpinner } from "lib/loader";
import { RoAlert } from "layouts/RoAlert";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@mui/material";
import { attachState } from "./constants";
import { Margin } from "@mui/icons-material";

const Insurances = ({ }) => {
  const [linkedPayers, setLinkedPayers] = useState([]);
  const [linkedPayersCache, setLinkedPayersCache] = useState([]);
  const [selectedInsurancePayer, setSelectedInsurancePayer] = useState();
  const [selectedInsurancePayerId, setSelectedInsurancePayerId] = useState();
  const [showTrizettoModal, setShowTrizettoModal] = useState(false);
  const [showLinkingModal, setShowLinkingModal] = useState(false);
  const handleOpenLinkingModal = () => setShowLinkingModal(true);
  const handleOpenTrizettoModal = () => setShowTrizettoModal(true);
  const handleCloseTrizettoModal = () => setShowTrizettoModal(false);
  const [showLinkingConfirmation, setShowLinkingConfirmation] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);

  const handleLinkingConfirmation = () => setShowLinkingConfirmation(!showLinkingConfirmation)
  const handleTicketModal = () => setShowTicketModal(!showTicketModal)

  const params = useParams();
  const location = useLocation();
  params.payerName = location?.state?.PrimaryInsurancePlanName;
  const { encounterId, payerName, payerId, encounterType } = useParams();
  const [isNewLinking, setIsNewLinking] = useState(false);
  const [linkPayerVal, setLinkPayerVal] = useState({
    payerName: payerName || "",
    payerId: payerId || "",
    claimsPhoneNumber: "",
    mailingAddress: "",
    payerUrl: "",
    appealViaPortal: "",
    appealURL: '',
    firstTimeBilled: "",
    dateOfDenial: "",
    eraSetup: ""
  });
  const [isRowChecked, setIsRowChecked] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);

  const handleSelectInsurancePayer = (e, linkpayer) => {
    const isChecked = e.target.checked;
    // setSelectedInsurancePayerId(isChecked ? linkpayer.id : undefined);
    setSelectedInsurancePayerId(isChecked ? linkpayer.linkedPayerId : undefined);
    setSelectedInsurancePayer(isChecked ? linkpayer : undefined);
    setIsRowChecked(isChecked ? true : false)
    if (isChecked) {
      console.log(linkpayer)
      const linkPayerObj = {
        payerName: linkpayer.linkedPayer || "",
        payerId: linkpayer.linkedPayerId || "",
        claimsPhoneNumber: linkpayer.claimPhoneNumber || "",
        mailingAddress: linkpayer.mailingAddress || "",
        payerUrl: linkpayer.payerURL || "",
        appealViaPortal: linkpayer.appealViaPortal || "",
        appealURL: linkpayer.appealViaPortal == 'yes' ? linkpayer.appealURL : "" || "",
        firstTimeBilled: linkpayer.firstTimeBilled || "",
        dateOfDenial: linkpayer.dateOfDenial || "",
        eraSetup: linkpayer.ERASetup || ""
      }
      setLinkPayerVal(linkPayerObj)
    } else {
      setLinkPayerVal({
        payerName: payerName || "",
        payerId: payerId || "",
        claimsPhoneNumber: "",
        mailingAddress: "",
        payerUrl: "",
        appealViaPortal: "",
        appealURL: "",
        firstTimeBilled: "",
        dateOfDenial: "",
        eraSetup: ""
      })
    }
  };

  const handleAfterUpdateLinkedPayer = (linkedPayer) => {
    const indexOfLinkedPayer = linkedPayers.findIndex(
      (payer) => payer.id === linkedPayer.id
    );
    if (indexOfLinkedPayer !== -1) {
      linkedPayers[indexOfLinkedPayer] = linkedPayer;
      setLinkedPayers(linkedPayers);
      setLinkedPayersCache(linkedPayers);
    }
  };

  const handleAfterDeleteLinkedPayer = (linkedPayer) => {
    // console.log(linkedPayer)
    // const listOfLinkedPayer = linkedPayers.filter(
    //   (payer) => payer.linkedPayerId !== linkedPayer.id
    // );
    // setLinkedPayers(listOfLinkedPayer);
    // setLinkedPayersCache(listOfLinkedPayer);
    fetchLinkedPayers();
  };

  const handleDeletePayer = async () => {
    const { linkedPayerId } = selectedInsurancePayer;
    if (!linkedPayerId) return;
    LoadingSpinner.show();
    const res = await deleteLinkedPayer(linkedPayerId);
    if (res) {
      NotificationManager.success("Deleted successfully.");
      handleAfterDeleteLinkedPayer(selectedInsurancePayer);
      setSelectedInsurancePayer(undefined);
      setShowDeleteConfirmation(false);
      setIsRowChecked(false)
      setLinkPayerVal({
        payerName: payerName || "",
        payerId: payerId || "",
        claimsPhoneNumber: "",
        mailingAddress: "",
        payerUrl: "",
        appealViaPortal: "",
        appealURL: "",
        firstTimeBilled: "",
        dateOfDenial: "",
        eraSetup: ""
      })
    }
    LoadingSpinner.hide();
  };

  const handleSaveClick = async () => {
    const { payerId, payerName, claimsPhoneNumber, mailingAddress, payerUrl, appealViaPortal, appealURL, firstTimeBilled, dateOfDenial, eraSetup } = linkPayerVal;
    console.log(linkPayerVal)
    // await updateLinkState(linkPayerVal)
    if (!payerId || !payerName) {
      NotificationManager.error("Invalid form values");
      return;
    }
    const res = await updateLinkedPayer({
      ...selectedInsurancePayer,
      linkedPayer: payerName,
      linkedPayerId: payerId,
      claimPhoneNumber: claimsPhoneNumber,
      mailingAddress: mailingAddress,
      appealViaPortal: appealViaPortal,
      appealURL: appealViaPortal == 'yes' ? appealURL : "",
      payerURL: payerUrl,
      firstTimeBilled: firstTimeBilled,
      dateOfDenial: dateOfDenial,
      ERASetup: eraSetup
    });
    if (res) {
      handleAfterUpdateLinkedPayer({
        ...selectedInsurancePayer,
        linkedPayer: payerName,
        linkedPayerId: payerId,
        claimPhoneNumber: claimsPhoneNumber,
        mailingAddress: mailingAddress,
        appealViaPortal: appealViaPortal,
        appealURL: appealViaPortal == 'yes' ? appealURL : "",
        payerURL: payerUrl,
        firstTimeBilled: firstTimeBilled,
        dateOfDenial: dateOfDenial,
        ERASetup: eraSetup
      });
      NotificationManager.success("Updated successfully.");
      setLinkPayerVal({
        payerName:  "",
        payerId: "",
        claimsPhoneNumber: "",
        mailingAddress: "",
        payerUrl: "",
        appealViaPortal: "",
        appealURL: "",
        firstTimeBilled: "",
        dateOfDenial: "",
        eraSetup: ""
      })
      setShowEditConfirmation(false);
      fetchLinkedPayers();
      setIsRowChecked(false)
    }
  };

  const handleAfterRelinking = (payer, trizettoInsuranceCompany) => {
    if (!payer || !trizettoInsuranceCompany) {
      console.error("Debug on line number 54 - insurances/index.jsx");
      return;
    }

    const { id } = payer;
    const indexOfSelectedPayer = linkedPayers.findIndex(
      (payer) => payer.id === id
    );

    if (indexOfSelectedPayer !== -1) {
      linkedPayers[indexOfSelectedPayer].payerId =
        trizettoInsuranceCompany.payerId;
      linkedPayers[indexOfSelectedPayer].payerName =
        trizettoInsuranceCompany.payerName;
      linkedPayers[indexOfSelectedPayer].state = trizettoInsuranceCompany.state;

      setLinkedPayers([...linkedPayers]);
      setLinkedPayersCache([...linkedPayers]);
      setSelectedInsurancePayer({ ...linkedPayers[indexOfSelectedPayer] });
    }
  };

  const handleLinkClick = async () => {
    const { payerId, payerName, claimsPhoneNumber, mailingAddress, payerUrl, appealViaPortal, appealURL, firstTimeBilled, dateOfDenial, eraSetup } = linkPayerVal;
    // const isValidated = !Object.values(linkPayerVal).includes("");
    // if (!isValidated) {
    //   NotificationManager.error("Payer name and payer id is required.");
    //   return;
    // }

    if (!payerId || !payerName || !claimsPhoneNumber || !mailingAddress || !appealViaPortal || (appealViaPortal == 'yes' && !appealURL) || !firstTimeBilled || !dateOfDenial || !eraSetup) {
      NotificationManager.error("Please enter the values.");
      return;
    }

    const isAlreadyLinked = (linkedPayers || []).find((payer) => {
      if (payer.linkedPayerId === payerId) {
        return payer;
      }
    });

    if (isAlreadyLinked) {
      setSelectedInsurancePayerId(isAlreadyLinked.id);
      setSelectedInsurancePayer({ ...isAlreadyLinked });
      NotificationManager.error(
        `${payerName} is already linked. You can relink it by selecting it from the table below.`
      );
      return;
    }

    await setSelectedInsurancePayer({
      ...selectedInsurancePayer,
      linkedPayer: payerName,
      linkedPayerId: payerId,
      claimPhoneNumber: claimsPhoneNumber,
      mailingAddress: mailingAddress,
      appealViaPortal: appealViaPortal,
      appealURL: appealViaPortal == 'yes' ? appealURL : "",
      payerURL: payerUrl,
      firstTimeBilled: firstTimeBilled,
      dateOfDenial: dateOfDenial,
      ERASetup: eraSetup
    });
    if (!params) await setSelectedInsurancePayerId();
    setIsNewLinking(true);
    await handleOpenLinkingModal();
  };

  const fetchLinkedPayers = async () => {
    LoadingSpinner.show();
    const linkedPayersList = await fetchAllLinkedPayers();
    await setSelectedInsurancePayer(undefined)
    await setSelectedInsurancePayerId(undefined)
    await setLinkedPayers(linkedPayersList);
    await setLinkedPayersCache(linkedPayersList);
    LoadingSpinner.hide();
  };

  const handleNewLinking = async (buttonType) => {
    if (!selectedInsurancePayer) {
      NotificationManager.error("No payer is selected");
      return;
    }
    LoadingSpinner.show();
    const res = await linkPayerWithInsurance(selectedInsurancePayer.id, {
      linkedPayerId: selectedInsurancePayer.linkedPayerId,
      linkedPayer: selectedInsurancePayer.linkedPayer,
      insuranceType: encounterType,
      encounterId: encounterId,
      payerName: selectedInsurancePayer && selectedInsurancePayer?.payerName ?
        selectedInsurancePayer?.payerName.trim() +
        " " +
        attachState(selectedInsurancePayer?.state && selectedInsurancePayer?.state) : '',
      buttonType: buttonType,
      claimPhoneNumber: linkPayerVal.claimsPhoneNumber,
      mailingAddress: linkPayerVal.mailingAddress,
      appealViaPortal: linkPayerVal.appealViaPortal,
      appealURL: linkPayerVal.appealViaPortal == 'yes' ? linkPayerVal.appealURL : "",
      payerURL: linkPayerVal.payerUrl,
      firstTimeBilled: linkPayerVal.firstTimeBilled,
      dateOfDenial: linkPayerVal.dateOfDenial,
      ERASetup: linkPayerVal.eraSetup
    });
    if (res) {
      setLinkPayerVal({
        payerName: "",
        payerId: "",
        claimsPhoneNumber: "",
        mailingAddress: "",
        payerUrl: "",
        appealViaPortal: "",
        appealURL: "",
        firstTimeBilled: "",
        dateOfDenial: "",
        eraSetup: ""
      });
      fetchLinkedPayers();
      NotificationManager.success("Linked successfully.");
      setShowLinkingModal(false);
    }
    LoadingSpinner.hide();
  };

  useEffect(() => {
    fetchLinkedPayers();
  }, []);

  useEffect(() => {
    if (params && params.payerId && params.payerName) {
      const id = (linkedPayers || []).find(
        (payer) => payer.linkedPayerId === params.payerId
      )?.linkedPayerId;
      // )?.id;
      // console.log(id, linkedPayers)
      if (id) {
        const linkedPayerByQuery = linkedPayers.find(
          (payer) => payer.linkedPayerId === params.payerId
        );
        setSelectedInsurancePayerId(id);
        setSelectedInsurancePayer({
          id,
          linkedPayerId: id,
          linkedPayer: params.payerName,
          payerName: linkedPayerByQuery.payerName,
          payerId: linkedPayerByQuery.payerId,
        });
        setIsNewLinking(false);
        setLinkPayerVal({ payerName: "", payerId: "" });
      } else {
        setIsNewLinking(true);
      }
    }
  }, [params, linkedPayers]);

  const renderFooter = () => (
    <LinkedInsuracePayerFooter
      setLinkedPayers={setLinkedPayers}
      linkedPayers={linkedPayersCache}
    />
  );

  // const renderPanelHeader = () => (
  //   <Box>
  //     <div className="row text-left">
  //       <div className="col-md-7">
  //         <TextInput
  //           label="Payer Name:"
  //           onChange={(e) => {
  //             setLinkPayerVal({
  //               ...linkPayerVal,
  //               payerName: e.target.value || "",
  //             });
  //           }}
  //           value={linkPayerVal.payerName}
  //           className="w-100"
  //         />
  //       </div>
  //       <div className="col-md-4">
  //         <TextInput
  //           type="text"
  //           label="Payer ID:"
  //           value={linkPayerVal.payerId}
  //           className="w-100"
  //           onChange={(e) => {
  //             setLinkPayerVal({
  //               ...linkPayerVal,
  //               payerId: e.target.value || "",
  //             });
  //           }}
  //         />
  //       </div>
  //       <div
  //         className="col mt-3 pr-0 pl-0 text-left "
  //         style={{ paddingLeft: "0px", paddingRight: "0px" }}
  //       >
  //         <MdHelp
  //           style={{
  //             fontSize: "35px",
  //             color: "#D8D8D8",
  //             marginRight: "1rem",
  //             marginLeft: "-5px",
  //           }}
  //         />
  //         <ROButton onClick={handleLinkClick} className="mt-2 mt-xxl-0 me-2 me-xxl-0">link</ROButton>
  //       </div>
  //     </div>
  //   </Box>
  // );

  const renderPanelHeader = () => (
    <div className="p-main-cont">
      <div className="p-cont-area">
        <Panel
          heading={"payer details".toUpperCase()}
          bodyStyle={{ padding: "10px 5px 10px 5px" , height: "calc(100vh - 233px)" , overflow: "auto" }}
          headStyle={{ height: "55px" }}>
            <div className="payDiv" style={{ padding: "10px"}}>
              <div className="payerdetail-form">
                <TextInput
                  label="Payer Name:"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      payerName: e.target.value || "",
                    });
                  }}
                  value={linkPayerVal.payerName}
                  className="w-100"
                />
                <div className="d-flex gap-3 align-items-end help-payer" >
                  <div className="w-100">
                  <TextInput
                    type="text"
                    label="Payer ID:"
                    value={linkPayerVal.payerId}
                    className=""
                    onChange={(e) => {
                      setLinkPayerVal({
                        ...linkPayerVal,
                        payerId: e.target.value || "",
                      });
                    }}
                  />
                  </div>
                  <MdHelp
                    style={{
                      fontSize: "32px",
                      color: "#D8D8D8",
                    }}
                  />
                </div>
                <TextInput
                  type="text"
                  label="Claims Phone Number:"
                  value={linkPayerVal.claimsPhoneNumber}
                  className="w-100"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      claimsPhoneNumber: e.target.value || "",
                    });
                    
                  }}
                />
                <TextInput
                  type="text"
                  label="Mailing Address:"
                  value={linkPayerVal.mailingAddress}
                  className="w-100"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      mailingAddress: e.target.value || "",
                    });
                  }}
                />
                <TextInput
                  type="text"
                  label="Obtain EOB URL:"
                  value={linkPayerVal.payerUrl}
                  className="w-100"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      payerUrl: e.target.value || "",
                    });
                  }}
                />
                <label htmlFor="appeal" className="textInputLabel font-face-futura">Able to Appeal via Portal:</label>
                <select className="w-100 denialSelect2"
                  value={linkPayerVal.appealViaPortal}
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      appealViaPortal: e.target.value || "",
                    });
                  }}>
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {linkPayerVal.appealViaPortal == 'yes' && <TextInput
                  type="text"
                  label="Appeal Online URL:"
                  value={linkPayerVal.appealURL}
                  className="w-100"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      appealURL: e.target.value || "",
                    });
                  }}
                />}

                <TextInput
                  type="text"
                  label="Timely Filing Limit in Days (First time billed):"
                  value={linkPayerVal.firstTimeBilled}
                  className="w-100"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      firstTimeBilled: e.target.value || "",
                    });
                  }}
                />
                <TextInput
                  type="text"
                  label="Timely Filing Limit in Days (Date of Denial):"
                  value={linkPayerVal.dateOfDenial}
                  className="w-100"
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      dateOfDenial: e.target.value || "",
                    });
                  }}
                />

                <label htmlFor="appeal" className="textInputLabel font-face-futura">ERA Set Up:</label>
                <select className="w-100 denialSelect2 font-face-futura"
                  value={linkPayerVal.eraSetup}
                  onChange={(e) => {
                    setLinkPayerVal({
                      ...linkPayerVal,
                      eraSetup: e.target.value || "",
                    });
                  }}>
                  <option value="">Select</option>
                  <option value="enrolled">Enrolled</option>
                  <option value="notYet">Not Yet Set Up</option>
                  <option value="inProgress">In Progress</option>
                  <option value="reEnroll">Need to Re-Enroll</option>
                  <option value="unable">Unable to Enroll</option>
                </select>
              </div>
              {/* <div className="mt-5 botDivider"></div> */}
              <div className="payerdetail-check">
                <TextInput
                  type="text"
                  label="Keyword Input for Insurance Eligibility Check:"
                  // value={linkPayerVal.payerId}
                  className="w-100"
                // onChange={(e) => {
                //   setLinkPayerVal({
                //     ...linkPayerVal,
                //     payerId: e.target.value || "",
                //   });
                // }}
                />
                <TextInput
                  type="text"
                  label="Enter Keywords to Violate Insurance Eligibility:"
                  // value={linkPayerVal.payerId}
                  className="w-100"
                // onChange={(e) => {
                //   setLinkPayerVal({
                //     ...linkPayerVal,
                //     payerId: e.target.value || "",
                //   });
                // }}
                />
              </div>
              <div className="d-flex">
                {showTrizettoModal ?
                  <>
                    <Button variant="contained"
                      className="never-btn mx-1"
                      onClick={() => setShowTrizettoModal(false)}
                      fontSize={20}
                    >
                      <span className="font-face-futura">NEVERMIND</span>
                    </Button>
                    <Button variant="contained"
                      fontSize={20}
                      className="link-btn mx-1"
                      onClick={() => {
                        if (selectedInsurancePayer.payerId === 'ELCMS') {
                          console.log(selectedInsurancePayer.payerId)
                          setShowTicketModal(true);
                        } else {
                          setShowLinkingConfirmation(true);
                        }
                      }}
                    >
                      <span className="font-face-futura">LINK</span>
                    </Button>
                  </> : isRowChecked ?
                    <div>
                      <ROButton
                        disabled={!selectedInsurancePayerId}
                        type="info"
                        className="mx-1"
                        onClick={() => setShowDeleteConfirmation(true)}
                      >
                        Delete
                      </ROButton>
                      <ROButton
                        disabled={!selectedInsurancePayerId}
                        type="primary"
                        className="mx-1"
                        onClick={async () => {
                          await setIsNewLinking(false);
                          handleOpenTrizettoModal();
                        }}
                      >
                        Relink
                      </ROButton>
                      <ROButton
                        disabled={!selectedInsurancePayerId}
                        type="info-light"
                        className="mx-1"
                        onClick={() => setShowEditConfirmation(true)}
                      >
                        Save
                      </ROButton>
                    </div> :
                    <Button variant="contained" onClick={handleLinkClick} className="payer-btn">
                      <span className="font-face-futura">ADD PAYER</span>
                    </Button>
                }
                {/* <ROButton onClick={handleLinkClick} className="mt-xxl-0 me-2 me-xxl-0">link</ROButton> */}
              </div>
            </div>
        </Panel>
      </div>
    </div>
  );

  // const renderTrizettoPayerListModal = () => (
  //   <ROModal
  //     show={showTrizettoModal}
  //     handleClose={handleCloseTrizettoModal}
  //     title="Trizetto Payer List"
  //     style={{ top: "42%" }}
  //   >
  //     <TrizettoPayerList
  //       handleClose={handleCloseTrizettoModal}
  //       selectedInsurancePayer={selectedInsurancePayer}
  //       handleAfterRelinking={handleAfterRelinking}
  //       isNewLinking={isNewLinking}
  //       encounter={{ encounterId, encounterType }}
  //       refreshLinkedPayers={() => {
  //         setLinkPayerVal({
  //           payerName: "",
  //           payerId: "",
  //         });
  //         fetchLinkedPayers();
  //       }}
  //     />
  //   </ROModal>
  // );

  let practiceData = JSON.parse(sessionStorage.getItem("practice"));

  const renderLinkingModal = () => (
    <RoAlert
      show={showLinkingModal}
      showLinking={showLinkingModal}
      handleClose={() => handleNewLinking("add only")}
      handleSubmit={() => {
        setShowTrizettoModal(true)
        setShowLinkingModal(false)
      }}
      // title="you are linking this payer"
      title="want to link payer?"
      type="info"
      content={
        <>
          <div className="alert-footer-text text-center mt-5 mb-4">
            Do you want to link this insurance to the practice:<br />
            <span style={{ color: '#0E0E2C' }}>{practiceData?.practiceName}</span><br />
          </div>
          <div className="alert-footer-text text-center">This will enable the payer to check eligibility</div>
        </>
      }
    />
  );

  const handleChangePractice = (practice) => { fetchLinkedPayers(); }

  return (
    <section>
      <Sidebar handleChangePractice={handleChangePractice} />
      <div className="linkedsection-layout">
      <div className="linkedsection-grid">
        <div className="linkedsection-table">
          <Main>
            {/* <Content className={"py-2 pr-5"} style={{ paddingRight: "18px" }}> */}
            <Content>
              <Panel
                heading={"linked payers".toUpperCase()}
                bodyStyle={{ padding: "0px", paddingBottom: "0px" }}
                headStyle={{ height: "55px" , marginBottom: "10px" }}
              >
                {showTrizettoModal ?
                  <TrizettoPayerList
                    handleClose={handleCloseTrizettoModal}
                    selectedInsurancePayer={selectedInsurancePayer}
                    handleAfterRelinking={handleAfterRelinking}
                    isNewLinking={isNewLinking}
                    linkingConfirmation={showLinkingConfirmation}
                    handleLinkingConfirmation={handleLinkingConfirmation}
                    ticketModal={showTicketModal}
                    handleTicketModal={handleTicketModal}
                    encounter={{ encounterId, encounterType }}
                    refreshLinkedPayers={() => {
                      setLinkPayerVal({
                        payerName: "",
                        payerId: "",
                        claimsPhoneNumber: "",
                        mailingAddress: "",
                        payerUrl: "",
                        appealViaPortal: "",
                        appealURL: "",
                        firstTimeBilled: "",
                        dateOfDenial: "",
                        eraSetup: ""
                      });
                      fetchLinkedPayers();
                      setIsRowChecked(false)
                    }}
                  /> :
                  <LinkedPayerTable
                    linkedPayersList={linkedPayers}
                    selectedInsurancePayerId={selectedInsurancePayerId}
                    handleSelectInsurancePayer={handleSelectInsurancePayer}
                    handleRelink={async () => {
                      await setIsNewLinking(false);
                      handleOpenTrizettoModal();
                    }}
                    selectedInsurancePayer={selectedInsurancePayer}
                    handleAfterUpdateLinkedPayer={handleAfterUpdateLinkedPayer}
                    handleAfterDeleteLinkedPayer={handleAfterDeleteLinkedPayer}
                  />
                }
              </Panel>
            </Content>
          </Main>
        </div>
        <div className="linkedsection-form">
          {renderPanelHeader()}
        </div>
      </div>

      </div>

      <RoAlert
        show={showDeleteConfirmation}
        handleClose={() => setShowDeleteConfirmation(false)}
        handleSubmit={handleDeletePayer}
        title="you are deleting this payer"
        type="danger"
        content={
          <div className="alert-footer-text text-center mb-3">
            You are about to delete the linked payer for the practice:<br />
            <span style={{ color: '#0E0E2C' }}>{practiceData?.practiceName}</span>
          </div>
        }
      />
      <RoAlert
        show={showEditConfirmation}
        handleClose={() => setShowEditConfirmation(false)}
        handleSubmit={handleSaveClick}
        title="you are editing this payer"
        type="info"
        content={
          <div className="alert-footer-text text-center mb-3">
            You are about to edit the linked payer for the practice:<br />
            <span style={{ color: '#0E0E2C' }}>{practiceData?.practiceName}</span>
          </div>
        }
      />
      {renderLinkingModal()}
      {renderFooter()}
      {/* {renderTrizettoPayerListModal()} */}
    </section>
  );
};

export default Insurances;
