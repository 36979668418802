import { Datatable } from "layouts/Datatable";
import { drawDatatableCell } from "layouts/Datatable/helper";
import { TextInput } from "layouts/Form/TextInput";
import { RoCheckbox } from "layouts/Form/Checkbox";
import { useEffect, useState } from "react";
import { LinkedPayersTableHead, LinkedPayerStatus } from "../constants";
import { ROButton } from "layouts/RoButton";
import { trizettoColStyles } from "./constants";
import { deleteLinkedPayer, updateLinkedPayer } from "../helper";
import { NotificationManager } from "react-notifications";
import { RoAlert } from "layouts/RoAlert";
import { LoadingSpinner } from "lib/loader";
import {
  sortInAscending,
  sortInDescending,
} from "layouts/Datatable/DatatableHead/helper";
import {
  mapCurrentSortedStateToNextState,
  SortedState,
} from "layouts/Datatable/DatatableHead/constants";
import { Tooltip } from "@material-ui/core";

export const LinkedPayerTable = (props) => {
  const {
    linkedPayersList,
    selectedInsurancePayerId,
    handleSelectInsurancePayer,
    handleRelink,
    selectedInsurancePayer,
    handleAfterUpdateLinkedPayer,
    // handleAfterDeleteLinkedPayer,
  } = props;

  const [linkedPayersRecords, setLinkedPayersRecords] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedLinkedPayer, setSelectedLinkedPayer] = useState(
    selectedInsurancePayer
  );
  // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [tableHead, setTableHead] = useState(LinkedPayersTableHead);
  const [sortedConfig, setSortedConfig] = useState({
    colIndex: 3,
    sortedState: SortedState.ASC,
  });

  let practiceData = JSON.parse(sessionStorage.getItem("practice"));

  const handleInputChange = (e, prop) => {
    if (!selectedLinkedPayer) return;
    const val = e.target.value || selectedLinkedPayer[prop];
    setSelectedLinkedPayer({ ...selectedLinkedPayer, [prop]: val });
  };

  const handleSaveClick = async () => {
    if (!selectedLinkedPayer.payerName || !selectedLinkedPayer.linkedPayerId) {
      NotificationManager.error("Invalid form values");
      return;
    }
    const res = await updateLinkedPayer(selectedLinkedPayer);
    if (res) {
      handleAfterUpdateLinkedPayer(selectedLinkedPayer);
      NotificationManager.success("Updated successfully.");
      setIsEditMode(false);
      setShowEditConfirmation(false);
    }
  };

  // const handleDeletePayer = async () => {
  //   const { linkedPayerId } = selectedLinkedPayer;
  //   if (!linkedPayerId) return;
  //   LoadingSpinner.show();
  //   const res = await deleteLinkedPayer(linkedPayerId);
  //   if (res) {
  //     NotificationManager.success("Deleted successfully.");
  //     handleAfterDeleteLinkedPayer(selectedLinkedPayer);
  //     setSelectedLinkedPayer(undefined);
  //     setShowDeleteConfirmation(false);
  //   }
  //   LoadingSpinner.hide();
  // };

  const renderSateCol = (states) => {
    if (states === "ALL") return "All States";
    else {
      const stateArr = states?.split(",");
      if (stateArr.length < 1) return states;
      else {
        return (
          <Tooltip title={<h6>{states}</h6>}>
            <span className="state-text">{states}</span>
          </Tooltip>
        );
      }
    }
  };

  const renderEditableRow = (config) => {
    const { payer, prop, jsx, customClassName } = config;
    // if (payer.id === selectedInsurancePayerId && isEditMode) {
    if (payer.linkedPayerId === selectedInsurancePayerId && isEditMode) {
      return (
        <TextInput
          defaultValue={payer[prop]}
          className={"text-center " + customClassName}
          onChange={(e) => {
            handleInputChange(e, prop);
          }}
        />
      );
    }
    return jsx;
  };

  const renderStatusColumn = (linkedPayer, isActiveColumn) => {
    // if (!isActiveColumn) return linkedPayer.payerId === 'ELCMS' ? linkedPayer.statusType : linkedPayer.status;
    if (!isActiveColumn) return linkedPayer.statusType &&
      linkedPayer.statusType === 'active' || linkedPayer.statusType === '' ? 'Approved' :
      linkedPayer.statusType === 'rejected' ? 'Rejected' :
        linkedPayer.statusType === 'pending' ? 'Pending' :
          linkedPayer.statusType === 'bypassed' ? 'Bypassed' :
            linkedPayer.statusType === 'inactive' && 'Not Linked';
    return (
      <ROButton type="success" onClick={handleRelink}>
        Re-link
      </ROButton>
    );
  };

  const restructureRecords = () => {
    const records = (linkedPayersList || []).map((linkedPayer) => {
      return [
        drawDatatableCell(
          "",
          <RoCheckbox
            onChange={(e) => handleSelectInsurancePayer(e, linkedPayer)}
            checked={selectedInsurancePayerId === linkedPayer.linkedPayerId}
            // checked={selectedInsurancePayerId === linkedPayer.id}
            style={{ marginTop: "-18px" }}
          />,
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
        drawDatatableCell(
          linkedPayer.linkedPayer,
          renderEditableRow({
            payer: linkedPayer,
            prop: "linkedPayer",
            jsx: linkedPayer.linkedPayer,
          }),
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
        drawDatatableCell(
          Number(linkedPayer.linkedPayerId),
          linkedPayer.linkedPayerId,
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
        drawDatatableCell(
          linkedPayer.payerName,
          linkedPayer.payerName ? linkedPayer.payerName : 'N/A',
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
        drawDatatableCell(
          linkedPayer.payerId,
          linkedPayer.payerId ? linkedPayer.payerId : 'N/A',
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
        drawDatatableCell(
          linkedPayer.state,
          renderSateCol(linkedPayer.state ? linkedPayer.state : 'N/A'),
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
        drawDatatableCell(
          linkedPayer.status,
          linkedPayer.statusType === 'active' || linkedPayer.statusType === '' ? 'Approved' :
            linkedPayer.statusType === 'rejected' ? 'Rejected' :
              linkedPayer.statusType === 'pending' ? 'Pending' :
                linkedPayer.statusType === 'bypassed' ? 'Bypassed' :
                  linkedPayer.statusType === 'inactive' && 'Not Linked',
          // renderStatusColumn(
          //   linkedPayer,
          //   selectedInsurancePayerId === linkedPayer.linkedPayerId
          //   // selectedInsurancePayerId === linkedPayer.id
          // ),
          null,
          selectedInsurancePayerId === linkedPayer.linkedPayerId
          // selectedInsurancePayerId === linkedPayer.id
        ),
      ];
    });

    const sortedRec = handleIfSorting(records);
    setLinkedPayersRecords([...sortedRec]);
  };

  const handleIfSorting = (records) => {
    if (!sortedConfig.colIndex || !sortedConfig.sortedState) return records;
    if (!sortedConfig.sortedState === SortedState.NO_SORT) return records;

    let sortedRecords = [];
    switch (sortedConfig.sortedState) {
      case SortedState.ASC:
        sortedRecords = sortInAscending(records, sortedConfig.colIndex);
        return sortedRecords;
      case SortedState.DESC:
        sortedRecords = sortInDescending(records, sortedConfig.colIndex);
        return sortedRecords;
      default:
        return records;
    }
  };

  const handleCustomSorting = async (colIndex) => {
    const activeCol = tableHead[colIndex];
    const nextSortedState =
      mapCurrentSortedStateToNextState[activeCol.sortedState];
    tableHead.forEach((head) => (head.sortedState = SortedState.NO_SORT));
    tableHead[colIndex].sortedState = nextSortedState;
    await setTableHead(tableHead);
    await setSortedConfig({ sortedState: nextSortedState, colIndex: colIndex });
  };

  useEffect(() => {
    restructureRecords();
  }, [linkedPayersList, isEditMode, selectedInsurancePayerId, sortedConfig]);

  useEffect(() => {
    if (!selectedInsurancePayerId) setIsEditMode(false);
  }, [selectedInsurancePayerId]);

  useEffect(() => {
    setSelectedLinkedPayer(selectedInsurancePayer);
  }, [selectedInsurancePayer]);

  const renderTableFooter = () => (
    <div className="d-flex border-top py-1" style={{ background: "#F2F2F2" }}>
      {/* <div>
        <ROButton
          disabled={!selectedInsurancePayerId}
          type="info"
          className="mx-1"
          onClick={() => setShowDeleteConfirmation(true)}
        >
          Delete
        </ROButton>
      </div>
      {!isEditMode && (
        <div>
          <ROButton
            disabled={!selectedInsurancePayerId}
            type="info-light"
            className="mx-1"
            onClick={() => setIsEditMode(true)}
          >
            Edit
          </ROButton>
        </div>
      )}
      {isEditMode && (
        <div>
          <ROButton
            disabled={!selectedInsurancePayerId}
            type="info-light"
            className="mx-1"
            onClick={() => setShowEditConfirmation(true)}
          >
            Save
          </ROButton>
        </div>
      )} */}
      <span className="text-futura mx-3 text-left">
        <div className="text-left" style={{ textAlign: 'left' }}>
          <b>Total Linked Payers:</b> {linkedPayersList?.length || 0}
          <br />
          <b>Approved:</b>{" "}
          {
            (linkedPayersList || []).filter(
              (payer) => payer.status === LinkedPayerStatus.APPROVED
            ).length
          }{" "}
          <br />
          <b>Rejected:</b>{" "}
          {
            (linkedPayersList || []).filter(
              (payer) => payer.status === LinkedPayerStatus.DISAPPROVED
            ).length
          }
        </div>
      </span>
      <div className="mx-1"></div>
    </div>
  );

  return (
    <>

      <div className="insurances-table">
        <Datatable
          head={tableHead}
          records={linkedPayersRecords || []}
          footer={renderTableFooter()}
          bodyStyle={{ height: "calc(100vh - 351px)", paddingLeft: "10px" }}
          headerStyle={{ height: "40px" }}
          columnStyles={trizettoColStyles}
          onSort={handleCustomSorting}
        />
      </div>
      {/* {isEditMode ? */}
      {isEditMode &&
        <RoAlert
          show={showEditConfirmation}
          handleClose={() => setShowEditConfirmation(false)}
          handleSubmit={handleSaveClick}
          title="you are editing this payer"
          type="info"
          content={
            <div className="alert-footer-text text-center mb-3">
              You are about to edit the linked payer for the practice:<br />
              <span style={{ color: '#0E0E2C' }}>{practiceData?.practiceName}</span>
            </div>
          }
        />
        // :
        // <RoAlert
        //   show={showDeleteConfirmation}
        //   handleClose={() => setShowDeleteConfirmation(false)}
        //   handleSubmit={handleDeletePayer}
        //   title="you are deleting this payer"
        //   type="danger"
        //   content={
        //     <div className="alert-footer-text text-center mb-3">
        //       You are about to delete the linked payer for the practice:<br />
        //       <span style={{ color: '#0E0E2C' }}>{practiceData?.practiceName}</span>
        //     </div>
        //   }
        // />
      }
    </>
  );
};
