import React from "react";
import "./login.css";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import logo from "../../assets/icons/logo.svg";
import { useState } from "react";
import API from "Api";
import { NotificationManager } from "react-notifications";


const ForgotPassword = (props) => {
  const [passwordInfo, setPasswordInfo] = useState({
    mailCode: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [isChangePassword, setIsChangePassword] = useState(false);
  const [isAccRegistration, setIsAccRegistration] = useState(true);
  const [userName, setUserName] = useState(props.location.state?.first_name);

  
  /**
   * function to handle chnage in input fields
   * @param {*} e 
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    errors[name] = "";
    setPasswordInfo({ ...passwordInfo, [name]: value });
    setErrors(errors);
  };

  /**
   * function is use for validation in OTP
   */
  const isValidOtp = () => {
    const validation = {};
    let valid = true;
    if (!passwordInfo.mailCode) {
      valid = false;
      validation.mailCode = "*Please enter the code that was emailed to you";
    }
    setErrors(validation);
    return valid;
  };

  /**
   * Function is used for validation in set new password
   */
  const isValidPassword = () => {
    const validPassword = {};
    let valid = true;
    let validatePassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
    if(!passwordInfo.password) {
      valid = false;
      validPassword.password = "*Please enter the new password"
    } else if(passwordInfo.password.length < 8 || passwordInfo.password.length > 20){
      valid = false;
      validPassword.password = "*Password must be in between 8 to 20 characters"
    } else if(!passwordInfo.password.match(validatePassword)){
      valid =  false;
      validPassword.password = "*Password must contain atleast one uppercase, one lowercase, one number and a special character"
    } else if(!passwordInfo.confirmPassword){
      valid = false;
      validPassword.confirmPassword = "*Please enter the confirm password"
    } else if(passwordInfo.password !== passwordInfo.confirmPassword){
      valid = false;
      validPassword.confirmPassword = "*Password does not matched"
    }
    setErrors(validPassword);
    return valid;
  }

  /**
   * function to handle chnage password button after fill successful OTP
   */
  const onChangePassword = () => {
    if (isValidOtp()) {
      let body = {
        email: props.location.state?.email,
        key: props.location.state?.client_key,
        OTP: passwordInfo.mailCode
      }
      API.post("otpVerify", body).then((res)=>{
        if(res.data.status === 200){
          NotificationManager.success("OTP verified successfully", "Successfully!", 3000) 
          setIsAccRegistration(false);
          setIsChangePassword(true);
        } else {
          NotificationManager.error("Incorrect OTP", "Failed", 3001)
        }
      })
    }
  };

  /**
   * Function is used to resend the OTP for varification
   */
  const onResendMail = () => {
    let body = {
      email: props.location.state?.email,
      key: props.location.state?.client_key,
    }
    API.post("forgotPassword", body).then((res)=>{
      if(res.data.status === 200){
        NotificationManager.success("OTP send successfully on registered mail id", "Successfully!", 3000)
      } else {
        NotificationManager.error("Something went wrong", "Failed", 3001)
      }
    }).catch((err)=>{
      return err
    })
  };

  /**
   * function is used for set the new password and navigate to login Page.
   */
  const onLogin = () => {
    if(isValidPassword()){
      let body = {
        email: props.location.state?.email,
        key: props.location.state?.client_key,
        password: passwordInfo.password,
        confirmPassword: passwordInfo.confirmPassword
      }
      API.post("setPassword", body).then((res)=>{
        if(res.data.status === 200){
          NotificationManager.success(`${res.data.message}`, "Successfully!", 3000)
          props.history.push("/")
        } else {
          NotificationManager.error("something went wrong!", "Failed", 3001)
        }
      }).catch((err)=>{
        return err
      })
    }
  };

  const _handleOnChangePassword = (e) => {
      if (e.key === 'Enter') {
          onChangePassword();
      }
  }
  const _handleOnLogin = (e) => {
      if (e.key === 'Enter') {
          onLogin();
      }
  }


  const style = {
    textAlign: "left",
    height: 0,
    lineHeight: "16px",
  };


  return (
    <section className="page-backGround">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card variant="outlined" className="loginCard h100" onKeyDown={isAccRegistration ? _handleOnChangePassword : _handleOnLogin}>
          <CardContent>
            <div className="row customize-dflex">
              <div className="col-6 col-sm-12 col-lg-6 col-md-12 d-flex align-items-center">
                <img src={logo} alt="logo" width={420} height={155} />
              </div>
              {isAccRegistration && (
                <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                  <CardHeader
                    title={
                      <span className="font-face-poppins pageHeadings">
                        Let’s Verify Your Account
                      </span>
                    }
                  />
                  <div>
                    <span className="">Welcome {userName}!</span>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="mailCode">
                      What is the code that was emailed to you?
                    </label>
                    <input
                      className="inputFields"
                      name="mailCode"
                      type="number"
                      value={passwordInfo.mailCode}
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="text-danger" style={style}>
                      {errors.mailCode}
                    </p>
                  </div>

                  <div className="mt-3">
                    <button className="changePswrd" onClick={onChangePassword}>
                      <span>Change Password</span>
                    </button>
                  </div>

                  <div className="termsCondition mt-2">
                    <p>
                      By logging in you agree to the
                      <a
                        href="/"
                        style={{ textDecoration: "none", color: "#81C3D7" }}
                      >
                        {" "}
                        terms{" "}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/"
                        style={{ textDecoration: "none", color: "#81C3D7" }}
                      >
                        conditions
                      </a>
                    </p>
                  </div>
                  <div className="mt-5 pb-2">
                    <span>Didn’t receive it?</span>
                  </div>
                  <div className="mt-2">
                    <button
                      className="resendPswrd"
                      onClick={() => onResendMail()}
                    >
                      <span>Resend Code</span>
                    </button>
                  </div>
                </div>
              )}
              {isChangePassword && (
                <div className="col-6 col-sm-12 col-lg-6 col-md-12 font-face-poppins text-align">
                  <CardHeader
                    title={
                      <span className="font-face-poppins pageHeadings">
                        Account Registration
                      </span>
                    }
                  />
                  <div>
                    <span className="">Welcome {userName}!</span>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="password">Password:</label>
                    <input
                      type="password"
                      name="password"
                      value={passwordInfo.password}
                      className="inputFields"
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="text-danger" style={style}>
                      {errors.password}
                    </p>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="confirmPassword">
                      Password Confirmation:
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={passwordInfo.confirmPassword}
                      className="inputFields"
                      onChange={(e) => handleChange(e)}
                    />
                    <p className="text-danger" style={style}>
                      {errors.confirmPassword}
                    </p>
                  </div>
                  <div className="mt-2">
                    <button className="changePswrd" onClick={onLogin}>
                      <span>Log In</span>
                    </button>
                  </div>
                  <div className="termsCondition mt-2 pb-3">
                    <p>
                      By logging in you agree to the
                      <a
                        href="/"
                        style={{ textDecoration: "none", color: "#81C3D7" }}
                      >
                        {" "}
                        terms{" "}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/"
                        style={{ textDecoration: "none", color: "#81C3D7" }}
                      >
                        conditions
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </section>
  );
};
export default ForgotPassword;
