export const searchItems = [
    {key: 'dateOfService', value: 'Date of Service', data: 'ServiceStartDate'}, 
    {key: 'DXC1', value: 'Diagnosis 1', data: 'EncounterDiagnosisID1'}, 
    {key: 'encounter', value: 'Encounter #', data: 'EncounterID'}, 
    {key: 'primaryInsurance', value: 'Primary Insurance', data: 'PrimaryInsurancePlanName'},
    {key: 'patientName', value: 'Patient Name', data: 'PatientName'}, 
    {key: 'facility', value: 'Place of Service', data: 'ServiceLocationName'}, 
    {key: 'procedureCode', value: 'Procedure Code', data: 'ProcedureCode'},  
    {key: 'schedulingProvider', value: 'Provider', data: 'SchedulingProviderName'},
    // {key: 'secondaryInsurance', value: 'Secondary Insurance'}, 
];

export const denialSearchItems = [
    {key: 'primaryInsurance', value: 'Billed To', data: 'Billed_to'},
    {key: 'dateOfService', value: 'Date of Service', data: 'ServiceStartDate'}, 
    {key: 'DXC1', value: 'Diagnosis 1', data: 'DXC1'}, 
    {key: 'dueDate', value: 'Due Date', data: 'DueDate'}, 
    {key: 'encounter', value: 'Encounter #', data: 'EncounterID'}, 
    {key: 'patientName', value: 'Patient Name', data: 'PatientName'}, 
    {key: 'facility', value: 'Place of Service', data: 'ServiceLocationName'},
    {key: 'procedureCode', value: 'Procedure Code', data: 'Code'},
    {key: 'schedulingProvider', value: 'Provider', data: 'SchedulingProviderName'},
    {key: 'assignedTo', value: 'Assigned To', data: 'AssignedTo'},
    {key: 'whatsNeeded', value: 'What needed', data: 'WhatsNeeded'},
    {key: 'redenied', value: 'Redenied', data: 'Redenied'},
    {key: 'remark', value: 'Remark', data: 'Remark'}
];

export const claimsDropdown = [
    {key: 'notRan', value: 'Claim Center - Not Ran'},
    {key: 'ranByDay', value: 'Claim Center - Ran by Day'},
    {key: 'claimsNotReviewed', value: 'Claims Not Reviewed'},
    {key: 'claimsReviewed', value: 'Claims Reviewed'}
];
  
export const searchValues = [
    {key: 'ruleName', value: 'Search by rule name'}, 
    {key: 'tags', value: 'Search by tag'}, 
    {key: 'modified', value: 'Search by modified'},
    {key: 'status', value: 'Search by status'}
];

export const denailFilterDropdown = [
    {key: 'workList', value: 'Work List'},
    {key: 'pendingWorkedValidation', value: 'Pending Worked Validation'},
    {key: 'completed', value: 'Completed'}
]