import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Chip } from '@material-ui/core';
import './createRule.css';
import PropTypes from 'prop-types';

export default function MultipleSelect({ separateBy, options, onChange, categoryType, onTagsChange, placeholder, indexs, editRule,
  ruleValues, tagValues, onFocus, procedureValues, value, gender, handleGender }) {
  let defaultValues = [];
  ruleValues?.map((val) => {
    if (true) {
      defaultValues.push(val);
    }
    return val;
  })

  let defaultProcedureValues = [];
  if (procedureValues?.length) {
    procedureValues?.map((val) => {
      if (true) {
        defaultProcedureValues.push(val);
      }
      return val;
    })
  }

  return (
    <div className='row mb-2 rule-builder-select'>
      <div className={categoryType === 'Diagnosis 1' ||
        categoryType === 'Diagnosis 2' ||
        categoryType === 'Diagnosis 3' ||
        categoryType === 'Diagnosis 4' ||
        categoryType === 'Diagnosis in any slot' || categoryType === 'Modifier' ? 'col-lg-10 offset-md-1' : 'col-lg-10 offset-md-1'}>
        <div className='mt-2'>
          {categoryType === 'Gender' ?
            <select className='selectStyle custom-select font-face-futura text-center' value={gender} name="gender" onChange={handleGender}>
              <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select> :
            <Autocomplete
              multiple
              disableCloseOnSelect
              noOptionsText={placeholder}
              options={options}
              // getOptionLabel={(option) => `${option.id ? option.name : option}`}
              // defaultValue={editRule ? defaultValues : []}
              defaultValue={editRule ? procedureValues?.length ? defaultProcedureValues : defaultValues : []}
              getOptionLabel={(option) => `${option.name} ${option.type === "provider" ? `(${option.providerId})` : option.type === "insurance" ? `(${option.id})` : ''}`}
              onChange={(event, value) => { onTagsChange(event, value, indexs) }}
              // value={value}
              renderTags={(value, getTagProps) => {
                // console.log(value, " ---------------> render value")
                // if(value.length === 0) {
                //   return []
                // }
                // return 
                // editRule ? defaultValues.map((f, index) => {
                //   let code2 = f;
                //   return(
                //     <>
                //     {index !== 0 && <span className='font-face-futura' style={{marginBottom: 6, fontSize: 18}}>&nbsp; {separateBy} &nbsp;</span>}
                //       <Chip
                //       label={code2} {...getTagProps({ index })}
                //       className='font-face-futura' 
                //       style={{fontSize: 18, borderRadius: '10px', padding: 4}} /></>
                // )
                // }) :
                // console.log('value inside multiselect', value)
                return (editRule || (categoryType === 'Global Period') ? value : tagValues)?.map((option, index) => {
                  // console.log(option)
                  // let code = option.split('-')[0].trim();
                  let code = (option.type &&
                    (categoryType === 'Diagnosis 1' ||
                      categoryType === 'Diagnosis 2' ||
                      categoryType === 'Diagnosis 3' ||
                      categoryType === 'Diagnosis 4' ||
                      categoryType === 'Diagnosis in any slot' ||
                      categoryType === 'Procedure Code' || categoryType === 'Global Period')) ? option.name.split('-')[0].trim() : option.name ? option.name : option;

                  // console.log(code)
                  // let code = option.name ? option.name.split('-')[0].trim() : option;
                  // let code = option.name ? option.name.split('-')[0].trim() : option.split('-')[0].trim();
                  return (
                    <>
                      {index !== 0 && <span className='font-face-futura' style={{ marginBottom: 6, fontSize: 18 }}>&nbsp; {separateBy} &nbsp;</span>}
                      <Chip key={index}
                        label={code} {...getTagProps({ index })}
                        className='font-face-futura'
                        style={{ fontSize: 18, borderRadius: '10px', padding: 4 }} /></>
                  )
                })
              }
              }
              renderInput={(params) => (
                <TextField {...params}
                  className={categoryType === 'Diagnosis 1' ||
                    categoryType === 'Diagnosis 2' ||
                    categoryType === 'Diagnosis 3' ||
                    categoryType === 'Diagnosis 4' ||
                    categoryType === 'Diagnosis in any slot' || categoryType === 'Modifier' ? "text-wrap font-face-futura dropd2 w-100 bgWhite" : "text-wrap font-face-futura dropd w-100 bgWhite"}
                  label=""
                  style={{ borderRadius: '20px', marginRight: '0px', border: 'none' }}
                  onChange={onChange} onFocus={onFocus} />
              )}
            />}
        </div>
      </div>
    </div>

  );
}

MultipleSelect.propTypes = {
  separateBy: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  categoryType: PropTypes.string.isRequired
}

MultipleSelect.defaultProps = {
  options: []
}