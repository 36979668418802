import React, { useEffect, useState } from 'react';
import { Button, FormControl, IconButton } from '@material-ui/core';
import { useHistory } from "react-router";
// import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListIcon from '@material-ui/icons/FilterList';
// import moment from 'moment';
import { searchItems, searchValues, claimsDropdown, denialSearchItems, denailFilterDropdown } from './data';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../assets/icons/foot-calendar.svg';
import MaskedInput from 'react-text-mask';
import './footer.css';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { RoCheckbox } from 'layouts/Form/Checkbox';
import { OutsideAlerter } from '../../global/helpers/index';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');

const styles = {
  background: 'linear-gradient(0deg, #24476A 31.25%, #8EAFC2 299.25%)',
  height: 70,
  width: '100%',
  position: 'fixed',
  bottom: 0
}

// const inputStyles = {
//   marginTop: 16, 
//   float: 'left', 
//   height: 28, 
//   width: 270,
//   color: 'black',
//   borderRadius: '10px'
// }

const inputStyle = {
  marginTop: 13,
  marginLeft: 13,
  float: 'left',
  height: 30,
  width: 270,
  borderRadius: '10px'
}

const Footer = (props) => {

  let path = window.location.pathname;

  const onDateChange = (event) => {
    const e = {
      target: {
        value: 'ranByDay'
      }
    }
    props.handleClaimsDropdown(e)
    props.onDateChange(event)
  }

  const handleClaimsDropdown = async (e) => {
    await props.handleClaimsDropdown(e)
  }

  // console.log(props.showSearch, props.showCheckedValues)

  // logic to change date format
  // let x = document.querySelector('#inputDate');
  // let formattedDate;
  // if(x){
  //   x.addEventListener('change', function() {
  //     formattedDate = moment(this.value, "YYYY-MM-DD").format(this.getAttribute('data-date-format'));
  //     this.setAttribute('data-date',
  //       moment(this.value, "YYYY-MM-DD").format(this.getAttribute('data-date-format')))
  //       // console.log(this.value, formattedDate);
  //   })
  //   x.dispatchEvent(new Event("change"));
  // }
  // console.log(props?.checkboxValues.length)
  return (
    <div style={styles}>
      {(path === '/rule-processor') && (
        <div className="row">
          <div className="col-6 pt-2">
            <div className="form-inline">
              <div className='footer-left-area'>
                <div className='t-left'>
                  {/* <select 
                className={props.optionSelector === true ? 'sticky-selection2 show-selector' : 'sticky-selection2 none-selector'} 
                onChange={props.onSelectChange} multiple>
                  {searchItems.map((item, key) => (<>
                    <option className='p-1 option text-center font-face-futura-footer' key={key} value={item.key}>{item.value}</option>
                    </>))}
                </select> */}
                  <div className={props.optionSelector === true ? 'sticky-selection2 show-selector' : 'sticky-selection2 none-selector'}>
                    <div className='row filter-row'>
                      <div className='col-12 col-sm-12 col-lg-12 filter-txt'>
                        <span className='mx-1 text-light'>Select Filter</span>
                      </div>
                      {/* <div className='col-6 col-sm-6 col-lg-6 text-end filter-txt'>
                        <span className='mx-1'>Clear Filter</span>
                      </div> */}
                    </div>
                    <select
                      className='sticky-sel font-face-futura'
                      value={props.selectValue}
                      onChange={props.onSelectChange}>
                      <option value="" disabled selected>Select</option>
                      {searchItems.map((item, key) => (
                        <>
                          <option style={{ fontSize: 14 }} key={key} value={item.key} id={item.value}>{item.value}</option>
                        </>))}
                    </select>
                    <div className={props.showSearch || props.showCheckedValues ? 'mt-2 fil-box2' : 'mt-2 fil-box'}>
                      {props.showSearch ?
                        <>
                          <input
                            className='m-2 fil-input font-face-futura'
                            placeholder='Type something to search...'
                            value={props.filterValue}
                            onChange={(e) => props.searchFilterValues(e.target.value)} />
                          <div className={props.columnData.length > 0 ? 'fil-drop2' : 'fil-drop'}>
                            {props.columnData.length > 0 && props.columnData.map((col, idx) => (
                              <div key={idx} className="fil-opt">
                                <RoCheckbox
                                  style={{ width: 18, height: 18 }}
                                  value={col}
                                  checked={props.checkboxValues.includes(col)}
                                  onChange={props.handleCheckbox} />
                                <span className='fil-txt font-face-futura'>{col}</span>
                              </div>
                            ))}
                          </div>
                        </> : props.showCheckedValues ?
                          <>
                            <div className='mt-2 mx-2 font-face-futura fil-drop2'>
                              {/* {props.filteredValues.length > 0 && props.filteredValues.map((val, idx) => (
                                <>
                                  <h6 className='text-capitalize'>{val.title}</h6>
                                  <div className="bottom"></div>
                                  {val.values.length > 0 && val.values.map((item, idx) => (
                                    <div key={idx} className="fil-opt">
                                      <RoCheckbox
                                        style={{ width: 18, height: 18 }}
                                        value={item}
                                        checked={val.values.includes(item)}
                                        onChange={props.handleCheckbox} />
                                      <span className='fil-txt font-face-futura'>{item}</span>
                                    </div>
                                  ))}
                                </>
                              ))} */}
                              {Object.keys(props?.filteredValues)?.length > 0 && Object.keys(props?.filteredValues).map((val, idx) => (
                                props?.filteredValues[val].length > 0 && (<>
                                  <h6 className='text-capitalize'>{searchItems.find(item => item.data == val)?.value}</h6>
                                  <div className="bottom"></div>
                                  {props?.filteredValues[val].map((item, idx) => (
                                    <div key={idx} className="fil-opt">
                                      <RoCheckbox
                                        style={{ width: 18, height: 18 }}
                                        value={item}
                                        checked={props?.filteredValues[val].includes(item)}
                                        onChange={() => props.handleGobackCheckbox(val, item)} />
                                      <span className='fil-txt font-face-futura'>{item}</span>
                                    </div>
                                  ))}
                                </>)
                              ))}
                            </div>
                          </> :
                          <div className='place-txt'><span>Select value to filter the table</span></div>
                      }
                    </div>
                    <div className='row'>
                      {props.showCheckedValues ?
                        <>
                          <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.clearFilter}>
                            <span>Clear Filter</span>
                          </div>
                          <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.filterTable}>
                            <span>Apply</span>
                          </div>
                        </> :
                        props.showSearch ?
                          <>
                            <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.handleGoBack}>
                              <span>Go Back</span>
                            </div>
                            <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.filterTable}>
                              <span>Apply</span>
                            </div>
                          </> : <></>
                      }
                    </div>
                  </div>
                  <div className='filter-icon'>
                    <IconButton onClick={props.selectHandler}>
                      <FilterListIcon
                        style={{ backgroundColor: 'white', width: 32, height: 30, borderRadius: '50%', color: '#535353', marginTop: 1 }} />
                    </IconButton>
                    {props?.checkboxValues?.length > 0 && <p onClick={props.clearFilter}>Clear Filter</p>}
                  </div>
                </div>

                <div className='claimDiv position-relative'>
                  <span className='text-light font-face-futura-footer'>Status: </span>
                  <select className={props.claimSelector === true ? 'sticky-select show-selector' : 'sticky-select none-selector'}
                    onChange={props.onClaimChange} multiple>
                    {claimsDropdown.map((claim, key) => (
                      <option
                        className='p-2 option text-center font-face-futura-footer'
                        selected={props.claimsDropdownSelect === claim.key ? true : false}
                        onClick={handleClaimsDropdown} key={key} value={claim.key}>{claim.value}</option>
                    ))}
                  </select>
                  <div onClick={props.claimHandler}>
                    <label htmlFor='claims-input' className='claimsDrop'>
                      <input className='claimInput font-face-futura-placeholder'
                        type='text'
                        placeholder={props.labelValue ? props.labelValue : 'Work List'} />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 pt-2 font-face-futura-footer">
            {/* <FormControl style={{float: 'right', marginRight: 120}}> */}
            {/* <label htmlFor="rules" className="text-white" style={{textAlign: 'left'}}>Rules ran on:</label> */}
            <div className='foot-calendar'>
              <span className='text-light scrubTxt'>Scrubbed On: </span>
              <DatePicker
                className='date-picker'
                format={"MM-dd-yyyy"}
                placeholderText="MM/DD/YYYY"
                selected={path === '/rule-processor' ? props.formattedRuleDate : props.rulesDate}
                onChange={path === '/rule-processor' ? (e) => onDateChange(e) : props.handleDateChange}
                customInput={
                  <MaskedInput
                    pipe={autoCorrectedDatePipe}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    guide={true}
                    keepCharPositions={true} />}
              />
              <span className='mt-1'>
                <img src={calendar} alt='foot-calendar' />
              </span>
            </div>
            {/* </FormControl> */}
          </div>
        </div>)}

      {(path === '/denial-management') && (
        // <div className="row" onClick={props.optionSelector === true && props.handleSelectHandler}>
        <div className="row">
          <div className="col-6 pt-2">
            <div className="form-inline">
              <div className='footer-left-area'>
                <div className='t-left'>
                  <div className={props.optionSelector === true ? 'sticky-selection2 show-selector' : 'sticky-selection2 none-selector'}>
                    <div className='row filter-row'>
                      <div className='col-12 col-sm-12 col-lg-12 filter-txt'>
                        <span className='mx-1 text-light'>Select Filter</span>
                      </div>
                    </div>
                    <select
                      className='sticky-sel font-face-futura'
                      id='select-box'
                      value={props.selectValue}
                      onChange={props.onSelectChange}>
                      <option value="" disabled selected>Select</option>
                      {denialSearchItems.map((item, key) => (
                        <>
                          <option style={{ fontSize: 14 }} key={key} value={item.key} id={item.value}>{item.value}</option>
                        </>))}
                    </select>
                    <div className={props.showSearch || props.showCheckedValues ? 'mt-2 fil-box2' : 'mt-2 fil-box'}>
                      {props.showSearch ?
                        <>
                          <input
                            className='m-2 fil-input font-face-futura'
                            placeholder='Type something to search...'
                            value={props.filterValue}
                            onChange={(e) => props.searchFilterValues(e.target.value)} />
                          <div className={props.columnData.length > 0 ? 'fil-drop2' : 'fil-drop'}>
                            {props.columnData.length > 0 && props.columnData.map((col, idx) => (
                              <div key={idx} className="fil-opt">
                                <RoCheckbox
                                  style={{ width: 18, height: 18 }}
                                  value={col}
                                  checked={props?.checkboxValues?.includes(col)}
                                  onChange={props.handleCheckbox} />
                                <span className='fil-txt font-face-futura'>{col}</span>
                              </div>
                            ))}
                          </div>
                        </> : props.showCheckedValues ?
                          <>
                            <div className='mt-2 mx-2 font-face-futura fil-drop2'>
                              {Object.keys(props?.filteredValues)?.length > 0 && Object.keys(props?.filteredValues).map((val, idx) => (
                                props?.filteredValues[val].length > 0 && (<>
                                  <h6 className='text-capitalize'>{denialSearchItems.find(item => item.data == val)?.value}</h6>
                                  <div className="bottom"></div>
                                  {props?.filteredValues[val].map((item, idx) => (
                                    <div key={idx} className="fil-opt">
                                      <RoCheckbox
                                        style={{ width: 18, height: 18 }}
                                        value={item}
                                        checked={props?.filteredValues[val].includes(item)}
                                        onChange={() => props.handleGobackCheckbox(val, item)} />
                                      <span className='fil-txt font-face-futura'>{item}</span>
                                    </div>
                                  ))}
                                </>)
                              ))}
                            </div>
                          </> :
                          <div className='place-txt'><span>Select value to filter the table</span></div>
                      }
                    </div>
                    <div className='row'>
                      {props.showCheckedValues ?
                        <>
                          <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.clearFilter}>
                            <span>Clear Filter</span>
                          </div>
                          <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.filterTable}>
                            <span>Apply</span>
                          </div>
                        </> :
                        props.showSearch ?
                          <>
                            <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.handleGoBack}>
                              <span>Go Back</span>
                            </div>
                            <div className='col-6 col-sm-6 col-lg-6 goBack' onClick={props.filterTable}>
                              <span>Apply</span>
                            </div>
                          </> : <></>
                      }
                    </div>
                  </div>
                  <div className='filter-icon'>
                    <IconButton onClick={props.selectHandler}>
                      <FilterListIcon
                        style={{ backgroundColor: 'white', width: 32, height: 30, borderRadius: '50%', color: '#535353', marginTop: 1 }} />
                    </IconButton>
                    {(props?.checkboxValues?.length > 0 || props.showCheckedValues) && <p onClick={props.clearFilter}>Clear Filter</p>}
                  </div>
                </div>
                <OutsideAlerter handleHideState={props.setClaimSelector}>
                <div className='claimDiv'>
                  <span className='text-light font-face-futura-footer'>Status: </span>
                  <select style={{height: '111px'}} className={props.claimSelector === true ? 'sticky-select show-selector' : 'sticky-select none-selector'}
                    onChange={props.onClaimChange} multiple>
                    {denailFilterDropdown.map((claim, key) => (
                      <option
                        className='p-2 option text-center font-face-futura-footer'
                        selected={props.claimsDropdownSelect === claim.key ? true : false}
                        onClick={handleClaimsDropdown} key={key} value={claim.key}>{claim.value}</option>
                    ))}
                  </select>
                  <div onClick={props.statusDropdownHandler}>
                    <label htmlFor='claims-input' className='claimsDrop'>
                      <input className='claimInput font-face-futura-placeholder'
                        type='text'
                        placeholder={props.labelValue ? props.labelValue : 'Claim Center - Not Ran'} />
                    </label>
                  </div>
                </div>
                </OutsideAlerter>
              </div>
            </div>

            {/* <div className='foot-calendar'>
              <DatePicker
                className='date-picker'
                format={"MM-dd-yyyy"}
                placeholderText="MM/DD/YYYY"
                selected={path === '/rule-processor' ? props.formattedRuleDate : props.rulesDate}
                onChange={path === '/rule-processor' ? (e) => onDateChange(e) : props.handleDateChange}
                customInput={
                  <MaskedInput
                    pipe={autoCorrectedDatePipe}
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    guide={true}
                    keepCharPositions={true} />}
              />
              <span className='mt-1 mx-2'>
                <img src={calendar} alt='foot-calendar' />
              </span>
            </div> */}
          </div>

          <div className="col-6 pt-2">
            <div className='foot-calendar font-face-futura'>
              <div className='denDivFrom'>
                <span className='text-light denFromTxt'>Denial From: </span>
                <DatePicker
                  className='date-picker'
                  format={"MM-dd-yyyy"}
                  placeholderText="MM/DD/YYYY"
                  selected={path === '/rule-processor' ? props.formattedRuleDate : props.denailFromAndToDate?.fromDate ? new Date(props.denailFromAndToDate?.fromDate) : null}
                  onChange={path === '/rule-processor' ? (e) => onDateChange(e) : (e) => props.handleDateChange(e, 'DENIAL_FROM')}
                  customInput={
                    <MaskedInput
                      pipe={autoCorrectedDatePipe}
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      guide={true}
                      keepCharPositions={true} />}
                />
              </div>
              <div className='denDivTo'>
                <span className='text-light denToTxt'>Denial To: </span>
                <DatePicker
                  className='date-picker'
                  format={"MM-dd-yyyy"}
                  placeholderText="MM/DD/YYYY"
                  selected={path === '/rule-processor' ? props.formattedRuleDate : props.denailFromAndToDate?.toDate ? new Date(props.denailFromAndToDate?.toDate): null}
                  onChange={path === '/rule-processor' ? (e) => onDateChange(e) :  (e) => props.handleDateChange(e, 'DENIAL_TO')}
                  customInput={
                    <MaskedInput
                      pipe={autoCorrectedDatePipe}
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                      guide={true}
                      keepCharPositions={true} />}
                />
              </div>
              <span className='mt-1 mx-2'>
                <img src={calendar} alt='foot-calendar' />
              </span>
            </div>
          </div>
        </div>)}

      {path === '/manageRule' && (
        <div className="row">
          <div className="col-6 pt-2">
            <div className="form-inline">
              <div className='footer-left-area'>
                <div className='t-left'>
                  <select
                    className={props.selector === true ? 'sticky-selection show-selector' : 'sticky-selection none-selector'}
                    onChange={props.onSelect} multiple>
                    {searchValues.map((item, key) => (<>
                      <option className='p-1 option text-center font-face-futura-footer' key={key} value={item.key}>{item.value}</option>
                    </>))}
                  </select>
                  <IconButton onClick={props.clickHandler}>
                    <FilterListIcon
                      style={{ backgroundColor: 'white', width: 32, height: 30, borderRadius: '50%', color: '#535353', marginTop: 3 }} />
                  </IconButton>
                </div>
                <div>
                  <input
                    type="text"
                    className='p-2 searchInput font-face-futura-placeholder'
                    placeholder={props.placeholder ? props.placeholder : 'Search by rule name'}
                    style={inputStyle}
                    value={props.ruleSearchValue}
                    onChange={props.onInput}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>)}

      {path === '/create-rule' && (<></>)}
    </div>
  );
}

Footer.propTypes = {
  onSelectChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  rulesRan: PropTypes.bool.isRequired,
  ruleSuccess: PropTypes.bool.isRequired,
  formattedRuleDate: PropTypes.string.isRequired,
  rulesDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  selector: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  clickHandler: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  ruleSearchValue: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  handleClaimsDropdown: PropTypes.func.isRequired
}

export default Footer;
