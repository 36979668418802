import React, { Component } from "react";
import API from "../../Api/index";
import Sidebar from "../Sidebar";
import { Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import "./autoRunRule.css";
import { Tabs, Tab } from "@material-ui/core";
import AutoRunRuleRepo from "./AutoRunRuleRepo";
import { dateRangeData, transactionHeader, varibleBreakdown } from "./ReportData";
import { header } from "./ReportData";
import BarChart from "layouts/BarChart";
import RadarChart from "layouts/RadarChart";
import { LoadingSpinner } from "lib/loader";
import ruleoutLogo from '../../assets/icons/logo2.svg';
/**
 * Swiper css including pagination and navigation
 */
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Virtual,
} from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import CleanChart from "layouts/CleanChart";
import Loader from "react-loader-spinner";
import CustomSelect from "./CustomSelect";
SwiperCore.use([Virtual, Navigation, Pagination, Scrollbar, A11y]);

/**
 * gloabal varible for filter date range
 */
const today = new Date();
var dateFilte = "month_to_date";
var initialDate = JSON.stringify(today);
var finalDate = JSON.stringify(new Date(today.getFullYear(), today.getMonth(), 2));
var dateCount = Math.floor((today.getTime() - (new Date(today.getFullYear(), today.getMonth())).getTime()) / (1000 * 60 * 60 * 24))
var lastDate = new Date(new Date(today.getFullYear(), today.getMonth(), 2) - (dateCount * 24 * 60 * 60 * 1000))

var providerDropdownkey = "Clean Claims"

var serviceLocation = "all";
var providerNameFilter = "all";
var primaryInsurancePlanName = "all";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportTabValue: 0,
      isAllPracticeSelected: false,
      optionForProviders: [],
      optionServiceLocation: [],
      optionPrimaryInsurance: [],
      topFiveRuleViolation: [],
      transactionDetails: [],
      providerFilterdData: [],
      serviceFilterdData: [],
      insuranceFilterdData: [],
      providerName: [],
      providerCount: [],
      selectedValueOfProvider: "Clean Claims",
      selectedDateRange: "",
      swiperDetails: {},
      cleanClaimRate: {},
      showLoader: false,
      showCancelLoader: false,
      dropdownCloseOnChange: false,

      /**
       * Bar graph state for Variable Breakdown violation of providers and service location
       */
      barGraphData: {
        labels: [],
        datasets: [
          {
            label: "violation",
            data: [],
            backgroundColor: "#5CB02C",
          },
        ],
      },
      cleanClaimsRateChart: {
        labels: ["Clean Claims", "Clean Violations"],
        datasets: [
          {
            label: "clean Claims",
            data: [0, 0],
            backgroundColor: ["#5CB02C", "#3681A9"],
          },
        ],
      },
      /**
       * Radar graph state for top Variable Breakdown violation
       */
      dataOfRadarChart: {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            borderColor: "#5CB02C",
            borderWidth: 6,
            pointRadius: 8,
            pointBorderColor: "#24476A",
            backgroundColor: "#24476A",
          },
        ],
      },
    };
  }

  //function to handle tabs
  handleTabs = (event, reportTabValue) => {
    this.setState({ reportTabValue });
  };

  /**
   * funtion to handle top 5 rule violation
   */
  getTopRuleViolation = async () => {
    await API.post("topRuleViolation")
      .then((res) => {
        LoadingSpinner.show();
        if (!res) return;
        var countArr = res.data.ruleCount;
        var ruleDetailsArr = res.data.data;
        const topFiveRuleViolationData = countArr?.map((item) => {
          const obj = ruleDetailsArr?.find((o) => o.ruleId === item.ruleId);
          return { ...item, ...obj };
        });
        this.setState({
          topFiveRuleViolation: topFiveRuleViolationData == undefined ? [] : topFiveRuleViolationData
        });
        LoadingSpinner.hide();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function to handle transaction data
   */
  getTransactionData = async () => {
    let allMonthsData = [];
    let transactionCount = [];
    await API.get("getTransactionsDetails")
      .then((transactions) => {
        transactions.data.data.map((res) => {
          allMonthsData.push(res.field.month);
          transactionCount.push(res.count);
        });
        let currentMonthData = new Date().getMonth();
        var today = new Date();
        var d;
        var month;
        var year;
        let prevMonths = [];
        for (var i = 0; i < 12; i += 1) {
          d = new Date(today.getFullYear(), today.getMonth() - i, 1);
          year = d.getFullYear();
          month = allMonthsData[d.getMonth()] + " - " + year;
          prevMonths.push({ month });
        }
        let lastYear = prevMonths;
        let transactionData = transactionCount
          .slice(currentMonthData + 1 - 12)
          .concat(transactionCount.slice(0, currentMonthData + 1))
          .reverse();
        let transactionDetails = transactionData?.map((item, i) =>
          Object.assign({}, item, lastYear[i])
        );
        this.setState({ transactionDetails: transactionDetails });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function to handle date range dropdown with initial and final date to filter the swiper
   * @param {*} e
   */
  dateRangeChange = (e) => {
    const value = e.target.value;
    dateFilte = value
    this.setState({ selectedDateRange: e.target.value, dropdownCloseOnChange: false })
    const today = new Date();
    const currDate = today;
    switch (value) {
      case "month_to_date":
        if (value === "month_to_date") {
          const month_date = new Date(today.getFullYear(), today.getMonth(), 2);
          initialDate = currDate ? currDate : 0;
          finalDate = month_date ? month_date : 0;
          let prevDateCount = Math.floor(
            (initialDate.getTime() - month_date.getTime()) /
            (1000 * 60 * 60 * 24)
          );
          let prevDate = new Date(
            finalDate - prevDateCount * 24 * 60 * 60 * 1000
          );
          lastDate = prevDate;
        }
        break;
      case "quater_to_date":
        if (value === "quater_to_date") {
          const quarter = Math.floor(today.getMonth() / 3);
          const startFullQuarter = new Date(
            today.getFullYear(),
            quarter * 3,
            2
          );
          // const endFullQuarter = new Date(startFullQuarter.getFullYear(),startFullQuarter.getMonth() + 3, 0);
          const endFullQuarter = currDate;
          initialDate = endFullQuarter ? endFullQuarter : 0;
          finalDate = startFullQuarter ? startFullQuarter : 0;
          let prevDateCount = Math.floor(
            (initialDate.getTime() - finalDate.getTime()) /
            (1000 * 60 * 60 * 24)
          );
          let prevDate = new Date(
            finalDate - prevDateCount * 24 * 60 * 60 * 1000
          );
          lastDate = prevDate;
        }
        break;
      case "Year_to_date":
        if (value == "Year_to_date") {
          const Year_to_date = new Date(today.getFullYear(), 0, 2);
          initialDate = currDate;
          finalDate = Year_to_date;
          let prevDateCount = Math.floor(
            (initialDate.getTime() - finalDate.getTime()) /
            (1000 * 60 * 60 * 24)
          );
          let prevDate = new Date(
            finalDate - prevDateCount * 24 * 60 * 60 * 1000
          );
          lastDate = prevDate;
        }
        break;
      case "last_month":
        if (value === "last_month") {
          const Last_Month_start_Date = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            2
          );
          const Last_Month_lastDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          initialDate = Last_Month_lastDate;
          finalDate = Last_Month_start_Date;
          let prevDateCount = Math.floor(
            (initialDate.getTime() - finalDate.getTime()) /
            (1000 * 60 * 60 * 24)
          );
          let prevDate = new Date(
            finalDate - prevDateCount * 24 * 60 * 60 * 1000
          );
          lastDate = prevDate;
        }
        break;
      case "last_quarter":
        if (value === "last_quarter") {
          const quarter = Math.floor(today.getMonth() / 3);
          const lastStartFullQuarter = new Date(
            today.getFullYear(),
            quarter * 3 - 3,
            2
          );
          const lastEndFullQuarter = new Date(
            lastStartFullQuarter.getFullYear(),
            lastStartFullQuarter.getMonth() + 3,
            1
          );
          initialDate = lastEndFullQuarter;
          finalDate = lastStartFullQuarter;
          let prevDateCount = Math.floor(
            (initialDate.getTime() - finalDate.getTime()) /
            (1000 * 60 * 60 * 24)
          );
          let prevDate = new Date(
            finalDate - prevDateCount * 24 * 60 * 60 * 1000
          );
          lastDate = prevDate;
        }
        break;
      case "last_year":
        if (value === "last_year") {
          const lastyear = new Date(new Date().getFullYear() - 1, 0, 2);
          const Last_Year_start_date = new Date(lastyear.getFullYear(), 0, 2);
          const Last_Year_last_date = new Date(lastyear.getFullYear(), 11, 32);
          initialDate = Last_Year_last_date;
          finalDate = Last_Year_start_date;
          let prevDateCount = Math.floor(
            (initialDate.getTime() - finalDate.getTime()) /
            (1000 * 60 * 60 * 24)
          );
          let prevDate = new Date(
            finalDate - prevDateCount * 24 * 60 * 60 * 1000
          );
          lastDate = prevDate;
        }
        break;
      default:
    }
    providerNameFilter = [];
    serviceLocation = [];
    primaryInsurancePlanName = [];
    this.getSwiperReport();
    this.getReportsFilterOnSwiper();
  };

  schedulingProviderChange = (e, key) => {
    switch (key) {
      case 'SchedulingProviderName':
        providerNameFilter = e
        this.setState({ providerFilterdData: e })
        break;
      case 'ServiceLocationName':
        serviceLocation = e
        this.setState({ serviceFilterdData: e })
        break;
      case 'PrimaryInsurancePlanName':
        primaryInsurancePlanName = e
        this.setState({ insuranceFilterdData: e })
        break;
      default:
        break;
    }
  };

  /**
   * function to show all the option in the providers, service location and Primary insurance dropdown
   */
  getReportsFilterOnSwiper = () => {
    let body_data = {
      dateRange: {
        fromDate: initialDate,
        toDate: finalDate,
      },
      serviceLocation: (serviceLocation.length === 0 ? "all" : serviceLocation),
      providerName: (providerNameFilter.length === 0 ? "all" : providerNameFilter),
      primaryInsurancePlanName: (primaryInsurancePlanName.length === 0 ? "all" : primaryInsurancePlanName),
    };
    API.post("ranEncountersFilters", body_data)
      .then((res) => {
        this.setState({
          optionForProviders: res.data.providerData,
          optionServiceLocation: res.data.locationData,
          optionPrimaryInsurance: res.data.primaryInsuranceData,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * function to handle swiper details
   */
  getSwiperReport = () => {
    this.setState({ showLoader: true })
    let dateRange_body = {
      dateRange: {
        fromDate: initialDate,
        toDate: finalDate,
        lastDate: lastDate,
      },
      dateFilte: dateFilte,
      serviceLocation: (serviceLocation.length === 0 ? "all" : serviceLocation),
      providerName: (providerNameFilter.length === 0 ? "all" : providerNameFilter),
      primaryInsurancePlanName: (primaryInsurancePlanName.length === 0 ? "all" : primaryInsurancePlanName),
    };
    API.post("swiperTransaction", dateRange_body)
      .then((res) => {
        this.setState({
          swiperDetails: res.data ? res.data : {},
          showLoader: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function to handle variable breakdown change providers with violations
   * @param {*} event
   */
  handleProviderChange = (event) => {
    if (event.target.value == "Service Locations") {
      providerDropdownkey = "ServiceLocationName";
    } else if (event.target.value == "Providers") {
      providerDropdownkey = "SchedulingProviderName";
    }
    this.setState({ selectedValueOfProvider: event.target.value });
    this.getVaribaleBreakDown();
  };

  /**
   * function to handle bar graph chart of providers with violations
   */
  getVaribaleBreakDown = () => {
    let providr_body = {
      provider: providerDropdownkey,
    };
    let providerName = [];
    let providerCount = [];
    LoadingSpinner.show();
    API.post("getVariableBreakdown", providr_body)
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        res.data.providerData.map((violation) => {
          let schedulingProviderName = violation.SchedulingProviderName;
          let serviceLocationName = violation.ServiceLocationName;
          providerName.push(
            schedulingProviderName
              ? schedulingProviderName
              : serviceLocationName
          );
          providerCount.push(violation.count);
        });
        this.setState({
          cleanClaimRate: res.data,
          barGraphData: {
            labels: providerName ? providerName : [],
            datasets: [
              {
                label: "violation",
                data: providerCount ? providerCount : [],
                backgroundColor: "#5CB02C",
              },
            ],
          },
          cleanClaimsRateChart: {
            labels: ["Clean Claims", "Clean Violations"],
            datasets: [
              {
                label: "clean Claims",
                data: [res.data.cleanClaimsData, res.data.claimViolationData],
                backgroundColor: ["#5CB02C", "#3681A9"],
              },
            ],
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    LoadingSpinner.hide();
  };

  /**
   * function to handle Radar(Spider) chart Data with top violation, of provider, diagnosis, CPT, POS, patients, PI
   */
  getTopVaribleViolation = () => {
    API.get("getTopVariableViolations")
      .then((res) => {
        let radarChartLabels = res.data.dataName;
        let radarChartData = res.data.dataCount;
        this.setState({
          dataOfRadarChart: {
            labels: radarChartLabels,
            datasets: [
              {
                label: "",
                data: radarChartData,
                borderColor: "#5CB02C",
                borderWidth: 6,
                pointRadius: 8,
                pointBorderColor: "#24476A",
                backgroundColor: "#24476A",
                fill: false,
                spanGaps: false,
              },
            ],
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    initialDate = JSON.stringify(today);
    finalDate = JSON.stringify(
      new Date(today.getFullYear(), today.getMonth(), 2)
    );
    dateCount = Math.floor(
      (today.getTime() -
        new Date(today.getFullYear(), today.getMonth()).getTime()) /
      (1000 * 60 * 60 * 24)
    );
    lastDate = new Date(
      new Date(today.getFullYear(), today.getMonth(), 2) -
      dateCount * 24 * 60 * 60 * 1000
    );
    providerDropdownkey = "Clean Claims";
    this.getReportsFilterOnSwiper();
    this.getTopVaribleViolation();
    this.getVaribaleBreakDown();
    this.getSwiperReport();
    this.getTopRuleViolation();
    this.getTransactionData();
  }

  render() {
    const {
      reportTabValue,
      isAllPracticeSelected,
      optionForProviders,
      optionServiceLocation,
      optionPrimaryInsurance,
      topFiveRuleViolation,
      transactionDetails,
      swiperDetails,
      barGraphData,
      dataOfRadarChart,
      selectedValueOfProvider,
      selectedDateRange,
      cleanClaimRate,
      cleanClaimsRateChart,
      showLoader,
      showCancelLoader,
      providerFilterdData,
      serviceFilterdData,
      insuranceFilterdData
    } = this.state;

    let roleData = JSON.parse(sessionStorage?.getItem('userDetail'))

    return (
      <>
        <section style={{ backgroundColor: "white" }}>
          <Sidebar
            hasAllPracticeOption={(roleData.roleId === 1 || roleData.roleId === 2) ? true : false}
            handleChangePractice={(practice) => {
              console.log("pract", practice.id)
              this.setState({
                topFiveRuleViolation: [],
                dropdownCloseOnChange: false
              })
              serviceLocation = "all";
              providerNameFilter = "all";
              primaryInsurancePlanName = "all";
              this.getReportsFilterOnSwiper();
              this.getTopVaribleViolation();
              this.getVaribaleBreakDown();
              this.getSwiperReport();
              this.getTopRuleViolation();
              if (practice == "all") {
                this.setState({ isAllPracticeSelected: true });
              } else this.setState({ isAllPracticeSelected: false });
            }}
          />
                <div className='sectionlayoutcontainer'>
       
              <Tabs
                value={reportTabValue}
                indicatorColor="primary"
                onChange={this.handleTabs}
                variant="fullWidth"
              >
                <Tab
                  className="font-face-futura"
                  icon={<span className="transctionTabText">T</span>}
                  label="Transactions"
                />
                <Tab
                  className="font-face-futura"
                  icon={<span className="tabAutoRun">A</span>}
                  label="Auto-Run"
                />
              </Tabs>
        

          {reportTabValue === 0 && (
            <>

            <div className="container-fluid">
              <div className="row py-1 align-items-center justify-content-between">
                <div className="col-auto">
                  <span className="trnsactionTab ">Reports</span>
                </div>
                <div className="col-auto">
                <div className="d-flex align-items-center gap-4">
                  <label className="dataforRange" htmlFor="dateRangeData">
                    Date Range:
                  </label>
                  <select
                    className="dateRange"
                    onChange={this.dateRangeChange}
                    defaultValue={selectedDateRange}
                    selected={selectedDateRange}
                  >
                    {dateRangeData.map((item) => (
                      <option key={item.name} value={item.name}>
                        {item.key}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
              </div>
              {isAllPracticeSelected == false && (
                <div className="reportHead">
                    <div className="row justify-content-around w-100 gap-3">
                      <div className="col-auto">
                        <div className="divbox">
                          <label
                            className="text-white text-nowrap font-face-futura"
                            htmlFor="providers"
                          >
                            Providers:
                          </label>
                          <CustomSelect
                            listItem={optionForProviders}
                            schedulingProviderChange={this.schedulingProviderChange}
                            selectedData = {providerFilterdData}
                            name={'SchedulingProviderName'}
                            getReportsFilterOnSwiper={this.getReportsFilterOnSwiper}
                            getSwiperReport={this.getSwiperReport}
                            dropdownCloseOnChange={this.state.dropdownCloseOnChange}
                            selectedDateRange={selectedDateRange}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="divbox">
                          <label
                            className="text-white text-nowrap font-face-futura"
                            htmlFor="location"
                          >
                            Service Location:
                          </label>
                          <CustomSelect
                            listItem={optionServiceLocation}
                            schedulingProviderChange={this.schedulingProviderChange}
                            selectedData = {serviceFilterdData}
                            name={'ServiceLocationName'}
                            getReportsFilterOnSwiper={this.getReportsFilterOnSwiper}
                            getSwiperReport={this.getSwiperReport}
                            dropdownCloseOnChange={this.state.dropdownCloseOnChange}
                            selectedDateRange={selectedDateRange}
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <div className="divbox">
                          <label
                            className="text-white text-nowrap font-face-futura"
                            htmlFor="insurance"
                          >
                            Primary Insurance:
                          </label>
                          <CustomSelect
                            listItem={optionPrimaryInsurance}
                            schedulingProviderChange={this.schedulingProviderChange}
                            selectedData = {insuranceFilterdData}
                            name={'PrimaryInsurancePlanName'}
                            getReportsFilterOnSwiper={this.getReportsFilterOnSwiper}
                            getSwiperReport={this.getSwiperReport}
                            dropdownCloseOnChange={this.state.dropdownCloseOnChange}
                            selectedDateRange={selectedDateRange}
                          />
                        </div>
                      </div>
                    </div>               
                </div>
              )}
              <div className="row mySwiperCard py-4 mx-auto mb-5">
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    slidesPerView={4}
                    // spaceAround={20}
                    grabCursor={true}
                    pagination={{
                      className: ".swiper-pagination",
                      clickable: true,
                    }}
                    navigation={true}
                    onSlideChange={() => console.log("slide Cahnge")}
                    onSwiper={(swiper) => console.log("swiper")}
                  >
                    <SwiperSlide>
                      <div className="mySwiper">
                        <div className="swipeCard">
                          <div className="row swipeDetail">
                            <div className="col-lg-12 col-sm-12 ">
                              <span>ENCOUNTERS RAN</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w31">
                                <span
                                  className={
                                    swiperDetails.encounterRanCountPer >= 0
                                      ? "perc"
                                      : "percRed"
                                  }
                                >
                                  {swiperDetails.encounterRanCountPer}%
                                </span>
                              </div>
                              <div className="w32 total">
                                <span>
                                  Previous Total:{" "}
                                  <strong>
                                    {swiperDetails.prevEncounterRanCount}
                                  </strong>
                                </span>
                              </div>
                              <div className="w33 total">
                                <span>
                                  Current Total:{" "}
                                  <strong>
                                    {swiperDetails.encounterRanCount}
                                  </strong>
                                </span>
                              </div>
                            </div>
                            <span className="percStatus">
                              {swiperDetails.encounterRanCountPer > 0
                                ? "INCREASE"
                                : swiperDetails.encounterRanCountPer < 0
                                  ? "DECREASE"
                                  : " "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="mySwiper">
                        <div className="swipeCard">
                          <div className="row swipeDetail">
                            <div className="col-lg-12 col-sm-12 ">
                              <span>ELIGIBILITY CHECKED</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w31">
                                <span
                                  className={
                                    swiperDetails.eligibilityCheckedCountPer >=
                                      0
                                      ? "perc"
                                      : "percRed"
                                  }
                                >
                                  {swiperDetails.eligibilityCheckedCountPer}%
                                </span>
                              </div>
                              <div className="w32 total">
                                <span>
                                  Previous Total:{" "}
                                  <strong>
                                    {swiperDetails.prevEligibilityCheckedCount}
                                  </strong>
                                </span>
                              </div>
                              <div className="w33 total">
                                <span>
                                  Current Total:{" "}
                                  <strong>
                                    {swiperDetails.eligibilityCheckedCount}
                                  </strong>
                                </span>
                              </div>
                            </div>
                            <span className="percStatus">
                              {swiperDetails.eligibilityCheckedCountPer > 0
                                ? "INCREASE"
                                : swiperDetails.eligibilityCheckedCountPer < 0
                                  ? "DECREASE"
                                  : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="mySwiper">
                        <div className="swipeCard">
                          <div className="row swipeDetail">
                            <div className="col-lg-12 col-sm-12 ">
                              <span>CLEAN CLAIMS</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="w31">
                                <span
                                  className={
                                    swiperDetails.cleanCliamsCountPer >= 0
                                      ? "perc"
                                      : "percRed"
                                  }
                                >
                                  {swiperDetails.cleanCliamsCountPer}%
                                </span>
                              </div>
                              <div className="w32 total">
                                <span>
                                  Previous Total:{" "}
                                  <strong>
                                    {swiperDetails.prevCleanCliamsCount}
                                  </strong>
                                </span>
                              </div>
                              <div className="w33 total">
                                <span>
                                  Current Total:{" "}
                                  <strong>
                                    {swiperDetails.cleanCliamsCount}
                                  </strong>
                                </span>
                              </div>
                            </div>
                            <span className="percStatus">
                              {swiperDetails.cleanCliamsCountPer > 0
                                ? "INCREASE"
                                : swiperDetails.cleanCliamsCountPer < 0
                                  ? "DECREASE"
                                  : " "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="custom-swiper">
                        <div className="mySwiper">
                          <div className="swipeCard">
                            <div className="row swipeDetail">
                              <div className="col-lg-12 col-sm-12 ">
                                <span>CLAIMS VIOLATIONS</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="w31">
                                  <span
                                    className={
                                      swiperDetails.claimsViolationPer <= 0
                                        ? "perc"
                                        : "percRed"
                                    }
                                  >
                                    {swiperDetails.claimsViolationPer}%
                                  </span>
                                </div>
                                <div className="w32 total">
                                  <span>
                                    Previous Total:{" "}
                                    <strong>
                                      {swiperDetails.prevClaimsViolation}
                                    </strong>
                                  </span>
                                </div>
                                <div className="w33 total">
                                  <span>
                                    Current Total:{" "}
                                    <strong>
                                      {swiperDetails.claimsViolation}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <span className="percStatus">
                                {swiperDetails.claimsViolationPer > 0
                                  ? "INCREASE"
                                  : swiperDetails.claimsViolationPer < 0
                                    ? "DECREASE"
                                    : " "}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="custom-swiper">
                        <div className="mySwiper">
                          <div className="swipeCard">
                            <div className="row swipeDetail">
                              <div className="col-lg-12 col-sm-12">
                                <span>RULE VIOLATIONS</span>
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="w31">
                                  <span
                                    className={
                                      swiperDetails.ruleViolationCountPer <= 0
                                        ? "perc"
                                        : "percRed"
                                    }
                                  >
                                    {swiperDetails.ruleViolationCountPer}%
                                  </span>
                                </div>
                                <div className="w32 total">
                                  <span>
                                    Previous Total:{" "}
                                    <strong>
                                      {swiperDetails.prevRuleViolationCount}
                                    </strong>
                                  </span>
                                </div>
                                <div className="w33 total">
                                  <span>
                                    Current Total:{" "}
                                    <strong>
                                      {swiperDetails.ruleViolationCount}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <span className="percStatus">
                                {swiperDetails.ruleViolationCountPer > 0
                                  ? "INCREASE"
                                  : swiperDetails.ruleViolationCountPer < 0
                                    ? "DECREASE"
                                    : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
              </div>

              {isAllPracticeSelected === false && (
                <div className="mb-4">
                  <Card className="variableCard">
                    <CardHeader
                      className="reportCardHead"
                      title={
                        <h5 className="text-white font-face-futura">
                          Variable Breakdown
                        </h5>
                      }
                    ></CardHeader>
                    <div className="dashbaordsection-layout">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="breakDownType">
                            <label htmlFor="location">Breakdown Type:</label>
                            <select
                              className="breakDown"
                              onChange={this.handleProviderChange}
                              defaultValue={selectedValueOfProvider}
                              selected={selectedValueOfProvider}
                            >
                              {varibleBreakdown.map((option) => (
                                <option value={option.name} key={option.value}>
                                  {" "}
                                  {option.name}{" "}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mt-3">
                            <span className="providersViolation">
                              {selectedValueOfProvider == "Clean Claims"
                                ? "Clean Claim Rate"
                                : "Providers with violation"}
                            </span>
                          </div>
                          <div>
                            <div className="providerCard">
                              {selectedValueOfProvider == "Clean Claims" ? (
                                <div className="col-lg-12 col-sm-12">
                                  <div className="row">
                                    <div className="col-lg-6 col-sm-6 cleanPieChart">
                                      <p className="claimsPerc">
                                        {cleanClaimRate.cc_perc}%
                                      </p>
                                      <p className="approveText">
                                        {cleanClaimRate.cleanClaimsData} Claims
                                        Approved
                                      </p>
                                      <p className="violatedPerc">
                                        {cleanClaimRate.cv_perc}%
                                      </p>
                                      <p className="violatedText">
                                        {cleanClaimRate.claimViolationData}{" "}
                                        Claims Violated
                                      </p>
                                    </div>
                                    <div className="col-lg- col-sm-6 mt-5">
                                      <CleanChart data={cleanClaimsRateChart} />
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <BarChart data={barGraphData} />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <span className="variableViolation">
                            Top Variables with Violation
                          </span>
                          <div className="violationCard">
                            <RadarChart data={dataOfRadarChart} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              )}
            </div>
              <div>
                <Card className="reportCard">
                  <CardHeader
                    className="reportCardHead"
                    title={
                      <h5 className="text-white font-face-futura">
                        Top 5 Rule Violations
                      </h5>
                    }
                  ></CardHeader>
                  <CardContent className="dashbaordsection-layout">
                    <div className="managerRule">
                      <table className="manageTableRule mb-0">
                        <thead>
                          <tr>
                            {header.map((col) => (
                              <th
                                key={col.name}
                                className="text-center font-face-futura"
                              >
                                {col.name}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {topFiveRuleViolation?.length > 0 ? (
                            (topFiveRuleViolation || [])?.map((rule, index) => (
                              <tr key={rule.ruleName} className="table-row">
                                <td style={{ textAlign: "center" }}>
                                  <div className="innerdata">
                                    <span
                                      title=""
                                      className="one-line-ellipsis"
                                    >
                                      {index + 1}
                                    </span>
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="innerdata">
                                    <span
                                      title=""
                                      className="one-line-ellipsis"
                                    >
                                      {rule.ruleName}
                                    </span>
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="innerdata">
                                    <span
                                      title=""
                                      className="one-line-ellipsis"
                                    >
                                      {rule.count}
                                    </span>
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="innerdata">
                                    <span
                                      title=""
                                      className="one-line-ellipsis"
                                    >
                                      {new Date(
                                        rule.created_at
                                      ).toLocaleDateString("en-US")}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan={4}
                                className="mt-3 noRecord text-center"
                              >
                                No records found!
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              </div>
              {isAllPracticeSelected == true && (
                <div>
                  <Card className="reportCard">
                    <CardHeader
                      className="reportCardHead"
                      title={
                        <h5 className="text-white font-face-futura">
                          Transactions
                        </h5>
                      }
                    ></CardHeader>
                    <CardContent>
                      <div className="managerRule">
                        <table className="manageTableRule">
                          <thead>
                            <tr>
                              {transactionHeader.map((col) => (
                                <th
                                  key={col.name}
                                  className="text-center font-face-futura"
                                >
                                  {col.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {transactionDetails.length > 0 ? (
                              transactionDetails.map((item) => (
                                <tr key={item.id} className="table-row">
                                  <td style={{ textAlign: "center" }}>
                                    <div className="innerdata">
                                      <span
                                        title=""
                                        className="one-line-ellipsis"
                                      >
                                        {item.month}
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div className="innerdata">
                                      <span
                                        title=""
                                        className="one-line-ellipsis"
                                      >
                                        {item.encounterRanCount}
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div className="innerdata">
                                      <span
                                        title=""
                                        className="one-line-ellipsis"
                                      >
                                        {item.eligibilityCheckedCount}
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div className="innerdata">
                                      <span
                                        title=""
                                        className="one-line-ellipsis"
                                      >
                                        {item.cleanCliamsCount}
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div className="innerdata">
                                      <span
                                        title=""
                                        className="one-line-ellipsis"
                                      >
                                        {item.claimsViolation}
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div className="innerdata">
                                      <span
                                        title=""
                                        className="one-line-ellipsis"
                                      >
                                        {item.ruleViolationCount}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={4}
                                  className="mt-3 noRecord text-center"
                                >
                                  No records found!
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}
              {/* <div className="mt-5"></div> */}
            </>
          )}
          {reportTabValue === 1 && <AutoRunRuleRepo />}
          {showLoader && (
            <Dialog
              fullWidth
              open={showLoader}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div
                align="center"
                className={showCancelLoader ? "avatarRep2" : "avatarRep"}
              >
                <img
                  src={ruleoutLogo}
                  alt="ruleout"
                  className="ruleoutLogoRep"
                />
                <Loader
                  className="mt-2"
                  type="Oval"
                  height={180}
                  width={195}
                  color="#5CB02C"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#5CB02C"
                  strokeWidth={1}
                  strokeWidthSecondary={1}
                />
              </div>
              <div
                align="center"
                className={
                  showCancelLoader ? "dialog-boxRep2" : "dialog-boxRep"
                }
              >
                <DialogTitle id="alert-dialog-title">
                  <span className="font-face-futura dialogTitleRep">
                    {"Updating Reports"}
                  </span>
                </DialogTitle>
                {showCancelLoader ? (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <span className="dialogTextRep">
                        Hold tight while we fetch the reports!
                      </span>
                    </DialogContentText>
                    <br />
                    <DialogContentText id="alert-dialog-description">
                      <span className="dialogTextRep">
                        Hmm... This is taking longer than expected.
                      </span>
                      <br />
                      <span className="dialogTextRep">
                        Give us a little bit longer
                      </span>
                    </DialogContentText>
                    <Button
                      fullWidth
                      className="mt-4 dialogBtnRep"
                      variant="contained"
                      onClick={() => window.location.reload()}
                    >
                      <span className="font-face-futura">Cancel</span>
                    </Button>
                  </DialogContent>
                ) : (
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <span className="font-face-futura dialogTextRep">
                        Let's get you the reports!
                      </span>
                    </DialogContentText>
                  </DialogContent>
                )}
              </div>
            </Dialog>
          )}
          </div>
        </section>
      </>
    );
  }
}
export default Reports;