import React from 'react';
import Sidebar from '../Sidebar';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, Button, Card, Box, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import copyIcon from '../../assets/icons/copy.png';
import API from '../../Api';
// import { DateConverter } from '../helper/helper';
import "./manageRule.css"
import Swal from 'sweetalert2';
import copyRule from '../../assets/icons/copyRule.svg';
import powerIcon from '../../assets/icons/ruleStatus.svg';
import openInNew from '../../assets/icons/openInNew.svg';
import editIcon from '../../assets/icons/Edit.svg';
import deleteRule from '../../assets/icons/Trash.svg';
import { RoAlert } from 'layouts/RoAlert';
import { NotificationManager } from 'react-notifications';
import LineChart from 'layouts/LineChart';
import PieChart from 'layouts/PieChart';
import { withRouter } from 'react-router-dom';
import { savePracticeLocally } from 'components/Sidebar/PracticeSelect';
import Loader from "react-loader-spinner";

// logic to get last six months for dropdown
let allMonths = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"];

var today = new Date();
var d;
var month;
var year;
let lastSixMonths = [];

for (var i = 0; i < 6; i += 1) {
  d = new Date(today.getFullYear(), today.getMonth() - i, 1);
  year = d.getFullYear();
  month = allMonths[d.getMonth()] + ' ' + year;
  lastSixMonths.push(month);
}

class RuleDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: ['Patient Name', 'Encounter', 'Provider', 'DOS', 'Code', 'Primary Insurance',
        'Secondary Insurance', 'Service Location', 'DXC'],
      showCopyModal: false,
      showDeleteModal: false,
      practices: [],
      activePracticeId: JSON.parse(sessionStorage.getItem('practice') || '{}')?.id,
      ruleStatus: JSON.parse(window.localStorage.getItem("ruleStatus", this.props.location.state.ruleStatus)),
      chargeData: [],
      trizettoData: this.props.location.state.runRuleDetails,
      eligibilityData: this.props.location.state.eligibilityDetails,
      rpaData: this.props.location.state.rpaInfo,
      selectedMonth: '',
      triggeredEncounters: [],
      editRule: false,
      groupStatus: this.props.location.state.groupStatus,
      ruleSuccess: this.props.location.state.ruleSuccess,
      showDeleteRulePopup: false,
      activePracticeId: JSON.parse(sessionStorage.getItem('practice') || '{}')?.id,
      copyPracticeId: null,
      data: {
        labels: lastSixMonths,
        datasets: [
          {
            label: "Rule Violation",
            fill: false,
            lineTension: 0.5,
            backgroundColor: "rgba(75,192,192,1)",
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 2,
            data: [0, 0, 0, 0, 0, 0],
          },
        ],
      },
      dataOfPieGraph: {
        datasets: [
          {
            label: "Rule Violation",
            data: [0, 0.1],
            backgroundColor: ["#5CB02C", "#E5E5E5"],
            hoverOffset: 4,
          },
        ],
      },
      totalRuleViolation: 0,
      percentViolation: 0,
      particularRuleViolation: 0,
      ruleDetails: [],
      statusUpdatedBy: this.props.location.state.data[0].statusUpdatedBy,
      statusUpdatedAt: this.props.location.state.data[0].statusUpdated_at,
      loading: false
    }
  }

  // function to toggle status
  handleStatus = (id) => {
    console.log(this.props.location.state.data, 'sdfs');
    let ruleDetail = this.props.location.state?.data;
    if (this.state.groupStatus === true && this.state.ruleStatus === false) {
      Swal.fire({
        title: 'Do you wish to turn on the rule?',
        text: "Edit the rule first to turn it on!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#006F90',
        cancelButtonColor: '#C77B7B',
        confirmButtonText: 'Yes, edit it!'
      }).then((result) => {
        if (result.isConfirmed) {
          let length = ruleDetail[0].ruleInfo.length;
          let obj = {
            for: ruleDetail[0].ruleInfo[length - 1].operator,
            pendValue: ruleDetail[0].result,
            reason: ruleDetail[0].Reason,
            operator: "none"
          }
          ruleDetail[0].ruleInfo.push(obj);
          this.props.history.push({
            pathname: `/edit-rule/${id}`,
            state: {
              editRule: true,
              ruleDetail: ruleDetail[0],
              tags: ruleDetail[0]?.tags,
              ruleId: id,
              path: `/edit-rule/${id}`
            }
          })
        }
      })
    } else {
      API.put(`/status_OnOff/${id}`, { status: !this.state.ruleStatus }).then((response) => {
        // console.log("response data", response.data);
        let statusInfo = response.data.ruleDetail;
        this.setState({
          ruleStatus: !this.state.ruleStatus,
          statusUpdatedBy: statusInfo.statusUpdatedBy,
          statusUpdatedAt: statusInfo.statusUpdated_at
        }, () => {
          window.localStorage.setItem('ruleStatus', JSON.stringify(this.state.ruleStatus))
        });
      })
    }
  }

  // function to handle edit rule
  handleEdit = (ruleId) => {
    console.log(this.props.location.state.data, 'sdfs');
    let ruleDetail = this.props.location.state?.data;
    let length = ruleDetail[0].ruleInfo.length;
    let obj = {
      for: ruleDetail[0].ruleInfo[length - 1].operator,
      pendValue: ruleDetail[0].result,
      reason: ruleDetail[0].Reason,
      operator: "none"
    }
    ruleDetail[0].ruleInfo.push(obj);
    this.props.history.push({
      pathname: `/edit-rule/${ruleId}`,
      state: {
        editRule: true,
        ruleDetail: ruleDetail[0],
        tags: ruleDetail[0].tags,
        ruleId: ruleId,
        path: `/edit-rule/${ruleId}`
      }
    });
  }

  // function to handle copy modal
  handleCopyModal = () => {
    this.setState({ showCopyModal: !this.state.showCopyModal });
    this.getPractices();
  }
  closeCopyModal = () => {
    this.setState({ showCopyModal: false })
  }

  // function to handle delete modal
  handleDeleteModal = () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }
  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false })
  }

  // function to get current month encounters
  getCurrentEncounters = () => {
    let runRuleDetails = this.props.location.state.runRuleDetails;
    // console.log("runRuleDetails", runRuleDetails);
    let chargeDetail = this.props.location.state.chargeDetails;
    let presentMonth = new Date().getMonth();
    let monthEncounters = runRuleDetails.filter((item) => new Date(item.currentTime).getMonth() === presentMonth)
    monthEncounters.sort(function (a, b) {
      return new Date(b.currentTime) - new Date(a.currentTime);
    })
    // console.log("date sort", monthEncounters);
    let filteredIds = [];
    monthEncounters.forEach(el => {
      filteredIds.push(el.instanceID);
      // console.log(filteredIds)
    });
    // const filteredEncounters = chargeDetails.filter((item) => filteredIds.includes(item.EncounterID));
    let res = [];
    filteredIds.forEach((el) => {
      res.push(chargeDetail.find(el2 => el2.instanceID === el))
    })
    const filteredEncounters = res.filter(item => item);
    this.setState({ triggeredEncounters: filteredEncounters ? filteredEncounters : [] });
  }

  handleMonth = (event) => {
    let { ruleDetails } = this.state;
    let chargeDetail = this.props.location.state.chargeDetails;
    let selectedMonthValue = (event.target.value).split(" ")[0];
    console.log('selectedMonthValue', ruleDetails, chargeDetail);

    this.setState({ selectedMonth: selectedMonthValue });
    // console.log('value', this.state.selectedMonth);

    let selectedValue = allMonths.indexOf(selectedMonthValue.split(" ")[0]);
    // console.log('selectedMonth', selectedValue)
    let monthEncounters = ruleDetails.filter((item) => new Date(item.created_at).getMonth() === selectedValue)
    console.log('monthEncounters', monthEncounters);
    monthEncounters.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    console.log("date sort", monthEncounters);
    let filteredIds = [];
    monthEncounters.forEach(el => {
      filteredIds.push(el.instanceID);
    });
    let res = [];
    filteredIds.forEach((el) => {
      res.push(chargeDetail.find(el2 => el2.instanceID === el))
    })
    console.log("date sort", res);
    const filteredEncounters = res.filter(item => item);
    // const filteredEncounters = triggeredEncounters.chargeDetail.filter((item) => filteredIds.includes(item.EncounterID));
    console.log("filteredEncounters", filteredEncounters);
    this.setState({ triggeredEncounters: filteredEncounters ? filteredEncounters : [] });
  }

  // function to get list of all practices
  getPractices = () => {
    API.get('allPractices').then((response) => {
      // console.log(response.data.data);
      let practices = response.data.data;
      let practiceList = practices.filter(item => item.id !== this.state.activePracticeId);
      this.setState({ practices: practiceList });
    }).catch(err => {
      console.error(err);
    })
  }

  // function to delete rule
  deleteRule = (id) => {
    API.put(`deleteRule/${id}`).then((response) => {
      // console.log(response.data);
      this.setState({
        showDeleteModal: false
      });
      this.props.history.push('/manageRule');
    })
  }

  handleRow = (id) => {
    let body_data = {
      rulesRunStatus: this.state.ruleSuccess ? this.state.ruleSuccess : false
    }
    API.post(`getChargesFromLocalId/${id}`, body_data).then((response) => {
      // console.log(response.data);
      const chargeData = response.data;
      // console.log(this.props.location.state.data.ruleId);
      if (this.state.ruleSuccess === true) {
        let length = this.state.trizettoData.length;
        // console.log(length);
        // let trizettoRes = this.state.trizettoData.filter(item => item.EncounterID === chargeData[0].EncounterID);
        let chargeInstanceIds = [];
        chargeData.forEach(el => chargeInstanceIds.push(el.instanceID))
        let trizettoRes = this.state.trizettoData.filter(item => chargeInstanceIds.includes(item.instanceID));
        let eligibilityRes = this.state.eligibilityData.filter(item => chargeInstanceIds.includes(item.instanceID));
        // console.log(trizettoRes);
        // let finalTrizettoRes = trizettoRes[0].encounterRuleResult.filter((item) => item.ruleId === this.props.location.state.data.ruleId);
        // console.log(finalTrizettoRes);
        // trizettoRes[0]['encounterRuleResult'] = finalTrizettoRes;
        // console.log(trizettoRes);
        localStorage.setItem('isReviewd', trizettoRes[0].isReviewed);
        sessionStorage.setItem('viewedDetail', '0') // set 0 for not viewed page and log of action will trigger
        this.props.history.push({
          pathname: '/inspection-noviolation',
          state: {
            chargeData: chargeData,
            trizettoData: trizettoRes,
            eligibilityData: eligibilityRes,
            trizettoDate: this.state.trizettoData[length - 1],
            ruleInfo: this.props.location.state.runRuleDetails
          }
        });
      }
      this.setState({ chargeData: chargeData });
    }).catch(err => {
      console.error(err);
    });
  }

  copyRuleToPractice = async () => {
    // console.log('this.props.location.state?.data ', this.props.location.state?.data[0])
    // let practice = sessionStorage.getItem('practice')
    if (this.state.copyPracticeId === null) {
      return NotificationManager.info('Please select practice!', '', 9000);
    }
    if (this.state.copyPracticeId == this.state.activePracticeId) {
      return NotificationManager.info('This rule is already assign for this practice, Please change practice', '', 9000)
    }
    let body_data = this.props.location.state?.data[0]
    body_data.practiceId = this.state.copyPracticeId
    this.closeCopyModal()
    const responseFromApi = await API.post('createRule', body_data)
    if (responseFromApi?.status === 200 && responseFromApi.data.success === true) {
      return NotificationManager.success('Rule successfully copied', 'Success', 9000)
    } else {
      return NotificationManager.error('Rule already exist for this practice', 'Failed!', 9000)
    }
  }

  //function to handle analytics reports
  ruleViolationReports = () => {
    let allMonthsData = [];
    let lineCount = [];
    let ruleId = { ruleId: this.props.location.state.id ? this.props.location.state.id : false }
    if (this.props.location?.state?.data[0]?.practiceId == 0) {
      savePracticeLocally('all')
    }
    API.post(`ruleViolationGraph`, ruleId).then((res) => {
      let dataOfGraph = res.data.ruleViolationGraphData;
      // console.log(res.data.data)
      dataOfGraph.map((item) => {
        allMonthsData.push(item.field)
        lineCount.push(item.count)
        return item;
      })
      var today = new Date();
      var d;
      let lastSixMonthsData = [];
      let lastSixMonthsLineCount = [];

      for (var i = 0; i < 6; i += 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        lastSixMonthsData.push(allMonthsData[d.getMonth()] + ' ' + d.getFullYear());
        lastSixMonthsLineCount.push(lineCount[d.getMonth()]);
      }
      // let currentMonthData = new Date().getMonth();
      // let lastSixMonthsData = (allMonthsData.slice((currentMonthData + 1) - 6).concat(allMonthsData.slice(0, currentMonthData + 1)));
      // let lastSixMonthsLineCount = (lineCount.slice((currentMonthData + 1) - 6).concat(lineCount.slice(0, currentMonthData + 1)));
      // console.log(lastSixMonthsLineCount)
      this.setState({
        loading: false,
        ruleDetails: res.data.data,
        data: {
          labels: lastSixMonthsData.reverse(),
          datasets: [
            {
              label: "Rule Violation",
              fill: false,
              lineTension: 0.5,
              backgroundColor: "rgba(75,192,192,1)",
              borderColor: "#24476A",
              borderWidth: 3,
              data: lastSixMonthsLineCount.reverse(),
            },
          ],
        },
        totalRuleViolation: this.props.location?.state?.data[0]?.practiceId == 0 ? res.data.totalRuleViolationAllPractices : res.data.totalRuleViolation,
        particularRuleViolation: res.data.particularRuleViolation,
        percentViolation: this.props.location?.state?.data[0]?.practiceId == 0 ? res.data.percentViolationAllPractices : res.data.percentViolation,
        dataOfPieGraph: {
          datasets: [
            {
              label: "Rule Violation",
              data: [res.data.particularRuleViolation, (this.props.location?.state?.data[0]?.practiceId == 0 ? res.data.totalRuleViolationAllPractices : res.data.totalRuleViolation - res.data.particularRuleViolation)],
              backgroundColor: ["#5CB02C", "#E5E5E5"],
              hoverOffset: 4,
            },
          ],
        },
      })
    }).catch((err) => {
      return err
    })
  }

  componentDidMount() {
    this.getPractices();
    this.getCurrentEncounters();
    this.ruleViolationReports();
    this.setState({loading: true});
  }

  render() {
    const { heading, showCopyModal, showDeleteModal, practices, selectedMonth, triggeredEncounters, statusUpdateInfo, rpaData } = this.state;
    const { state } = this.props.location;
    const ruleDetails = state.data[0];
    // console.log("selectedMonth", selectedMonth);
    // console.log("id", rpaData);
    let practiceData = JSON.parse(sessionStorage.getItem("practice"));
    const roleDetails = JSON.parse(sessionStorage.getItem('userDetail'));
    let practiceIdForRolePermissions = this.props.location?.state?.data[0]?.practiceId;

    const createdDate = ruleDetails.created_at;
    const modifiedDate = ruleDetails.updated_at;
    // let date = DateConverter(createdDate);
    // console.log(DateConverter(ruleDetails.created_at));
    // console.log(new Date(createdDate));

    let client = JSON.parse(sessionStorage.getItem('clientDetail'));

    let tiles = ruleDetails.ruleInfo.filter(item => item.procedureTile);

    var months = new Array(12);
    months[0] = "January";
    months[1] = "February";
    months[2] = "March";
    months[3] = "April";
    months[4] = "May";
    months[5] = "June";
    months[6] = "July";
    months[7] = "August";
    months[8] = "September";
    months[9] = "October";
    months[10] = "November";
    months[11] = "December";

    let created_date = new Date(createdDate);
    let created_month_value = created_date.getMonth();
    let created_day_value = created_date.getDate();
    let created_year_value = created_date.getFullYear();
    let created_date_value = months[created_month_value] + " " + created_day_value + ", " + created_year_value;
    // console.log(created_date_value,'ss')
    let created_hours = created_date.getHours();
    let created_minutes = created_date.getMinutes();
    let created_ampm = created_hours >= 12 ? 'PM' : 'AM';
    created_hours = created_hours % 12;
    created_hours = created_hours ? created_hours : 12; // the hour '0' should be '12'
    created_minutes = created_minutes < 10 ? '0' + created_minutes : created_minutes;
    let createdTime = created_hours + ':' + created_minutes + ' ' + created_ampm;
    // console.log(createdTime);

    let modified_date = new Date(modifiedDate);
    let modified_month_value = modified_date.getMonth();
    let modified_day_value = modified_date.getDate();
    let modified_year_value = modified_date.getFullYear();
    let modified_date_value = months[modified_month_value] + " " + modified_day_value + ", " + modified_year_value;
    // console.log(modified_date_value)
    let modified_hours = modified_date.getHours();
    let modified_minutes = modified_date.getMinutes();
    let modified_ampm = modified_hours >= 12 ? 'PM' : 'AM';
    modified_hours = modified_hours % 12;
    modified_hours = modified_hours ? modified_hours : 12; // the hour '0' should be '12'
    modified_minutes = modified_minutes < 10 ? '0' + modified_minutes : modified_minutes;
    let modifiedTime = modified_hours + ':' + modified_minutes + ' ' + modified_ampm;
    // console.log(modifiedTime);

    let statusUpdatedBy = this.state?.statusUpdatedBy;
    let statusUpdatedAt = this.state?.statusUpdatedAt === null ? new Date(ruleDetails?.created_at).toLocaleString('en-US') : new Date(this.state?.statusUpdatedAt).toLocaleString('en-US')

    const headerStyle = {
      height: '45px',
      fontWeight: 'bold',
      backgroundColor: '#24476A',
      borderRadius: '9px'
    }
    return (
      <section>
        <Sidebar
          defaultDisabledValue={
            this.props.location?.state?.data[0]?.practiceId
          }
        />
        <div
          style={{
            marginTop: 60,
            width: "100%",
            height: 70,
            borderBottom: "1px solid lightgrey",
          }}
        >
          {/* <div className="iconBox">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(ruleId) => this.handleEdit(state.id)}
                >
                  <EditOutlinedIcon className="editIcon" />
                  <span className="iconText">Edit Rule</span>
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={this.handleCopyMaodal}
                >
                  <img src={copyRule} alt="copyRule" className="copyIcon" />
                  <span className="iconText">Copy Rule</span>
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(id) => this.handleStatus(ruleDetails.ruleId)}
                >
                  <img src={powerIcon} alt="ruleStatus" className="powerIcon" />
                  <span className="iconText">
                    Turn {this.state.ruleStatus === true ? "Off" : "On"}
                  </span>
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ showDeleteRulePopup: true })}
                >
                  <DeleteOutlineIcon className="deleteIcon" />
                  <span className="iconText">Delete</span>
                </span>
              </div> */}

          <div className="iconBox">
            <Tooltip
              title={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0') || (roleDetails.roleId === 4 && practiceIdForRolePermissions !== '0')) ? "" : "you don't have permission"}
              arrow
            >
              <IconButton disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0') || (roleDetails.roleId === 4 && practiceIdForRolePermissions !== '0')) ? false : true}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(ruleId) => this.handleEdit(state.id)}
                >
                  {/* <EditOutlinedIcon className="editIcon" />
                          <span className="iconText">Edit Rule</span> */}
                  <img src={editIcon} alt="editRule" className='editIcon' /><span className='iconText'>Edit Rule</span>
                </div>
              </IconButton>
            </Tooltip>

            <Tooltip
              title={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0')) ? "" : "you don't have permission"}
              arrow
            >
              <IconButton disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0')) ? false : true}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={this.handleCopyModal}
                >
                  <img src={copyRule} alt="copyRule" className="copyIcon" />
                  <span className="iconText">Copy Rule</span>
                </span>
              </IconButton>
            </Tooltip>

            <Tooltip
              title={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0') || roleDetails.roleId === 4) ? "" : "you don't have permission"}
              arrow
            >
              <IconButton disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0') || roleDetails.roleId === 4) ? false : true}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(id) => this.handleStatus(ruleDetails.ruleId)}
                >
                  <img src={powerIcon} alt="ruleStatus" className="powerIcon" />
                  <span className="iconText">
                    Turn {this.state.ruleStatus === true ? "Off" : "On"}
                  </span>
                </span>
              </IconButton>
            </Tooltip>

            <Tooltip
              title={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0')) ? "" : "you don't have permission"}
              arrow
            >
              <IconButton disabled={(roleDetails.roleId === 1 || roleDetails.roleId === 2 || (roleDetails.roleId === 3 && practiceIdForRolePermissions !== '0')) ? false : true}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ showDeleteRulePopup: true })}
                >
                  {/* <DeleteOutlineIcon className="deleteIcon" />
                          <span className="iconText">Delete</span> */}
                  <img src={deleteRule} alt="deleteRule" className='deleteIcon' /><span className='iconText'>Delete</span>
                </span>
              </IconButton>
            </Tooltip>
          </div>

          <RoAlert
            show={showCopyModal}
            handleClose={() => this.setState({ showCopyModal: false })}
            handleSubmit={this.copyRuleToPractice}
            title={"Copy Rule?"}
            type="info"
            content={
              <div className="alert-footer-text text-center">
                Which practice would you like to copy this rule to?<br />
                <select
                  onChange={(e) => this.setState({ copyPracticeId: e.target.value })}
                  className="p-1 mt-3 copyDrop text-center"
                >
                  <option value="">Select Practice</option>
                  {practices.filter(item => item.status).map((practice) => (
                    <option key={practice.id} value={practice.id} >{practice.practiceName}</option>
                  ))}
                </select><br />
                <span className='font-face-futura'>This action cannot be undone</span>
              </div>
            }
          />

          <RoAlert
            show={this.state.showDeleteRulePopup}
            handleClose={() =>
              this.setState({ showDeleteRulePopup: false })
            }
            handleSubmit={(id) => this.deleteRule(ruleDetails.ruleId)}
            title={"you are deleting a rule"}
            type="danger"
            content={
              <div className="alert-footer-text text-center">
                You are about to delete a rule for the practice:
                <br />
                <span className="font-face-futura">
                  <b>{practiceData.practiceName}</b>
                </span>
              </div>
            }
          />
        </div>

        <div
          className="mt-3 font-face-futura iconText"
          style={{ textAlign: "left", marginLeft: 50, fontSize: 24 }}
        >
          <a
            href="/manageRule"
            style={{ textDecoration: "none", color: "#5B5B5B" }}
          >
            Manage Rules
          </a>
          &nbsp;
          <span>
            <ArrowForwardIosIcon
              style={{ color: "#5CB02C", fontSize: 30 }}
            />{" "}
            {ruleDetails.ruleName}
          </span>
        </div>

        <div className="row mt-3" style={{ marginLeft: 20 }}>
          <div className="col-5 col-lg-5 col-sm-12">
            <Card className="detailCard">
              <CardHeader
                title={
                  <h5 className="pt-2 text-white font-face-futura">
                    Details
                  </h5>
                }
                style={headerStyle}
              />
              <CardContent>
                <div className="row font-face-futura">
                  <div className="col-6" style={{ fontSize: 18 }}>
                    <span>
                      <b>Rule Name:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {ruleDetails.ruleName}
                    </p>

                    <span>
                      <b>Rule:</b>
                    </span>
                    <p className='font-face-futura-rule'>When the {' '}
                      {ruleDetails.ruleInfo.map((item, idx) => {
                        let error = "{{error}}";
                        let finalValues, procedureValues;
                        let procedureTileValue;
                        // console.log(item.values)
                        switch (item?.category) {
                          case 'Diagnosis 1':
                          case 'Diagnosis 2':
                          case 'Diagnosis 3':
                          case 'Diagnosis 4':
                          case 'Diagnosis in any slot':
                          case 'Modifier':
                          case 'Total Charges':
                          case 'Units':
                          case 'Unit Charge':
                            procedureTileValue = item.procedureTile === 'any' ? 'for any procedure' : `for procedure tile ${item?.procedureTile}`
                            break;
                          default:
                            procedureTileValue = ""
                            break;
                        }
                        switch (item?.category) {
                          case 'Diagnosis 1':
                          case 'Diagnosis 2':
                          case 'Diagnosis 3':
                          case 'Diagnosis 4':
                          case 'Diagnosis in any slot':
                          case 'Procedure Code':
                            finalValues = (item?.condition === 'is' || item?.condition === 'contains') ?
                              (item?.values?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' or ') :
                              (item?.condition === 'is missing') ? [] :
                                (item?.values?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' and ');
                            break;
                          default:
                            finalValues = (item?.condition === 'is' || item?.condition === 'contains') ?
                              (item?.values?.map(val => val.name ? val.name + ' ' + ((item.category === 'Primary Insurance Plan' || item.category === 'Secondary Insurance Plan') ? `(${val.id})` : (item.category === 'Scheduling Provider' || item.category === 'Rendering Provider' || item.category === 'Referring Provider') ? `(${val.providerId})` : '') : val))?.join(' or ') :
                              (item?.condition === 'is missing') ? [] :
                              (item?.values?.map(val => val.name ? val.name + ' ' + ((item.category === 'Primary Insurance Plan' || item.category === 'Secondary Insurance Plan') ? `(${val.id})` : (item.category === 'Scheduling Provider' || item.category === 'Rendering Provider' || item.category === 'Referring Provider') ? `(${val.providerId})` : '') : val))?.join(' and ');
                            break;
                          // console.log(finalValues)
                        }
                        switch (item?.category) {
                          case 'Global Period':
                            finalValues = (item?.values?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' or ');
                            procedureValues = (item?.procedureArray?.map(val => val.name ? val.name.split('-')[0].trim() : val))?.join(' or ');
                            break;
                          default:
                            break;
                        }
                        return (<>
                          {item?.category === 'Global Period' ?
                            <>
                              <span>{item?.category}</span>{' '}
                              <span>for the procedure(s)</span>{' '}
                              <span style={{ color: '#297200' }}>{finalValues}</span>{' '}
                              <span>is</span>{' '}
                              {item?.days && (<span style={{ color: '#297200' }}>{item?.days}{' '}</span>)}
                              <span>{(item?.days === '0' || item.days === '1') ? 'day' : 'days'},</span>{' '}
                              <span>{item?.globalDrop}</span>{' '}
                              <span>we cannot bill the procedure(s)</span>{' '}
                              <span style={{ color: '#297200' }}>{procedureValues}{' '}</span>
                            </> :
                            <>
                              <span>{(idx > 0 || tiles.length > 0 ? ' the ' + item?.category + ' ' + procedureTileValue : item?.category + ' ' + procedureTileValue) + ' ' + item?.condition}</span>{' '}
                              <span style={{ color: (finalValues.length === 0 && item?.condition !== 'is missing') ? '#954437' : '#535353' }}>
                                {((finalValues && finalValues.length !== 0) || item?.condition === 'is missing') ? finalValues : error}
                              </span>{' '}
                            </>
                          }
                          <span>{(item?.operator).toLowerCase()}</span>
                        </>)
                      })} {ruleDetails.result === 'Run RPA Flow' ? ruleDetails?.result + ' ' + `[${rpaData?.rpa_flow_Name}]` : ruleDetails?.result}.
                    </p>

                    {ruleDetails.result === 'pend to review tab' && <><span>
                      <b>Reason:</b>
                    </span>
                      <p className="font-face-futura-rule">
                        {ruleDetails.Reason}
                      </p></>}

                    {ruleDetails.result === 'Run RPA Flow' && <>
                      <span><b>Action:</b></span>
                      <p>
                        <span style={{ fontSize: 16 }}><b>Action Taken:</b> {ruleDetails.result}</span><br />
                        <span style={{ fontSize: 16 }}><b>RPA Flow Name:</b> {rpaData?.rpa_flow_Name}</span><br />
                        {/* <span style={{ fontSize: 16 }}><b>Verify Authorization Line Note:</b> {rpaData?.verify_authorization === true ? 'True' : 'False'}</span><br />
                        <span style={{ fontSize: 16 }}><b>Operator:</b> </span><br />
                        <span style={{ fontSize: 16 }}><b>Auth Note Field:</b> </span><br /> */}
                        <span style={{ fontSize: 16 }}><b>Add Batch Message if there is an error?:</b> {rpaData?.batch_status === true ? 'True' : 'False'}</span><br />
                        <span style={{ fontSize: 16 }}><b>Batch Message:</b> {rpaData?.batch_message}</span><br />
                        <span style={{ fontSize: 16 }}><b>Add Error Note if there is an error?:</b> {rpaData?.error_status === true ? 'True' : 'False'}</span><br />
                        <span style={{ fontSize: 16 }}><b>Error Message:</b> {rpaData?.error_message}</span>
                      </p>
                      {/* <span><b>Result:</b></span>
                      <p>
                        <span style={{ fontSize: 14 }}><b>RPA Result:</b> </span><br />
                        <span style={{ fontSize: 14 }}><b>RPA Start:</b> </span><br />
                        <span style={{ fontSize: 14 }}><b>RPA End:</b> </span>
                      </p> */}
                    </>}

                    <span>
                      <b>Tags:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {ruleDetails.tags.join(", ")}
                    </p>
                  </div>
                  <div className="col-6" style={{ fontSize: 18 }}>
                    <Tooltip arrow title={`Turned ${this.state.ruleStatus === true ? "On" : "Off"} by: ${statusUpdatedBy} on ${statusUpdatedAt}`}>
                      <div>
                        <span>
                          <b>Status:</b>
                        </span>
                        <p className="font-face-futura-rule">
                          {this.state.ruleStatus === true ? "On" : "Off"}
                        </p>
                      </div>
                    </Tooltip>

                    <span>
                      <b>Created By:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {ruleDetails.createdBy}
                    </p>

                    <span>
                      <b>Created On:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {created_date_value} &nbsp;{createdTime}
                    </p>

                    <span>
                      <b>Modified On:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {modified_date_value} &nbsp;{modifiedTime}
                    </p>

                    <span>
                      <b>Modified By:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {ruleDetails.updatedBy}
                    </p>

                    <span>
                      <b>Version:</b>
                    </span>
                    <p className="font-face-futura-rule">
                      {ruleDetails.version}
                    </p>
                    <p>{ }</p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="col-7 col-lg-7 col-sm-12">
            <Card className="detailCard w-90 h-100">
              <CardHeader
                title={
                  <h5 className="pt-2 text-white font-face-futura">
                    Analytics
                  </h5>
                }
                style={headerStyle}
              />
              <CardContent className='h-100'>
                {this.state.loading ? <Loader
                        className="d-flex justify-content-center align-items-center h-100"
                        type="TailSpin"
                        color="#10496a"
                        height={50}
                        width={50}
                      /> : <div className="row ">
                  <div className="col-lg-8 col-sm-12 line_chart mt-5 text-center">
                    <LineChart data={this.state.data} />
                    <span className="font-face-futura">Rule Violation: 6 Months</span>
                  </div>
                  <div className="col-lg-4 col-sm-12 block_center_chart text-center">
                    <PieChart
                      data={this.state.dataOfPieGraph}
                      percentViolation={this.state.percentViolation}
                    />
                    <span className="font-face-futura">Rule Violations: {this.state.particularRuleViolation}</span><br />
                    <span className="font-face-futura">All Practice Violations: {parseInt(this.state.totalRuleViolation)}</span>
                  </div>
                </div>}
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12 col-lg-12 col-sm-12 font-face-futura">
            <h5 className="text-white trigDiv">Triggered Encounters</h5>
            <select
              value={selectedMonth}
              onChange={this.handleMonth}
              className="trigSelect copyDrop1"
            >
              {lastSixMonths.map((month) => (
                <option value={month.split(" ")[0]}>{month}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-3 mb-3 table-responsive tabManager denial-style font-face-futura">
          <table className="denial-table">
            <thead>
              <tr>
                {heading.map((head) => (
                  <th className="text-center text-white py-2">{head}</th>
                ))}
                {client?.RPAEnabled && <th className="text-center text-white">RPA Status</th>}
                <th className="text-center text-white py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {triggeredEncounters.map((trig, idx) => {
                let patientName = trig.PatientName;
                patientName = patientName
                  .toLowerCase()
                  .replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  });
                let providerName = trig.SchedulingProviderName;
                providerName = providerName
                  .toLowerCase()
                  .replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  });
                let primary = trig.PrimaryInsurancePlanName;
                primary = primary
                  .toLowerCase()
                  .replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  });
                let secondary = trig.SecondaryInsurancePlanName;
                secondary = secondary
                  .toLowerCase()
                  .replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  });
                let location = trig.ServiceLocationName;
                location = location
                  .toLowerCase()
                  .replace(/\b[a-z]/g, function (letter) {
                    return letter.toUpperCase();
                  });
                return (
                  <tr key={idx} className="table-row">
                    <td className="text-center p-0 font-face-futura">
                      <div
                        className="innerdata"
                        style={{
                          borderRadius: "9px 0px 0px 9px",
                          marginLeft: 20,
                        }}
                      >
                        <span
                          title={trig.PatientName}
                          className="one-line-ellipsis"
                        >
                          {patientName}
                        </span>
                      </div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">{trig.EncounterID}</div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        <span
                          title={trig.SchedulingProviderName}
                          className="one-line-ellipsis"
                        >
                          {providerName}
                        </span>
                      </div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        {trig.ServiceStartDate}
                      </div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">{trig.ProcedureCode}</div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        <span
                          title={trig.PrimaryInsurancePlanName}
                          className="one-line-ellipsis"
                        >
                          {primary}
                        </span>
                      </div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        <span
                          title={trig.SecondaryInsurancePlanName}
                          className="one-line-ellipsis"
                        >
                          {secondary}
                        </span>
                      </div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        <span
                          title={trig.ServiceLocationName}
                          className="one-line-ellipsis"
                        >
                          {location}
                        </span>
                      </div>
                    </td>
                    <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        {trig.EncounterDiagnosisID1}
                      </div>
                    </td>
                    {client?.RPAEnabled && <td className="text-center p-0 font-face-futura">
                      <div className="innerdata">
                        Could not complete action
                      </div>
                    </td>}
                    <td className="text-center p-0">
                      <div
                        className="innerdata"
                        style={{
                          borderRadius: "0px 9px 9px 0px",
                          marginRight: 10,
                        }}
                      >
                        <img
                          src={openInNew}
                          alt="open"
                          style={{ cursor: "pointer" }}
                          onClick={(id) => this.handleRow(trig.instanceID)}
                        />
                        {/* <OpenInNewIcon
                                                style={{ color: '#5CB02C', cursor: 'pointer', fontSize: 20 }}
                                                onClick={(id) => this.handleRow(trig.instanceID)} /> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <Table className='tablerounededCorner'>
                        <TableHead>
                            <TableRow>
                                {heading.map((head) => (
                                    <TableCell className="text-center text-white">{head}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {triggeredEncounters.map((trig, idx) => (
                            <TableRow>
                                <TableCell className="text-center text-white">{trig.PatientName}</TableCell>
                                <TableCell className="text-center text-white">{trig.EncounterID}</TableCell>
                                <TableCell className="text-center text-white">{trig.SchedulingProviderName}</TableCell>
                                <TableCell className="text-center text-white">{trig.ServiceStartDate}</TableCell>
                                <TableCell className="text-center text-white">{trig.ProcedureCode}</TableCell>
                                <TableCell className="text-center text-white">{trig.PrimaryInsurancePlanName}</TableCell>
                                <TableCell className="text-center text-white">{trig.SecondaryInsurancePlanName}</TableCell>
                                <TableCell className="text-center text-white">{trig.ServiceLocationName}</TableCell>
                                <TableCell className="text-center text-white">{trig.EncounterDiagnosisID1}</TableCell>
                                <TableCell className="text-center">
                                    <OpenInNewIcon 
                                    style={{color: '#2eb82e', cursor: 'pointer'}}
                                    onClick={(id) => this.handleRow(trig.EncounterID)} />
                                </TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table> */}
        </div>
      </section>
    );
  }
}
export default withRouter(RuleDashboard);