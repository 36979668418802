import { Card, CardContent, CardHeader } from "@material-ui/core";
import BarChart from "layouts/BarChart";
import React, { Component } from "react";
import Sidebar from "../Sidebar";
import "./dashboard.css";
import { autoRun } from "./dashBoardData";
import { dateRange } from "./dashBoardData";
import DashBoardChart from "./DashBoardChart";
import API from "../../Api/index";
import LineChart from "layouts/LineChart";
import PracticeSelect from "../Sidebar/PracticeSelect.jsx";

/**
 * set global varible to filter initially data for "month to date"
 */
const date = new Date();
const today = new Date().toISOString().substring(0, 10);
var initialDate = today;
var finalDate = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substring(0, 10);

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      practiceData: [],
      customSetPractice: () => { },
      valueOfdateRange: '',
      dataofGraph: {
        labels: [],
        datasets: [
          {
            label: "NotReviewed",
            data: [],
            backgroundColor: "#5CB02C",
          },
        ],
      },
      dataOfProcessedClaims: {
        labels: [],
        datasets: [
          {
            label: "Claims",
            data: [],
            borderColor: "#5CB02C",
            backgroundColor: "#5CB02C",
            borderWidth: 3,
            pointRadius: 5,
          },
        ],
      },
    };
  }

  /**
   * @returns function to handle practice data with status "on" and "off"
   */
  fetchPractice = () => {
    API.get("allPractices")
      .then((res) => {
        this.setState({ practiceData: res.data.data.filter(practice => practice.status) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * function to handle bar graph of practice reviewed in workload
   */
  getWorkloadReviewed = () => {
    API.get("getPracticeReviewed")
      .then((res) => {
        let reviewedCount = [];
        let practiceReviewed = [];
        res.data.practiceReviewed.map((reviewed) => {
          reviewedCount.push(reviewed.count);
          practiceReviewed.push(reviewed.practiceAcronym);
        });
        this.setState({
          dataofGraph: {
            labels: practiceReviewed,
            datasets: [
              {
                label: "NotReviewed",
                data: reviewedCount,
                backgroundColor: "#5CB02C",
              },
            ],
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * function to set practice change in sesssion storeg
   * on change in dropdown
   * @param {*} practice
   */
  savePracticeLocally = (practice) => {
    sessionStorage.setItem("practice", JSON.stringify(practice));
  };

  getSelectedPracticeLocally = () => {
    const practice = sessionStorage.getItem("practice");
    if (practice) {
      return JSON.parse(practice);
    }
  };

  /**
   * function to handle practice change in the dropdown to filter
   * processd claims line graph
  //  * @param {*} event
  //  */
  // handlePracticeChange = (event) => {
  //   const selectedPracticeId = event.target.value;
  //   const selectedPractice = (this.state.practiceData || []).find(
  //     (practice) => practice.id == selectedPracticeId
  //   );
  //   if (selectedPractice && selectedPractice.id) {
  //     this.savePracticeLocally(selectedPractice);
  //     // this.setState({customSetPractice:selectedPractice})
  //   }
  //   this.getProcessedClaimsGraph();
  // };

  /**
   * function to handle the date range change and get the required date
   * which is send in body of getProcessedClaimsGraph method
   * @param {*} e
   */
  handleDateRangeGraph = (e) => {
    let value = e.target.value;
    this.setState({ valueOfdateRange: value })
    const date = new Date();
    const today = new Date().toISOString().substring(0, 10);
    switch (value) {
      case "month_to_date":
        if (value === "month_to_date") {
          const month_date = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substring(0, 10);
          initialDate = today;
          finalDate = month_date;
        }
        break;
      case "year_to_date":
        if (value === "year_to_date") {
          const Year_to_date = new Date(date.getFullYear(), 0, 2).toISOString().substring(0, 10);
          initialDate = today;
          finalDate = Year_to_date;
        }
        break;
      default:
    }
    this.getProcessedClaimsGraph();
  };

  getDataForSelectedPractice = () => {
    let practice;
    let data = setInterval(() => {
      practice = sessionStorage.getItem("practice")
      if (practice) {
        this.getProcessedClaimsGraph();
        clearInterval(data)
      }
    }, 500)
  }

  /**
   * function to get line graph data by sending date range to filter and practice also
   */
  getProcessedClaimsGraph = () => {
    let body_data = {
      dateRange: {
        fromDate: initialDate,
        toDate: finalDate,
      },
    };
    API.post("getProcessedClaims", body_data)
      .then((claims) => {
        let claimsDate = [];
        let claimsCount = [];
        let modifiedDate = [];
        claims.data.processedClaims.map((dataOfClaims) => {
          claimsDate.push(dataOfClaims.currentDate);
          claimsCount.push(dataOfClaims.count);
        });
        for (var i = 0; i < claimsDate.length; i++) {
          let date = new Date(claimsDate[i])
          let d = date.getDate();
          let m = date.getMonth() + 1;
          let finalDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d);
          modifiedDate.push(finalDate)
        }
        this.setState({
          dataOfProcessedClaims: {
            labels: modifiedDate,
            datasets: [
              {
                label: "Claims",
                data: claimsCount,
                borderColor: "#5CB02C",
                backgroundColor: "#5CB02C",
                borderWidth: 3,
                pointRadius: 5,
              },
            ],
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    initialDate = today;
    finalDate = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substring(0, 10);
    this.getWorkloadReviewed();
    this.fetchPractice();
    this.getDataForSelectedPractice();
    //  this.getProcessedClaimsGraph();
  }

  render() {
    const { practiceData, dataofGraph, dataOfProcessedClaims } = this.state;
    return (
      <>
        <section style={{ backgroundColor: "white" }}>
          <Sidebar
            setSidebarDisable={true}
            customSetPractice={(customSetPractice) => {
              this.setState({ customSetPractice: customSetPractice });
            }}
            defaultDisabledValue={
              practiceData.id
            }
          />

          <div className="sectionlayoutcontainer">
            <span className="dashboardText mb-2 w-100 h-auto">Dashboard</span>                    
              <div className="row ">
                <div className="col-lg-6 col-sm-6 mb-3">
                  <Card className="workLoadCard">
                    <CardHeader
                      className="workLoadHead"
                      title={
                        <h5 className="text-white font-face-futura">
                          Workload
                        </h5>
                      }
                    ></CardHeader>
                    <div className="dashbaordsection-layout">
                      <div className="barChartWorkload text-white font-face-futura">
                        Not Reviewed
                      </div>
                      <div className="bar-chart-Content">
                        <BarChart data={dataofGraph} practiceData={practiceData} />
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-lg-6 col-sm-6 mb-3">
                  <Card className="autorunCard">
                    <CardHeader
                      className="workLoadHead"
                      title={
                        <h5 className="text-white font-face-futura">
                          Auto-Run
                        </h5>
                      }
                    ></CardHeader>
                    <CardContent className="dashbaordsection-layout">
                      <div className="table-responsive manageAutoRun">
                        <table className="tableAutorun">
                          <thead>
                            <tr>
                              {autoRun.map((col, index) => (
                                <th
                                  className="text-white font-face-furura"
                                  key={index}
                                >
                                  {col.name}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {practiceData.filter(x => x.status === true).map((practice) => (
                              <tr
                                key={practice.id}
                                className="table-row">
                                <td style={{ textAlign: "center" }}>
                                  <div className="innerdata">
                                    <span
                                      title=""
                                      className="one-line-ellipsis"
                                    >
                                      {practice.practiceName}
                                    </span>
                                  </div>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <div className="innerdata">
                                    <span
                                      title=""
                                      className="one-line-ellipsis"
                                    >
                                      {practice.autoRunRules === true
                                        ? "On"
                                        : "Off"}
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardContent>
                  </Card>
                </div>
          <div className="col">
            <Card className="processCard">
              <CardHeader
                className="processCardHead"
                title={
                  <h5 className="text-white font-face-futura">
                    Processed Volume
                  </h5>
                }
              />
              <CardContent className="dashbaordsection-layout">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">

                    <div className="d-flex align-items-center gap-4">
                    <label
                      htmlFor="volume"
                      className="text-dark font-face-futura"
                    >
                      Practice:
                    </label>

                    {/* <select
                      className="practiceDropdown text-center"
                      onChange={this.handlePracticeChange}
                      defaultValue={practice.practiceName}
                      value={practice.id}
                    >
                      {practiceData.map((practice) => (
                        <option value={practice.id} key={practice.id}>
                          {practice.practiceName}
                        </option>
                      ))}
                    </select> */}

                    <PracticeSelect
                      setSidebarDisable={false}
                      handleChangePractice={(practice) => {
                        this.getProcessedClaimsGraph()
                        this.state.customSetPractice(
                          practice.id ? practice.id : practice
                        );
                      }}
                    />
                    </div>
                  </div>
                  <div className="col-auto">
                  <div className="d-flex align-items-center gap-4">
                    <label
                      htmlFor="dateRange"
                      className="text-dark font-face-futura"
                    >
                      Date Range:
                    </label>
                    <select
                      className="dateRangeSelct text-center"
                      id="dateRange"
                      onChange={this.handleDateRangeGraph}
                      defaultValue={'month_to_date'}
                    >
                      {dateRange.map((date) => (
                        <option key={date.value} value={date.key}>
                          {date.value}
                        </option>
                      ))}
                    </select>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="mt-3 processedClaims text-white font-face-futura">
                  Processed Claims
                </div>
                  <div className="chartLine">
                    <LineChart data={dataOfProcessedClaims} />
                    {/* <DashBoardChart/> */}
                </div>
              </CardContent>
            </Card>
          </div>
              </div>
          </div>
    
       
        </section>
      </>
    );
  }
}

export default Dashboard;