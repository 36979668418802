export const systemAdminDo = ["Everything"];
export const systemAdminDont = ["Nothing"];
export const ruleCretorDo = [
  "Run rules",
  "View rules",
  "Link payers",
  "Create/Edit All-Practice Rules",
  "Create/Edit Practice-Specific",
  "Create/Edit Groups",
  "Manage Practice Information",
  "View Reports",
  "Remove Denail Action"
];
export const ruleCretorDont = [
  "Add Practices",
  "Add Providers",
  "Edit Practice Settings & Logins",
  "Manage Users",
  // "View Reports",
];
export const ruleLimitedDo = [
  "Run rules",
  "View rules",
  "Link payers",
  "Create/Edit Practice-Specific Rules Only",
  "Create/Edit Groups",
  "View Reports",
  // "Manage Practice Information",
];
export const ruleLimitedDont = [
  "Create/Edit All-Practice Rules",
  "Add Practices",
  "Add Providers",
  "Edit Practice Settings & Logins",
  "Manage Users",
  "No Response", 
  "Denial Management"
  // "View Reports",
];
export const ruleEditDo = [
  "Edit Practice-Specific Rules Only",
  "Edit Groups",
  "Run rules",
  "View rules",
  "Link payers",
  "View Reports",
];
export const ruleEditDont = [
  "Create/Edit All-Practice Rules",
  "Create Practice-Specific Rules Only",
  "Create Groups",
  "Manage Practice Information",
  "Add Practices",
  "Add Providers",
  "Edit Practice Settings & Logins",
  "Manage Users",
  "No Response", 
  "Denial Management"
  // "View Reports",
];
export const claimScrubberDo = ["Run rules", "View rules", "Link payers", "View Reports"];
export const claimScrubberDont = [
  "Create/Edit All-Practice Rules",
  "Create/Edit Practice-Specific Rules Only",
  "Create/Edit Groups",
  "Manage Practice Information",
  "Add Practices",
  "Add Providers",
  "Edit Practice Settings & Logins",
  "Manage Users",
  "No Response", 
  "Denial Management"
  // "View Reports",
];

export const arRepresentativeDo = ["Run rules", "View rules", "Link payers", "View Reports", "No Response", "Denial Management"]

export const arRepresentativeDont = [
  "Create/Edit All-Practice Rules",
  "Create/Edit Practice-Specific Rules Only",
  "Create/Edit Groups",
  "Manage Practice Information",
  "Add Practices",
  "Add Providers",
  "Edit Practice Settings & Logins",
  "Manage Users",
];

export const denialRepresentativeDo = ["Run rules", "View rules", "Link payers", "View Reports", "Denial Management"]

export const denialRepresentativeDont = [
  "Create/Edit All-Practice Rules",
  "Create/Edit Practice-Specific Rules Only",
  "Create/Edit Groups",
  "Manage Practice Information",
  "Add Practices",
  "Add Providers",
  "Edit Practice Settings & Logins",
  "Manage Users",
  "No Response",
];