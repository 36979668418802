import React, { useState } from "react";
import clsx from "clsx";
import {
  Drawer,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Menu, MenuItem, Typography, Box } from "@mui/material";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { navItems, useStyles } from "./constants";
import { NavLink, withRouter } from "react-router-dom";
import API from "../../Api";
import "./sidebar.css";
import logo2 from "../../assets/icons/logo2.svg";
import AppHeader from "./Header";
import { getDataFromSession } from '../helper/helper';

const SideBar = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [signOut, setSignOut] = useState(false);
  const [practice, setPractice] = useState("");
  const [practiceList, setPracticeList] = useState([]);
  const [modifiedNavItem, setModifiedNavItem] = useState([]);
  const { handleChangePractice, hasAllPracticeOption, defaultDisabledValue, customSetPractice, refreshPractice, handleRuleProcesserButton, setSidebarDisable, handlePracticeChangeByNotReviewPage, isNavigateFromDashborad } = props;

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const handleChange = (event) => setPractice(event.target.value);

  const onSignOut = (event) => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("denialFilter");
    setSignOut(true);
  };

  if (signOut) {
    props.history.push("/");
  }

  let path = window.location.pathname;

  React.useEffect(() => {
    let currentPractice = getDataFromSession('practice');
    API.get("allPractices")
      .then(async (response) => {
        setPracticeList(response.data.data);
        if (path === "/rule-processor") {
          if ("caches" in window) {
            const cacheStorage = await caches.open("navigateBack");
            const cachedResponse = await cacheStorage.match("goReverse");
            if (cachedResponse) {
            } else if (isNavigateFromDashborad) {
              return;
            } else {
              props.getEncounters(currentPractice?.id);
            }
          }
          props.getAutoRunRulesStatus(currentPractice?.autoRunRules);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    if (
      path === "/rule-processor" ||
      path === "/inspection-noviolation" ||
      path === "/runRules" ||
      path === "/insurances/true" ||
      path === "/insurances/false"
    ) { } else {
      if ("caches" in window) {
        caches.delete("navigateBack").then(function (res) {
          return res;
        });
      }
    }
  }, []);

  const handleList = () => {
    if ("caches" in window) {
      caches.delete("navigateBack").then(function (res) {
        return res;
      });
    }
    if (path === "/rule-processor") {
      handleRuleProcesserButton()
    }
  };

  const handleNavItemOnBehalfOfRole = () => {
    let roleData = JSON.parse(sessionStorage.getItem('userDetail'));
    let navItem = navItems;
    switch (roleData.role_Name) {
      case "Sytem Administrator":
        setModifiedNavItem(navItems)
        break;
      case "Claim Scrubber":
        let claimData = [];
        navItem.map((val) => {
          if (val.title === "Insurance" || (val.title === "Manage Rule" && val.options?.title != "Create Scrubbing Rule") ||
          (val.title === "Rule Processor" && (val.options?.title != "Denial Management" || val.options?.title != "No Response Mangement")) || val.title === "Dashboard" ||
            val.title === "Reports" || val.title === "Settings") {
            claimData.push({ title: val.title })
          }
        })
        let CliamResult = claimData.map((item) => {
          let obj = navItem.find((o) => o.title === item.title);
          return { ...obj, ...item }
        })
        setModifiedNavItem(CliamResult)
        break;
      case "Rule Editor":
        let editorData = [];
        navItem.map((val) => {
          if (val.title === "Insurance" || (val.title === "Manage Rule" && val.options?.title != "Create Scrubbing Rule") ||
            val.title === "Rule Processor" || val.title === "Dashboard" ||
            val.title === "Reports" || val.title === "Settings") {
            editorData.push({ title: val.title })
          }
        })
        let EditorResult = editorData.map((item) => {
          let obj = navItem.find((o) => o.title === item.title);
          return { ...obj, ...item }
        })
        setModifiedNavItem(EditorResult)
        break;
      case "AR Representative":
        let arData = [];
        navItem.map((val) => {
          if (val.title === "Insurance" || (val.title === "Manage Rule" && val.options?.title != "Create Scrubbing Rule") ||
          (val.title === "Rule Processor" && val.options?.title != "Claim Scrubbing") || val.title === "Dashboard" ||
            val.title === "Reports" || val.title === "Settings") {
            arData.push({ title: val.title })
          }
        })
        let arResult = arData.map((item) => {
          let obj = navItem.find((o) => o.title === item.title);
          return { ...obj, ...item }
        })
        setModifiedNavItem(arResult)
        break;
      case "Denial Representative":
        let denData = [];
        navItem.map((val) => {
          if (val.title === "Insurance" || (val.title === "Manage Rule" && val.options?.title != "Create Scrubbing Rule") ||
            (val.title === "Rule Processor" && (val.options?.title != "Claim Scrubbing" || val.options?.title != "No Response Mangement")) || val.title === "Dashboard" ||
            val.title === "Reports" || val.title === "Settings") {
            denData.push({ title: val.title })
          }
        })
        let denResult = denData.map((item) => {
          let obj = navItem.find((o) => o.title === item.title);
          return { ...obj, ...item }
        })
        setModifiedNavItem(denResult)
        break;
      default: setModifiedNavItem(navItems)
        break;
    }
  }

  React.useEffect(() => {
    handleNavItemOnBehalfOfRole()
  }, [])

  return (
    <section>
      <div className={classes.root}>
        {/* <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div>
            {open ? (
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  <MenuIcon className="text-white" />
                </IconButton>
              </div>
            ) : (
              <div className="mt-2 ml-2">
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx({
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon className="text-white" />
                </IconButton>
              </div>
            )}
          </div>
          <Divider />
          <List className="h-100">
            {modifiedNavItem.map((navItem, index) => (
              <NavLink
                to={navItem.path}
                style={{ textDecoration: "none" }}
                key={index}
                onClick={() => handleList()}
              >
                <ListItem
                  className="text-white mt-2 mb-2"
                  button
                  key={navItem.title}
                >
                  <ListItemIcon style={{ marginLeft: 6 }}>
                    {navItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={navItem.title} />
                </ListItem>
              </NavLink>
            ))}

            <ListItem className="text-white pos-ab" button>
              <ListItemIcon style={{ marginLeft: 6 }}>
                <img src={logo2} alt="logo2" />
              </ListItemIcon>
            </ListItem>
            <ListItem
              className="text-white pos-logo"
              button
              onClick={onSignOut}
            >
              <ListItemIcon
                style={{ color: "white", opacity: 0.4, marginLeft: 8 }}
              >
                <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </Drawer> */}

        <div>
          <CssBaseline />
          <AppHeader
            handleChangePractice={handleChangePractice}
            handlePracticeChangeByNotReviewPage={handlePracticeChangeByNotReviewPage}
            hasAllPracticeOption={hasAllPracticeOption}
            classes={classes}
            path={props.path}
            practiceList={practiceList}
            open={open}
            chargesFunc={props.chargesFunc}
            handleChange={handleChange}
            practice={practice}
            defaultDisabledValue={defaultDisabledValue}
            setSidebarDisable={setSidebarDisable}
            customSetPractice={customSetPractice}
            refreshPractice={refreshPractice}
            navItems={modifiedNavItem}
            handleList={handleList}
            handleLogout={onSignOut}
          />
        </div>
      </div>
    </section>
  );
};

export default withRouter(SideBar);
