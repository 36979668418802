import React from "react";
import clsx from "clsx";
import { AppBar, List, ListItem, ListItemIcon } from "@material-ui/core";
import { Menu, MenuItem, IconButton } from "@mui/material";
import "./sidebar.css";
import RuleOutLogo from "../../assets/icons/RuleOutLogo.svg";
import PracticeSelect from "./PracticeSelect";
import { NavLink, withRouter } from "react-router-dom";


import DashBoardIcon from "../../assets/icons/dashboard.svg"
import RuleProcessorIcon from "../../assets/icons/rule-processor.png";
import CreateRuleIcon from "../../assets/icons/create-rule.png";
import ManageRulesIcon from "../../assets/icons/manage-rules.png";
import InsurancesIcon from "../../assets/icons/insurances.png";
import ReportsIcon from "../../assets/icons/reports.png";

const AppHeader = ({
  classes,
  path,
  handleChange,
  practice,
  practiceList,
  open,
  handleChangePractice,
  handlePracticeChangeByNotReviewPage,
  hasAllPracticeOption,
  defaultDisabledValue,
  setSidebarDisable,
  customSetPractice,
  refreshPractice,
  ...props
}) => {

  const [userRoleData, setUserRoleData] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };
  const [rpEl, setRpEl] = React.useState(null);
  const [mrEl, setMrEl] = React.useState(null);
  const openRPMenu = Boolean(rpEl);
  const openMRMenu = Boolean(mrEl);
  const handleRPClick = (event) => {
    // console.log(event.target)
    setRpEl(event.currentTarget);
  };
  const handleMRClick = (event) => {
    // console.log(event.target)
    setMrEl(event.currentTarget);
  };
  const handleRPClose = () => {
    setRpEl(null);
  }
  const handleMRClose = () => {
    setMrEl(null);
  }

  React.useEffect(() => {
    const sessionValue = JSON.parse(sessionStorage?.getItem('userDetail'))
    setUserRoleData(sessionValue)
  }, [])

  let navlist = (nav) => {
    return <NavLink to={nav.path} key={nav.title} style={{ textDecoration: "none" }}>
      <MenuItem key={nav.title} onClick={() => props.handleList()}>
        <span className="navlinkTxt">{nav.title}</span>
      </MenuItem>
    </NavLink>
  }

  return (
    <div>
      <AppBar position="fixed"
      // position="fixed"
      // className={clsx(classes.appBar, {
      //   [classes.appBarShift]: open,
      // })}
      >
        <header
          className={classes.logo}
          style={{ backgroundColor: "#24476A", height: 75, display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div className="ml-30">
            <img
              src={RuleOutLogo}
              alt="logo"
              width={160}
              style={{ height: 75, paddingTop: 6 }}
            />
            <List className="nav custom-nav">
              {props.navItems.map((navItem, index) => (
                (!navItem.isDropDown ? (<NavLink
                  to={navItem.path}
                  style={{ textDecoration: "none" }}
                  key={index}
                  onClick={() => props.handleList()}
                >
                  <ListItem
                    className="text-white navIconmod"
                    button
                    key={navItem.title}
                  >
                    <div className="abx" >
                      {navItem.icon}
                    </div>
                  </ListItem>
                </NavLink>) : (
                  <div className="custom-dropdown">
                    <ListItem
                      className="text-white navIconmod custom-list-item" button
                      id="basic-button"
                      // aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      // aria-expanded={openMenu ? 'true' : undefined}
                      onClick={navItem.title === 'Rule Processor' ? handleRPClick : handleMRClick}
                    >
                      <div className="abx">
                        {navItem.icon}
                      </div>
                    </ListItem>
                    <Menu
                      id="basic-menu"
                      anchorEl={navItem.title === 'Rule Processor' ? rpEl : mrEl}
                      open={navItem.title === 'Rule Processor' ? openRPMenu : openMRMenu}
                      onClose={navItem.title === 'Rule Processor' ? handleRPClose : handleMRClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                      className="headericon-dropdown"
                    >
                      <div className="mx-2 font-face-futura font18 navDiv nav-menu-header">
                        <span>{navItem.title === 'Rule Processor' ? 'Rule Processor' : 'Rule Management'}</span>
                      </div>
                      <div className="botDiv"></div>
                      {navItem.title === 'Rule Processor' ?
                        <>{userRoleData.roleId == '1' || userRoleData.roleId == '2' || userRoleData.roleId == '3' || userRoleData.roleId == '4' ?
                          <>
                            {navlist(navItem?.options[0])}
                            {navlist(navItem?.options[1])}
                            {navlist(navItem?.options[2])}
                          </>
                          : userRoleData.roleId == '5' ?
                            <>
                              {navlist(navItem?.options[0])}
                            </>
                            : userRoleData.roleId == '6' ?
                              <>
                                {navlist(navItem?.options[1])}
                                {navlist(navItem?.options[2])}
                              </>
                              : userRoleData.roleId == '7' ?
                                <>
                                  {navlist(navItem?.options[1])}
                                </>
                                : null}</> :
                        <>{userRoleData.roleId == '4' || userRoleData.roleId == '5' || userRoleData.roleId == '6' || userRoleData.roleId == '7' ?
                          <>
                            {navlist(navItem?.options[1])}
                            {navlist(navItem?.options[2])}
                          </> :
                          <>
                            {navlist(navItem?.options[0])}
                            {navlist(navItem?.options[1])}
                            {navlist(navItem?.options[2])}
                          </>
                        }</>
                      }
                    </Menu>
                  </div>
                ))
              ))}
            </List>
          </div>

          <div className="row align-items-center justify-content-end">
            {/* <div className="col-lg-6 col-xxl-3 col-sm-3"> */}
            {/* <div className="col-lg-auto"> */}
            {/* {path === "/rule-processor" && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={160}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {path === "/runRules" && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={160}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/create-rule" ||
                path === "/manageRule" ||
                path === "/rule-dashboard" ||
                path) && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={160}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/dashboard" ||
                path === `/insurances/${false}` ||
                path === `/insurances/${true}`) && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={170}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/inspection-notran" ||
                path === "/inspection-noviolation") && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={170}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )}
              {(path === "/settings" || path === '/practice-settings') && (
                <div className="ml-30">
                  <img
                    src={logo}
                    alt="logo"
                    width={170}
                    style={{ height: 100, float: "left" }}
                  />
                </div>
              )} */}
            {/* </div> */}

            {/* <div className="col-lg-6 col-xxl-9 col-sm-9"> */}
            <div className="col-lg-auto">
              <div className="header-flex" >
                <div className="search_content">
                  <PracticeSelect
                    refreshPractice={refreshPractice}
                    handleChangePractice={handleChangePractice}
                    handlePracticeChangeByNotReviewPage={handlePracticeChangeByNotReviewPage}
                    defaultDisabledValue={defaultDisabledValue}
                    setSidebarDisable={setSidebarDisable}
                    hasAllPracticeOption={hasAllPracticeOption}
                    customSetPractice={customSetPractice}
                  />
                </div>

                <div className="ac_admin">
                  <div className="c-flex pe-3">
                    <IconButton onClick={handleOpenUserMenu}>
                      <div
                        className=""
                        style={{
                          height: 50,
                          width: 50,
                          // border: "2px solid #10496a",
                          // borderStyle: "dashed",
                          borderRadius: "50%",
                          backgroundColor: "#F0F0F0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <span
                          style={{
                            color: "#24476A",
                            fontSize: 24,
                            lineHeight: "20px",
                            fontWeight: 'bold',
                            position: "absolute",
                            fontFamily: "Futura PT"
                          }}
                        >
                          {(userRoleData?.Name)?.match(/\b(\w)/g)}
                        </span>
                      </div>
                    </IconButton>
                    <Menu className="logout-modal"
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={handleCloseUserMenu}>
                        <span className='font-face-futura ' onClick={() => props.handleLogout()}>Log Out</span>
                      </MenuItem>
                    </Menu>
                    <div className=" ms-3 pt-1">
                      <p
                        className="h6"
                        style={{
                          fontSize: "17px",
                          textAlign: "left",
                          color: "#fff",
                          fontFamily: "Futura PT",

                        }}
                      >
                        {userRoleData?.Name}
                        <br />
                        <small
                          style={{

                            fontSize: "12px",
                            color: "#fff",
                            fontFamily: "Futura PT",
                          }}
                        >
                          {userRoleData?.role_Name}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </AppBar>
    </div>
  );
};

export default AppHeader;
