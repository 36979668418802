import jwt_decode from 'jwt-decode';
import io from "socket.io-client";

export const getClientKey = () => {
    try {
        let token = sessionStorage.getItem('token');
        if(token) return jwt_decode(token).clientKey
        return;
    } catch (error) {
        throw error
    }
}

export let socketInstance = () => {
    var socket;
    try {
        const baseUrls = {
            "http://localhost:3001": "http://localhost:3000/",
            "https://dev.ruleout.com": "https://backenddev.ruleout.com/",
            "https://app.ruleout.com": "https://backendapp.ruleout.com/",
            "https://dev-admin.ruleout.com/admin-panel": "https://backenddev.ruleout.com/",
            // "https://reclaim.ruleout.com": "https://reclaim-backend.ruleout.com/",
            // "https://reclaim-admin.ruleout.com": "https://reclaim-backend.ruleout.com/",
        }
        const originUrl = window.location.origin
        socket = io.connect(baseUrls[originUrl], {
            extraHeaders: {
            'client-id': getClientKey()
            }
        });
        return socket;
    } catch (error) {
        console.log(error)
        // throw error
    }
}