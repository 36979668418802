import { NotificationManager } from "react-notifications";
import React, {useEffect} from 'react';

export const handleErrors = async (callback) => {
  try {
    const res = await callback();
    return res;
  } catch (e) {
    NotificationManager.error(e.message ? e.message : "Something went wrong!");
  }
};

export const objectToQuery = (obj) => {
  let query = "";
  const keys = Object.keys(obj);
  keys.forEach((k, index) => {
    query += k + "=" + obj[k] + `${index !== keys.length - 1 ? "&" : ""}`;
  });
  return query;
};

export const buildUrlWithQuery = (url, query = {}) => {
  if (typeof query !== "object") return url;
  if (Object.keys(query).length === 0) return url;
  return url + (query !== undefined ? "?" + objectToQuery(query) : "");
};

export class PracticeHelper {
  static SESSION_PRACTICE_KEY = "practice";

  static savePracticeLocally = (practice) => {
    sessionStorage.setItem(this.SESSION_PRACTICE_KEY, JSON.stringify(practice));
  };

  static getSelectedPracticeLocally = () => {
    const practice = sessionStorage.getItem(this.SESSION_PRACTICE_KEY);
    if (practice) {
      return JSON.parse(practice);
    }
  };
}

let debounceId;

export const debounce = (callback, time = 800) => {
  clearTimeout(debounceId);
  debounceId = setTimeout(() => {
    callback();
  }, time);
};

function useOutsideAlerter(ref, handleHideState) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleHideState(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export function OutsideAlerter(props) {
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, props.handleHideState);

  return <div ref={wrapperRef}>{props.children}</div>;
}
