export const categoryArray = [
    {name: 'Age', value: 'age'}, 
    {name: 'Date of Injury', value: 'date of injury'}, 
    {name: 'Diagnosis 1', value: 'diagnosis 1'}, 
    {name: 'Diagnosis 2', value: 'diagnosis 2'}, 
    {name: 'Diagnosis 3', value: 'diagnosis 3'}, 
    {name: 'Diagnosis 4', value: 'diagnosis 4'}, 
    {name: 'Diagnosis in any slot', value: 'diagnosis in any slot'},
    // {name: 'Eligibility Response', value: 'eligibility response'},
    {name: 'Gender', value: 'gender'}, 
    {name: 'Global Period', value: 'global period'}, 
    {name: 'Hospitalization Start Date', value: 'hospitalization start date'}, 
    {name: 'Modifier', value: 'modifier'}, 
    {name: 'Patient Address', value: 'patient address'}, 
    {name: 'Patient Alert', value: 'patient alert'}, 
    {name: 'Patient Name', value: 'patient name'}, 
    {name: 'Place of Service', value: 'place of service'}, 
    {name: 'Place of Service Code', value: 'place of service code'}, 
    {name: 'Policy Number', value: 'policy number'}, 
    // {name: 'Post Date', value: 'post date'}, 
    {name: 'Primary Insurance Plan', value: 'primary insurance plan'}, 
    {name: 'Procedure Code', value: 'procedure code'}, 
    {name: 'Referring Provider', value: 'referring provider'}, 
    {name: 'Rendering Provider', value: 'rendering provider'}, 
    {name: 'Scheduling Provider', value: 'scheduling provider'}, 
    {name: 'Secondary Insurance Plan', value: 'secondary insurance plan'}, 
    {name: 'Service Date', value: 'service date'},
    {name: 'Total Charges', value: 'total charges'},
    // {name: 'Trizetto Payer ID', value: 'trizetto payer id'}
    {name: 'Units', value: 'units'},
    {name: 'Unit Charge', value: 'unit charge'},
];

export const conditionForAllPractice = [
    {name: '', value: ''},
    {name: 'is missing', value: 'is missing'}, 
    {name: 'is not missing', value: 'is not missing'}, 
    {name: 'contains', value: 'contains'}, 
    {name: 'does not contain', value: 'does not contain'},
    {name: 'starts with', value: 'starts with'},
    {name: 'does not start with', value: 'does not start with'},
    {name: 'ends with', value: 'ends with'},
    {name: 'does not end with', value: 'does not end with'}
];

export const conditionArray = [
    {name: '', value: ''},
    {name: 'is', value: 'is'}, 
    {name: 'is not', value: 'is not'}, 
    {name: 'is missing', value: 'is missing'}, 
    {name: 'is not missing', value: 'is not missing'}, 
    {name: 'contains', value: 'contains'}, 
    {name: 'does not contain', value: 'does not contain'},
    {name: 'starts with', value: 'starts with'},
    {name: 'does not start with', value: 'does not start with'},
    {name: 'ends with', value: 'ends with'},
    {name: 'does not end with', value: 'does not end with'}
];
export const trizettoConditionArray = [
    {name: '', value: ''},
    {name: 'is', value: 'is'}, 
    {name: 'is not', value: 'is not'}
];
export const eligibilityArray = [
    {name: '', value: ''},
    {name: 'contains', value: 'contains'}, 
    {name: 'does not contain', value: 'does not contain'}
];
export const selectedCategoryData = [
    {name: '', value: ''},
    {name: 'is above', value: 'is above'},
    // {name: 'is greater than', value: 'is greater than'},
    {name: 'is below', value: 'is below'},
    // {name: 'is less than', value: 'is less than'},
    {name: 'is equal to', value: 'is equal to'},
    {name: 'is not equal to', value: 'is not equal to'},
    {name: 'is equal to or above', value: 'is equal to or above'},
    // {name: 'is greater than or equal to', value: 'is greater than or equal to'},
    {name: 'is equal to or below', value: 'is equal to or below'},
    // {name: 'is less than or equal to', value: 'is less than or equal to'},
    {name: 'is between', value: 'is between'},
    {name: 'is not between', value: 'is not between'},
    {name: 'is missing', value: 'is missing'},
    {name: 'is not missing', value: 'is not missing'}
]
export const dateData = [
    {name: '', value: ''},
    {name: 'is after', value: 'is after'},
    {name: 'is before', value: 'is before'},
    {name: 'is equal to', value: 'is equal to'},
    {name: 'is not equal to', value: 'is not equal to'},
    {name: 'is equal to or after', value: 'is equal to or after'},
    {name: 'is equal to or before', value: 'is equal to or before'},
    {name: 'is between', value: 'is between'},
    {name: 'is not between', value: 'is not between'},
    {name: 'is missing', value: 'is missing'},
    {name: 'is not missing', value: 'is not missing'},
]

export const globalRuleData = [
    {name: 'before', value: 'before'},
    {name: 'after', value: 'after'},
    {name: 'before & after', value: 'before & after'}
]

export const operator = ['and', 'then'];

export const selectThen = ['Pend to Review Tab', 'Write off under adjustment code x', 'Change procedure code to x',
'Add modifier', 'Change charge amount']

export const gender = ['Male', 'Female'];

export const daysArray = [
    {name: '0', value: '0'},
    {name: '1', value: '1'},
    {name: '2', value: '2'},
    {name: '3', value: '3'},
    {name: '4', value: '4'},
    {name: '5', value: '5'},
    {name: '6', value: '6'},
    {name: '7', value: '7'},
    {name: '8', value: '8'},
    {name: '9', value: '9'},
    {name: '10', value: '10'},
    {name: '11', value: '11'},
    {name: '12', value: '12'},
    {name: '13', value: '13'},
    {name: '14', value: '14'},
    {name: '15', value: '15'},
    {name: '16', value: '16'},
    {name: '17', value: '17'},
    {name: '18', value: '18'},
    {name: '19', value: '19'},
    {name: '20', value: '20'},
    {name: '21', value: '21'},
    {name: '22', value: '22'},
    {name: '23', value: '23'},
    {name: '24', value: '24'},
    {name: '25', value: '25'},
    {name: '26', value: '26'},
    {name: '27', value: '27'},
    {name: '28', value: '28'},
    {name: '29', value: '29'},
    {name: '30', value: '30'},
    {name: '31', value: '31'},
    {name: '32', value: '32'},
    {name: '33', value: '33'},
    {name: '34', value: '34'},
    {name: '35', value: '35'},
    {name: '36', value: '36'},
    {name: '37', value: '37'},
    {name: '38', value: '38'},
    {name: '39', value: '39'},
    {name: '40', value: '40'},
    {name: '41', value: '41'},
    {name: '42', value: '42'},
    {name: '43', value: '43'},
    {name: '44', value: '44'},
    {name: '45', value: '45'},
    {name: '46', value: '46'},
    {name: '47', value: '47'},
    {name: '48', value: '48'},
    {name: '49', value: '49'},
    {name: '50', value: '50'},
    {name: '51', value: '51'},
    {name: '52', value: '52'},
    {name: '53', value: '53'},
    {name: '54', value: '54'},
    {name: '55', value: '55'},
    {name: '56', value: '56'},
    {name: '57', value: '57'},
    {name: '58', value: '58'},
    {name: '59', value: '59'},
    {name: '60', value: '60'}
];

export const RPAFlowNames = [
    {key: 'add authorization', value: 'Add Authorization'},
    {key: 'update provider', value: 'Update Provider'},
    // {key: 'update diagnosis', value: 'Update Diagnosis'},
    {key: 'custom rpa flow', value: 'Run Custom RPA Flow'},
]